import React from "react";
import Main from "../components/Main";
import logo from "../images/ekomuzLogo.svg";
const Welcome = () => {
  return (
    <Main
      isInteractionPanel={false}
      isPagination={false}
      element={
        <>
          <div className="flex flex-col items-center my-[58px]">
            <div className="mb-[35px]">
              <img className="w-[250px] h-a" src={logo} alt="logo"/>
            </div>
            <div className="text-[#414141] text-[49px] font-normal">Приветствуем в ERP EkomUz!</div>
          </div>
        </>
      }
      createButton={false}
      isFilterButton={false}
      handleOpenCreateModals={() => {}}
      // title="Каталог"
      // placeholderText="Название товара"
    />
  );
};

export default Welcome;
