function CategorysSearch(params) {
  return (
    <div className="categorys__search">
      <input placeholder="Поиск по категориям" className="categorys__search-line zero-input"></input>
      <button className="categorys__search-button zero-button"></button>
    </div>
  )
};

export default CategorysSearch;
