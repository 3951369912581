import React, { useState } from 'react';
import SearchLight from '../../images/Search_alt_light.svg';
import CloseGrey from '../../images/Close_round_grey.svg';
import SelectAPLogist from '../../components/UI/SelectAPLogist';
import { useDecoratedCommonStore } from '../../hooks/useDecoratedCommonStore';
import { useCommonProductCategoryStore } from '../../store/commonProductCategory';
import { Link } from 'react-router-dom';
export default function FirstCard({ setNextCard }) {
	const { data: productCategories } = useDecoratedCommonStore(
		useCommonProductCategoryStore
	);
	const [search, setSearch] = useState('');
	const [targetFirstCategory, setTargetFirstCategory] = useState();
	const [targetSecondCategory, setTargetSecondCategory] = useState();
	const [targetThirdCategory, setTargetThirdCategory] = useState();
	return (
		<form onSubmit={setNextCard}>
			<div className='max-w-[730px]'>
				<h3 className='text-[18px] font-normal'>
					Название товара <span className='text-[#F98026]'>*</span>
				</h3>
				<input
					type='text'
					placeholder='Выбрать категорию'
					className='text-[#909090] text-[16px] font-normal mt-[10px] bg-[#13131408] py-[8px] pl-[15px] pr-[15px] w-full rounded-[10px] h-[36px] outline-none border-none'
				/>
			</div>

			<div className='max-w-[730px] mt-[30px]'>
				<h3 className='text-[18px] font-normal'>
					Описание товара <span className='text-[#F98026]'>*</span>
				</h3>
				<textarea
					type='text'
					placeholder='Описание товара'
					className='text-[#909090] resize-none text-[16px] font-normal mt-[10px] bg-[#13131408] py-[8px] pl-[15px] pr-[15px] w-full rounded-[10px] h-[140px] outline-none border-none'
				/>
			</div>

			<div className='max-w-[730px] mt-[30px]'>
				<h3 className='text-[18px] font-normal'>
					Выбор бренда <span className='text-[#F98026]'>*</span>
				</h3>
				<SelectAPLogist
					placeholder='Выбрать бренд'
					className='w-full'
				/>
			</div>

			<div className='max-w-[730px] mt-[30px]'>
				<h3 className='text-[18px] font-normal'>
					Артикул <span className='text-[#F98026]'>*</span>
				</h3>
				<input
					type='text'
					placeholder='Артикул'
					className='text-[#909090] text-[16px] font-normal mt-[10px] bg-[#13131408] py-[8px] pl-[15px] pr-[15px] w-full rounded-[10px] h-[36px] outline-none border-none'
				/>
			</div>

			<div className='max-w-[730px] mt-[30px]'>
				<h3 className='text-[22px] font-semibold'>
					Выбрать категорию <span className='text-[#F98026]'>*</span>
				</h3>
				<div className='relative mt-[15px] flex gap-1 items-baseline pl-9 text-[#909090] text-[16px] font-normal'>
					<label
						className='absolute top-[18%] left-[4px]'
						htmlFor='category_input'>
						<img
							src={SearchLight}
							alt='search'
							className='pointer-events-none'
						/>
					</label>
					{targetFirstCategory && (
						<span className='flex-shrink-0'>{`${targetFirstCategory.name} > `}</span>
					)}
					{targetSecondCategory && (
						<span className='flex-shrink-0'>{`${targetSecondCategory.name} > `}</span>
					)}
					{targetThirdCategory && (
						<span className='flex-shrink-0'>{`${targetThirdCategory.name}`}</span>
					)}
					<input
						type='text'
						placeholder=''
						onChange={(e) => setSearch(e.target.value)}
						disabled={targetThirdCategory}
						value={search}
						id='category_input'
						className=' bg-[#13131408] py-[8px] pr-[35px] w-full rounded-[10px] h-[40px] outline-none border-none'
					/>

					<button
						type='button'
						className='absolute top-[18%] right-[4px]'
						htmlFor='category_input'>
						<img
							src={CloseGrey}
							alt='close'
							className='pointer-events-none max-w-[24px]'
						/>
					</button>
				</div>
				<div className='flex w-full mt-[15px]'>
					<div className='mr-[1px] max-w-[250px] w-full'>
						{productCategories?.map((item) => {
							const { id, name, parent } = item;
							const isSearched =
								name.includes(search) || targetFirstCategory;
							if (!parent && isSearched) {
								return (
									<p
										key={id}
										onClick={() => {
											setTargetFirstCategory(item);
											setTargetSecondCategory(undefined);
											setTargetThirdCategory(undefined);
											setSearch('');
										}}
										className={` cursor-pointer text-[14px] font-normal my-[5px] px-[12px] ${
											targetFirstCategory?.id === id
												? ' text-[#666666] '
												: 'text-[#999999]'
										}`}>
										{name}
									</p>
								);
							}
							return;
						})}
					</div>
					<div className='mr-[1px] max-w-[250px] w-full'>
						{targetFirstCategory &&
							productCategories?.map((item) => {
								const { id, name, parent } = item;
								const isSearched =
									name.includes(search) ||
									targetSecondCategory;
								if (
									parent === targetFirstCategory?.id &&
									isSearched
								) {
									return (
										<p
											key={id}
											onClick={() => {
												setTargetSecondCategory(item);

												setTargetThirdCategory(
													undefined
												);
												setSearch('');
											}}
											className={` cursor-pointer text-[14px] font-normal my-[5px] px-[12px] ${
												targetSecondCategory?.id === id
													? ' text-[#666666] '
													: 'text-[#999999]'
											}`}>
											{name}
										</p>
									);
								}
								return;
							})}
					</div>
					<div className='mr-[1px] max-w-[250px] w-full'>
						{targetSecondCategory &&
							productCategories?.map((item) => {
								const { id, name, parent } = item;
								const isSearched =
									name.includes(search) ||
									targetThirdCategory;
								if (
									parent === targetSecondCategory?.id &&
									isSearched
								) {
									return (
										<p
											key={id}
											onClick={() => {
												setTargetThirdCategory(item);

												setSearch('');
											}}
											className={` cursor-pointer text-[14px] font-normal my-[5px] px-[12px] ${
												targetThirdCategory?.id === id
													? ' text-[#666666] '
													: 'text-[#999999]'
											}`}>
											{name}
										</p>
									);
								}
								return;
							})}
					</div>
				</div>
			</div>
			<div className='max-w-[730px] mt-[30px] flex justify-end items-center'>
				<Link to={'/catalog'}>
					<button
						type='button'
						className='py-[10px] px-[24px] bg-[#13131408] hover:bg-[#13131414] text-[#909090] rounded-[10px] mr-[20px]'>
						Отмена
					</button>
				</Link>
				<button
					type='submit'
					className='py-[10px] px-[32px] bg-[#007AFF] hover:bg-[#0d81ff]  text-[#FFFFFF] rounded-[10px]'>
					Сохранить и продолжить
				</button>
			</div>
		</form>
	);
}
