import React from 'react';
import { Link } from 'react-router-dom';

export default function FourthCard({ setNextCard }) {
	return (
		<form onSubmit={setNextCard}>
			<div className='max-w-[730px] mt-[30px]'>
				<h3 className='text-[18px] font-normal'>
					Цена в $ <span className='text-[#F98026]'>*</span>
				</h3>
				<input
					type='text'
					placeholder='Цена в $'
					className='text-[#909090] text-[16px] font-normal mt-[10px] bg-[#13131408] py-[8px] pl-[15px] pr-[15px] w-full rounded-[10px] h-[36px] outline-none border-none'
				/>
			</div>
			<div className='max-w-[730px] mt-[30px] flex justify-end items-center'>
				<Link to={'/catalog'}>
					<button
						type='button'
						className='py-[10px] px-[24px] bg-[#13131408] hover:bg-[#13131414] text-[#909090] rounded-[10px] mr-[20px]'>
						Отмена
					</button>
				</Link>
				<button
					type='submit'
					className='py-[10px] px-[32px] bg-[#007AFF] hover:bg-[#0d81ff]  text-[#FFFFFF] rounded-[10px]'>
					Сохранить и продолжить
				</button>
			</div>
		</form>
	);
}
