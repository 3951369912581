import React from 'react';
import SelectAPLogist from '../../components/UI/SelectAPLogist';

import Arrow from '../../images/arrow.svg';
import Trash from '../../images/Trash.svg';
import ArrowBlue from '../../images/ArrowBlue.svg';
import { Link } from 'react-router-dom';

export default function ThirdCard({ setNextCard }) {
	return (
		<form onSubmit={setNextCard}>
			<div className='max-w-[730px] mt-[30px]'>
				<h3 className='text-[22px] font-semibold'>Атрибуты товара</h3>
				<p className='text-[#999999] text-[16px] font-normal mt-[10px]'>
					Заполните эти поля, они помогают покупателям сравнить
					продукты и выбрать оптимальный вариант, соответствующий их
					потребностям и предпочтениям.
				</p>

				<div className='flex justify-between mt-[30px]'>
					<SelectAPLogist
						placeholder='Категория атрибута'
						className='max-w-[350px]'
					/>
					<button className='bg-[#007AFF] text-[#FFFFFF] ml-[15px] w-full h-[36px] px-[32px] rounded-[8px] max-w-[350px]'>
						Добавить категорию атрибута
					</button>
				</div>

				<div className='mt-[30px]'>
					<div>
						<div className='flex justify-between items-center'>
							<div className='flex gap-[10px] items-center'>
								<button>
									<img
										className='rotate-180'
										src={Arrow}
										alt='arrow'
									/>
								</button>
								<p className='text-[18px] font-medium'>
									Технические параметры
								</p>
							</div>
							<div className='flex gap-[10px] items-center cursor-pointer'>
								<p className='text-[14px] text-[#FF3E3E] font-normal'>
									Удалить категорию
								</p>
								<button>
									<img className='' src={Trash} alt='Trash' />
								</button>
							</div>
						</div>

						<div className='mt-[15px]'>
							<div className='flex justify-between items-center my-[15px]'>
								<p className='text-[16px] text-[#909090] font-medium'>
									Конструкция
								</p>
								<div className='flex items-center'>
									<p className='bg-[#F8F8F8] py-[10px] px-[12px] ml-[8px] mr-[15px] rounded-[8px]'>
										полноразмерные
									</p>
									<button>
										<img
											className=''
											src={Trash}
											alt='Trash'
										/>
									</button>
								</div>
							</div>

							<div className='flex justify-between items-center my-[15px]'>
								<p className='text-[16px] text-[#909090] font-medium'>
									Технология
								</p>
								<div className='flex items-center'>
									<p className='bg-[#F8F8F8] py-[10px] px-[12px] ml-[8px] mr-[15px] rounded-[8px]'>
										динамические
									</p>
									<button>
										<img
											className=''
											src={Trash}
											alt='Trash'
										/>
									</button>
								</div>
							</div>

							<div className='flex justify-between items-center my-[15px]'>
								<p className='text-[16px] text-[#909090] font-medium'>
									Минимальная воспроизводимая частота
								</p>
								<div className='flex items-center'>
									<p className='bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]'>
										20 000
									</p>
									<p className='text-[#909090] text-[14px] font-medium ml-[8px] mr-[15px]'>
										Гц
									</p>
									<button>
										<img
											className=''
											src={Trash}
											alt='Trash'
										/>
									</button>
								</div>
							</div>

							<div className='flex justify-between items-center my-[15px]'>
								<p className='text-[16px] text-[#909090] font-medium'>
									Импеданс
								</p>
								<div className='flex items-center'>
									<p className='bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]'>
										32
									</p>
									<p className='text-[#909090] text-[14px] font-medium ml-[8px] mr-[15px]'>
										Ом
									</p>
									<button>
										<img
											className=''
											src={Trash}
											alt='Trash'
										/>
									</button>
								</div>
							</div>

							<div className='flex justify-between items-center my-[15px]'>
								<p className='text-[16px] text-[#909090] font-medium'>
									Чувствительность
								</p>
								<div className='flex items-center'>
									<p className='bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]'>
										80
									</p>
									<p className='text-[#909090] text-[14px] font-medium ml-[8px] mr-[15px]'>
										дБ
									</p>
									<button>
										<img
											className=''
											src={Trash}
											alt='Trash'
										/>
									</button>
								</div>
							</div>

							<div className='flex justify-between items-center my-[15px]'>
								<p className='text-[16px] text-[#909090] font-medium'>
									Максимальная мощность
								</p>
								<div className='flex items-center'>
									<p className='bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]'>
										50
									</p>
									<p className='text-[#909090] text-[14px] font-medium ml-[8px] mr-[15px]'>
										мВт
									</p>
									<button>
										<img
											className=''
											src={Trash}
											alt='Trash'
										/>
									</button>
								</div>
							</div>

							<div className='flex justify-between items-center my-[15px]'>
								<p className='text-[16px] text-[#909090] font-medium'>
									Тип акустического оформления
								</p>
								<div className='flex items-center'>
									<p className='bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px] ml-[8px] mr-[15px]'>
										закрытые
									</p>
									<button>
										<img
											className=''
											src={Trash}
											alt='Trash'
										/>
									</button>
								</div>
							</div>
						</div>

						<div className='flex justify-between'>
							<SelectAPLogist
								placeholder='Атрибут'
								className='max-w-[350px]'
							/>
							<button className='bg-[#007AFF] text-[#FFFFFF] ml-[15px] w-full h-[36px] px-[32px] rounded-[8px] max-w-[350px]'>
								Добавить атрибут
							</button>
						</div>
					</div>
				</div>

				<div className='mt-[30px]'>
					<div>
						<div className='flex justify-between items-center'>
							<div className='flex gap-[10px] items-center'>
								<button>
									<img
										className=''
										src={ArrowBlue}
										alt='arrow'
									/>
								</button>
								<p className='text-[18px] font-medium'>
									Основные характеристики
								</p>
							</div>
							<div className='flex gap-[10px] items-center cursor-pointer'>
								<p className='text-[14px] text-[#FF3E3E] font-normal'>
									Удалить категорию
								</p>
								<button>
									<img className='' src={Trash} alt='Trash' />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='max-w-[730px] mt-[30px] flex justify-end items-center'>
				<Link to={'/catalog'}>
					<button
						type='button'
						className='py-[10px] px-[24px] bg-[#13131408] hover:bg-[#13131414] text-[#909090] rounded-[10px] mr-[20px]'>
						Отмена
					</button>
				</Link>
				<button
					type='submit'
					className='py-[10px] px-[32px] bg-[#007AFF] hover:bg-[#0d81ff]  text-[#FFFFFF] rounded-[10px]'>
					Сохранить и продолжить
				</button>
			</div>
		</form>
	);
}
