import InputsFilterPopup from './InputsFilterPopup';
import SelectsFilterPopup from './SelectsFilterPopup';
import RadiosFilterPopup from './RadiosFilterPopup';
import { useEffect, useRef } from 'react';
import SelectsFilterPopupLogist from './SelectsFilterPopupLogist';
import CheckedsFilterPopup from './CheckedsFilterPopup';

function FilterPopupFilterSection({
	filters,
	setFilters,
	isFiltersConfirm,
	onFiltersConfirm,
}) {
	const refForm = useRef();
	const handleChange = () => {
		onFiltersConfirm();
	};

	// const setValueFilter = (id, newValue) => {
	//     const retuslt = filters.map(item => {
	//         if (id !== item.id) return item;
	//         return { ...item, ...newValue };
	//     })
	//     setFilters(retuslt);
	// }

	const setMinMaxFilter = (id) => {
		return (newValue) => {
			setFilters({
				...filters,
				minMax: filters.minMax.map((item) => {
					if (item.id === id) {
						console.log({ ...item, value: newValue });
						return { ...item, value: newValue };
					}
					return item;
				}),
			});
		};
	};

	const setMinMaxDiscountFilter = (id) => {
		return (newValue) => {
			setFilters({
				...filters,
				minMaxDiscount: filters?.minMaxDiscount?.map((item) => {
					if (item.id === id) {
						console.log({ ...item, value: newValue });
						return { ...item, value: newValue };
					}
					return item;
				}),
			});
		};
	};
	const setTrueFalseBothFilter = (id) => {
		return (newValue) => {
			setFilters({
				...filters,
				trueFalseBoth: filters.trueFalseBoth.map((item) => {
					if (item.id === id) {
						console.log({ ...item, value: newValue });
						return { ...item, value: newValue };
					}
					return item;
				}),
			});
		};
	};

	const setSelectFilter = (id) => {
		return (newValue) => {
			setFilters({
				...filters,
				select: filters.select.map((item) => {
					if (item.id === id) {
						console.log({ ...item, values: [newValue] });
						return { ...item, values: [newValue] };
					}
					return item;
				}),
			});
		};
	};

	const setCheckedFilter = (id) => {
		return (newValue) => {
			setFilters({
				...filters,
				checked: filters.checked.map((item) => {
					if (item.id === id) {
						console.log({ ...item, values: newValue });
						return { ...item, values: newValue };
					}
					return item;
				}),
			});
		};
	};

	const setCheckedColumnFilter = (id) => {
		return (newValue) => {
			setFilters({
				...filters,
				checkedColumn: filters.checkedColumn.map((item) => {
					if (item.id === id) {
						console.log({ ...item, values: [newValue] });
						return { ...item, values: [newValue] };
					}
					return item;
				}),
			});
		};
	};

	useEffect(() => {
		if (!isFiltersConfirm) refForm.current.reset();
	}, [isFiltersConfirm]);

	return (
		<form
			ref={refForm}
			onChange={handleChange}
			className='filter-popup__section'>
			<h2 className='filter-popup__title filter-popup__title_type_filters'>
				Фильтры
			</h2>
			{filters?.minMax?.map((item) => {
				return (
					<InputsFilterPopup
						icon={item.icon}
						setValueFilter={setMinMaxFilter(item.id)}
						inputsType='text'
						subtitle={item.title}
					/>
				);
			})}
			{filters?.checkedColumn?.map((item) => {
				return (
					<div className='flex flex-col gap-4 my-3'>
						<SelectsFilterPopupLogist
							filter={item}
							setValueFilter={setCheckedColumnFilter(item.id)}
						/>{' '}
					</div>
				);
			})}
			{filters?.minMaxDiscount?.map((item) => {
				return (
					<InputsFilterPopup
						icon={item.icon}
						setValueFilter={setMinMaxDiscountFilter(item.id)}
						inputsType='text'
						subtitle={item.title}
					/>
				);
			})}
			{filters?.trueFalseBoth?.map((item) => {
				return (
					<RadiosFilterPopup
						filter={item}
						setValueFilter={setTrueFalseBothFilter(item.id)}
					/>
				);
			})}
			<div className='flex gap-2 pb-2'>
				{filters?.select?.map((item) => {
					return (
						<SelectsFilterPopup
							filter={item}
							setValueFilter={setSelectFilter(item.id)}
						/>
					);
				})}
			</div>

			<div className='flex flex-col gap-2 pb-2'>
				{filters?.checked?.map((item) => {
					// return item?.dataList?.map((option) => (
					// 	<p>{option?.name}</p>
					// ));
					return (
						<CheckedsFilterPopup
							filter={item}
							setValueFilter={setCheckedFilter(item.id)}
						/>
					);
				})}
			</div>

			{/* {filters?.map(item => {

                if (item.type === 'range') {
                    return (<InputsFilterPopup setValueFilter={setValueFilter} id={item.id} inputsType='text' subtitle={item.title} />)
                }
                if (item.type === 'radio') {
                    return (<RadiosFilterPopup filter={item} setValueFilter={setValueFilter} />)
                }
                if (item.type === 'selects') {
                    return (<SelectsFilterPopup setValueFilter={setValueFilter} filter={item} />)
                }
            })} */}

			{/* <InputsFilterPopup inputsType='date' subtitle='Время разгрузки' />
            <InputsFilterPopup inputsType='text' subtitle='Кол-во атрибутов' />
            <SelectsFilterPopup firstSubtitle='Город' secondSubtitle='Компания' />
            <SelectsFilterPopup firstSubtitle='Город' secondSubtitle='Компания' isArrow={true} />
            <RadiosFilterPopup subtitle='Печать' name='print' idName='radio-print' />
            <RadiosFilterPopup subtitle='Статус' name='status' idName='radio-status' /> */}
		</form>
	);
}

export default FilterPopupFilterSection;
