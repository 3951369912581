import React, { useState } from "react";

import doneStatus from "../../images/Done_icon.svg";
import notDoneStatus from "../../images/Not_icon.svg";

import downloadGreen from "../../images/Import_green.svg";
import downloadRed from "../../images/Import_red.svg";
import InventoryControlModalView from "../../components/modals/InventoryControlModalView";
import InventoryControlModalPosting from "../../components/modals/InventoryControlModalPosting";
import Main from "../../components/Main";

export default function InventoryControl() {
    const [isEditInventoryControlModalOpen, setIsEditInventoryControlModalOpen] = useState(false);
    const [isEditInventoryControlModalPostingOpen, setIsEditInventoryControlModalPostingOpen] = useState(false);

    const closeAllModals = () => {
        setIsEditInventoryControlModalOpen(false);
        setIsEditInventoryControlModalPostingOpen(false)
    }
    return (
        <Main
            isInteractionPanel={true}
            createButton={true}
            isFilterButton={true}
            handleOpenCreateModals={() => { setIsEditInventoryControlModalPostingOpen(!isEditInventoryControlModalPostingOpen) }}
            title="Оприходования"
            placeholderText="Номер или комментарий"
            modals={
                <>
                    <InventoryControlModalView
                        title="Просмотр оприходования"
                        initialData={() => { }}
                        handleCloseAllModals={closeAllModals}
                        isOpen={isEditInventoryControlModalOpen}
                        name="view-postings-modal"
                        modalWidth={{ width: "500px" }}
                    />
                    <InventoryControlModalPosting
                        title="Оприходование"
                        initialData={() => { }}
                        handleCloseAllModals={closeAllModals}
                        isOpen={isEditInventoryControlModalPostingOpen}
                        name="postings-modal"
                        modalWidth={{ width: "500px" }}
                    />
                </>
            }
        >
            <div className="block">
                <div className="table">
                    <div
                        className={`grid-cols-inventoryControlColumns grid mt-[20px] gap-[80px] p-[10px] items-center  border-b-[1px]`}
                    >
                        <div className="text-[#2886EB] text-base font-normal text-start">№</div>
                        <div className="text-[#2886EB] text-base font-normal text-start">Время</div>
                        <div className="text-[#2886EB] text-base font-normal text-start">Комментарий</div>
                        <div className="text-[#2886EB] text-base font-normal text-start">Статус</div>
                        <div className="text-[#2886EB] text-base font-normal text-center">Печать</div>
                    </div>
                </div>
                <div class="table__body">
                    <div class=" grid-cols-inventoryControlColumns grid mt-[20px] gap-[80px] p-[10px] items-center cursor-pointer hover:bg-[#1313140a] hover:rounded-[5px]">
                        <div class="text-[#787878] text-base font-normal text-start">558612112</div>
                        <div class="text-[#787878] text-base font-normal text-start">Dec 30, 2019 05:18</div>
                        <div class="text-[#787878] text-base font-normal text-start">Agriculture, Forestry, Fishing and Hunting</div>
                        <div class="text-[#787878] text-base font-normal flex justify-start items-center">
                            <img src={notDoneStatus} alt="download available" className="mr-[6px]" />
                            Не оприходовано
                        </div>
                        <div class="text-[#787878] text-base font-normal flex justify-center">
                            <img src={downloadRed} alt="download available" />
                        </div>
                    </div>

                    <div class=" grid-cols-inventoryControlColumns grid mt-[20px] gap-[80px] p-[10px] items-center cursor-pointer hover:bg-[#1313140a] hover:rounded-[5px]">
                        <div class="text-[#787878] text-base font-normal text-start">558612112</div>
                        <div class="text-[#787878] text-base font-normal text-start">Dec 30, 2019 05:18</div>
                        <div class="text-[#787878] text-base font-normal text-start">Agriculture, Forestry, Fishing and Hunting</div>
                        <div class="text-[#787878] text-base font-normal flex justify-start items-center">
                            <img src={doneStatus} alt="download available" className="mr-[6px]" />
                            Оприходовано
                        </div>
                        <div class="text-[#787878] text-base font-normal flex justify-center">
                            <img src={downloadGreen} alt="download available" />
                        </div>
                    </div>
                </div>
            </div>


        </Main>

    );
}
