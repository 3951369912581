import React, { useState } from "react";
import Main from "../../components/Main";
import SelectAPLogist from "../../components/UI/SelectAPLogist";
import noImage from "../../images/no-image.png";
import SearchLight from "../../images/Search_alt_light.svg";
import CloseGrey from "../../images/Close_round_grey.svg";
import Arrow from "../../images/arrow.svg";
import Trash from "../../images/Trash.svg";
import image1 from "../../images/image 11.png";
import image2 from "../../images/image 10.png";
import image3 from "../../images/image 12.png";
import image4 from "../../images/image 13.png";
import image5 from "../../images/image 16.png";
import closeWhite from "../../images/Close_white.svg";
import ArrowBlue from "../../images/ArrowBlue.svg";



const ProductUpdate = () => {
    const [isImageHover, setIsImageHover] = useState(false);
    const [isImageHoverNumber, setIsImageHoverNumber] = useState(null);

    const handleMouseEnter = (props) => {
        setIsImageHover(true);
        setIsImageHoverNumber(props)
    }
    const handleMouseLeave  = () => {
        setIsImageHover(false);
        setIsImageHoverNumber(null)
    }


  return (
    <Main
      isInteractionPanel={false}
      isPagination={false}
      vendorCode={false}
      element={
        <>
            <div className="w-full">
                <div className="max-w-[730px]">
                    <h3 className="text-[18px] font-normal">Название товара <span className="text-[#F98026]">*</span></h3>
                    <input type="text" placeholder="Выбрать категорию" className="text-[#909090] text-[16px] font-normal mt-[10px] bg-[#13131408] py-[8px] pl-[15px] pr-[15px] w-full rounded-[10px] h-[36px] outline-none border-none"/>
                </div>

                <div className="max-w-[730px] mt-[30px]">
                    <h3 className="text-[18px] font-normal">Описание товара <span className="text-[#F98026]">*</span></h3>
                    <textarea type="text" placeholder="Описание товара" className="text-[#909090] resize-none text-[16px] font-normal mt-[10px] bg-[#13131408] py-[8px] pl-[15px] pr-[15px] w-full rounded-[10px] h-[140px] outline-none border-none"/>
                </div>

                <div className="max-w-[730px] mt-[30px]">
                    <h3 className="text-[18px] font-normal">Выбор бренда <span className="text-[#F98026]">*</span></h3>
                    <SelectAPLogist placeholder="Выбрать бренд" className="w-full"/>
                </div>

                <div className="max-w-[730px] mt-[30px]">
                    <h3 className="text-[18px] font-normal">Артикул <span className="text-[#F98026]">*</span></h3>
                    <input type="text" placeholder="Артикул" className="text-[#909090] text-[16px] font-normal mt-[10px] bg-[#13131408] py-[8px] pl-[15px] pr-[15px] w-full rounded-[10px] h-[36px] outline-none border-none"/>
                </div>

                <div className="max-w-[730px] mt-[30px]">
                    <h3 className="text-[18px] font-normal">Цена в $ <span className="text-[#F98026]">*</span></h3>
                    <input type="text" placeholder="Цена в $" className="text-[#909090] text-[16px] font-normal mt-[10px] bg-[#13131408] py-[8px] pl-[15px] pr-[15px] w-full rounded-[10px] h-[36px] outline-none border-none"/>
                </div>

                <div className="max-w-[730px] mt-[30px]">
                    <h3 className="text-[22px] font-semibold">Изображения <span className="text-[#F98026]">*</span></h3>
                    <p className="text-[#999999] text-[16px] font-normal mt-[10px]">Добавьте хотябы одно изображение товара, а лучше несколько. Тогда мы сможем быстро проверить товар и создать карточку, если ее еще нет в каталоге.</p>
                    <div className="flex justify-between items-center mt-[20px]">
                        <div className="bg-[#F8F8F8] text-[#999999] text-[14px] font-semibold px-[12px] py-[8px] rounded-[8px]">5 фото</div>
                        <div>
                            <label htmlFor="file-upload" class="text-[#007AFF] text-[14px] font-medium cursor-pointer">
                                Добавить фото
                            </label>
                            <input className="hidden" id="file-upload" type="file" />
                        </div>
                    </div>
                    <div className="mt-[20px] mb-[30px]">
                        <div className="flex w-full gap-x-[10px]">
                            <div className="min-h-[200px] h-full max-h-[250px] bg-[#F8F8F8] rounded-lg ">
                                <img src={image1} className="min-h-[200px] max-h-[300px]" alt="no image"/>
                            </div>
                            <div className="flex flex-wrap gap-y-[10px] gap-x-[10px] h-full">
                                <div id="111" onMouseEnter={() => handleMouseEnter(111)} onMouseLeave={() => handleMouseLeave(111) } className={`max-h-[100px] h-full max-w-[120px] w-full bg-[#F8F8F8] rounded-lg relative`}>
                                        <img className="max-h-[95px] w-full h-full" src={image1} alt="image 3"/> 
                                        {
                                            isImageHover && isImageHoverNumber === 111 &&
                                            <div className="absolute top-0 bg-black opacity-30 w-full h-full rounded-lg">
                                                <div className="flex justify-end pr-[5px] pt-[5px]">
                                                    <button><img src={closeWhite} alt="close" /></button>
                                                </div>
                                            </div>                                       
                                        }
                                </div>

                                <div id="222" onMouseEnter={() => handleMouseEnter(222)} onMouseLeave={() => handleMouseLeave(222) } className={`max-h-[100px] h-full max-w-[120px] w-full bg-[#F8F8F8] rounded-lg relative`}>
                                    <img className="max-h-[95px] w-full h-full" src={image2} alt="image 3"/> 
                                    {
                                        isImageHover && isImageHoverNumber === 222 &&
                                        <div className="absolute top-0 bg-black opacity-30 w-full h-full rounded-lg">
                                            <div className="flex justify-end pr-[5px] pt-[5px]">
                                                <button><img src={closeWhite} alt="close" /></button>
                                            </div>
                                        </div>                                       
                                    }
                                </div>

                                <div id="333" onMouseEnter={() => handleMouseEnter(333)} onMouseLeave={() => handleMouseLeave(333) } className={`max-h-[100px] h-full max-w-[120px] w-full bg-[#F8F8F8] rounded-lg relative`}>
                                    <img className="max-h-[95px] w-full h-full" src={image3} alt="image 3"/> 
                                    {
                                        isImageHover && isImageHoverNumber === 333 &&
                                        <div className="absolute top-0 bg-black opacity-30 w-full h-full rounded-lg">
                                            <div className="flex justify-end pr-[5px] pt-[5px]">
                                                <button><img src={closeWhite} alt="close" /></button>
                                            </div>
                                        </div>                                       
                                    }
                                </div>

                                <div id="444" onMouseEnter={() => handleMouseEnter(444)} onMouseLeave={() => handleMouseLeave(444) } className={`max-h-[100px] h-full max-w-[120px] w-full bg-[#F8F8F8] rounded-lg relative`}>
                                    <img className="max-h-[95px] w-full h-full" src={image4} alt="image 3"/> 
                                    {
                                        isImageHover && isImageHoverNumber === 444 &&
                                        <div className="absolute top-0 bg-black opacity-30 w-full h-full rounded-lg">
                                            <div className="flex justify-end pr-[5px] pt-[5px]">
                                                <button><img src={closeWhite} alt="close" /></button>
                                            </div>
                                        </div>                                       
                                    }
                                </div>

                                <div id="555" onMouseEnter={() => handleMouseEnter(555)} onMouseLeave={() => handleMouseLeave(555) } className={`max-h-[100px] h-full max-w-[120px] w-full bg-[#F8F8F8] rounded-lg relative`}>
                                    <img className="max-h-[95px] w-full h-full" src={image5} alt="image 3"/> 
                                    {
                                        isImageHover && isImageHoverNumber === 555 &&
                                        <div className="absolute top-0 bg-black opacity-30 w-full h-full rounded-lg">
                                            <div className="flex justify-end pr-[5px] pt-[5px]">
                                                <button><img src={closeWhite} alt="close" /></button>
                                            </div>
                                        </div>                                       
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="max-w-[730px] mt-[30px]">
                    <h3 className="text-[22px] font-semibold">Выбрать категорию <span className="text-[#F98026]">*</span></h3>
                    <div className="relative mt-[15px]">
                        <label className="absolute top-[18%] left-[4px]" htmlFor="category_input">
                            <img src={SearchLight} alt="search" className="pointer-events-none"/>
                        </label>
                        <input type="text" placeholder="" id="category_input" className="text-[#909090] text-[16px] font-normal bg-[#13131408] py-[8px] pl-[35px] pr-[35px] w-full rounded-[10px] h-[40px] outline-none border-none"/>
                        
                        <button className="absolute top-[18%] right-[4px]" htmlFor="category_input">
                            <img src={CloseGrey} alt="close" className="pointer-events-none max-w-[24px]"/>
                        </button>
                        
                    </div>
                    <div className="flex w-full mt-[15px]">
                        <div className="mr-[1px] max-w-[250px] w-full">
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Печатные изделия</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Антикварные изделия</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]">Одежда</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Обувь</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Электроника</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Ремонт и строительство</p>
                        </div>
                        <div className="mr-[1px] max-w-[250px] w-full">
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Аксессуары</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]">Одежда</p>
                        </div>
                        <div className="mr-[1px] max-w-[250px] w-full">
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Одежда для охоты</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Футболка мужская</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Шорты мужские</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Блейзер мужской</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]">Бриджи мужские</p>
                            <p className="text-[14px] text-[#999999] font-normal my-[5px] px-[12px]">Брюки мужские</p>
                        </div>
                    </div>
                </div>

                <div className="max-w-[730px] mt-[30px]">
                    <h3 className="text-[22px] font-semibold">Атрибуты товара</h3>
                    <p className="text-[#999999] text-[16px] font-normal mt-[10px]">Заполните эти поля, они помогают покупателям сравнить продукты и выбрать оптимальный вариант, соответствующий их потребностям и предпочтениям.</p>
                    
                    <div className="flex justify-between mt-[30px]">
                        <SelectAPLogist placeholder="Категория атрибута"  className="max-w-[350px]"/>
                        <button className="bg-[#007AFF] text-[#FFFFFF] ml-[15px] w-full h-[36px] px-[32px] rounded-[8px] max-w-[350px]">Добавить  категорию атрибута</button>
                    </div>

                    <div className="mt-[30px]">
                        <div>
                            <div className="flex justify-between items-center">
                                <div className="flex gap-[10px] items-center"> 
                                    <button>
                                        <img className="rotate-180" src={Arrow} alt="arrow"/>  
                                    </button>
                                    <p className="text-[18px] font-medium">Технические параметры</p>
                                </div>
                                <div className="flex gap-[10px] items-center cursor-pointer">
                                    <p className="text-[14px] text-[#FF3E3E] font-normal">Удалить категорию</p>
                                    <button>
                                        <img className="" src={Trash} alt="Trash"/>                                  
                                    </button>
                                </div>
                            </div>
                            
                            <div className="mt-[15px]">
                                <div className="flex justify-between items-center my-[15px]">
                                    <p className="text-[16px] text-[#909090] font-medium">Конструкция</p>
                                    <div className="flex items-center">
                                        <p className="bg-[#F8F8F8] py-[10px] px-[12px] ml-[8px] mr-[15px] rounded-[8px]">полноразмерные</p>
                                        <button>
                                            <img className="" src={Trash} alt="Trash"/>                                  
                                        </button>
                                    </div>
                                </div>
                                
                                <div className="flex justify-between items-center my-[15px]">
                                    <p className="text-[16px] text-[#909090] font-medium">Технология</p>
                                    <div className="flex items-center">
                                        <p className="bg-[#F8F8F8] py-[10px] px-[12px] ml-[8px] mr-[15px] rounded-[8px]">динамические</p>
                                        <button>
                                            <img className="" src={Trash} alt="Trash"/>                                  
                                        </button>
                                    </div>
                                </div>

                                <div className="flex justify-between items-center my-[15px]">
                                    <p className="text-[16px] text-[#909090] font-medium">Минимальная воспроизводимая частота</p>
                                    <div className="flex items-center">
                                        <p className="bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]">20 000</p><p className="text-[#909090] text-[14px] font-medium ml-[8px] mr-[15px]">Гц</p>
                                        <button>
                                            <img className="" src={Trash} alt="Trash"/>                                  
                                        </button>
                                    </div>
                                </div>

                                <div className="flex justify-between items-center my-[15px]">
                                    <p className="text-[16px] text-[#909090] font-medium">Импеданс</p>
                                    <div className="flex items-center">
                                        <p className="bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]">32</p><p className="text-[#909090] text-[14px] font-medium ml-[8px] mr-[15px]">Ом</p>
                                        <button>
                                            <img className="" src={Trash} alt="Trash"/>                                  
                                        </button>
                                    </div>
                                </div>

                                <div className="flex justify-between items-center my-[15px]">
                                    <p className="text-[16px] text-[#909090] font-medium">Чувствительность</p>
                                    <div className="flex items-center">
                                        <p className="bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]">80</p><p className="text-[#909090] text-[14px] font-medium ml-[8px] mr-[15px]">дБ</p>
                                        <button>
                                            <img className="" src={Trash} alt="Trash"/>                                  
                                        </button>
                                    </div>
                                </div>

                                <div className="flex justify-between items-center my-[15px]">
                                    <p className="text-[16px] text-[#909090] font-medium">Максимальная мощность</p>
                                    <div className="flex items-center">
                                        <p className="bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px]">50</p><p className="text-[#909090] text-[14px] font-medium ml-[8px] mr-[15px]">мВт</p>
                                        <button>
                                            <img className="" src={Trash} alt="Trash"/>                                  
                                        </button>
                                    </div>
                                </div>

                                <div className="flex justify-between items-center my-[15px]">
                                    <p className="text-[16px] text-[#909090] font-medium">Тип акустического оформления</p>
                                    <div className="flex items-center">
                                        <p className="bg-[#F8F8F8] py-[10px] px-[12px] rounded-[8px] ml-[8px] mr-[15px]">закрытые</p>
                                        <button>
                                            <img className="" src={Trash} alt="Trash"/>                                  
                                        </button>
                                    </div>
                                </div>

                            </div>

                            <div className="flex justify-between">
                                <SelectAPLogist placeholder="Атрибут" className="max-w-[350px]"/>
                                <button className="bg-[#007AFF] text-[#FFFFFF] ml-[15px] w-full h-[36px] px-[32px] rounded-[8px] max-w-[350px]">Добавить атрибут</button>
                            </div>
                        </div>
                    </div>

                    <div className="mt-[30px]">
                        <div>
                            <div className="flex justify-between items-center">
                                <div className="flex gap-[10px] items-center"> 
                                    <button>
                                        <img className="" src={ArrowBlue} alt="arrow"/>  
                                    </button>
                                    <p className="text-[18px] font-medium">Основные характеристики</p>
                                </div>
                                <div className="flex gap-[10px] items-center cursor-pointer">
                                    <p className="text-[14px] text-[#FF3E3E] font-normal">Удалить категорию</p>
                                    <button>
                                        <img className="" src={Trash} alt="Trash"/>                                  
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="max-w-[730px] mt-[30px] flex justify-end items-center">
                    <button className="py-[10px] px-[24px] bg-[#13131408] hover:bg-[#13131414] text-[#909090] rounded-[10px] mr-[20px]">Отмена</button>
                    <button className="py-[10px] px-[32px] bg-[#007AFF] hover:bg-[#0d81ff]  text-[#FFFFFF] rounded-[10px]">Сохранить и продолжить</button>
                </div>
            </div>
        </>
      }
      createButton={false}
      isFilterButton={false}
      handleOpenCreateModals={() => {}}
      title="Редактирование товара"
    />
  );
};

export default ProductUpdate;
