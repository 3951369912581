import React, { useEffect } from "react";
import ModalWindowWithForm from "../ModalWindowWithForm";
import { createSuppliers } from "../../service/suppliers";
import { useUserStore } from "../../store/user";

import { useForm } from "react-hook-form";
import { useSuppliersStore } from "../../store/suppliers";

const CreateStorekeeperModal = ({ title, name, modalWidth, isOpen, handleCloseAllModals }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { token } = useUserStore();
  const { updateDataList } = useSuppliersStore();

  const onSubmit = ({ name }) => {
    // if (!!token) {
    //   createSuppliers(token, name, (result) => {
    //     if (result) {
    //       updateDataList(token);
    //       handleCloseAllModals();
    //       setValue("name", "")
    //     }
    //   });
    // }
  };

  return (
    <ModalWindowWithForm
      resetForm={reset}
      onSubmit={handleSubmit(onSubmit)}
      title={title}
      name={name}
      modalWidth={modalWidth}
      isOpen={isOpen}
      handleCloseAllModals={handleCloseAllModals}
    >
      <div className="attributes-modal">
        <div className="attributes-modal__container ">
          <label className="attributes-modal__field-name">Склад</label>
          <input
            placeholder="Склад"
            className="attributes-modal__input zero-input"
            name={`${name}-input-name`}
            {...register("name", { required: true })}
          />
        </div>
      </div>
      <div className="attributes-modal__flex-wrapper">
        <button
          type="button"
          className="cansel-button zero-button bg-[#13131408]"
          onClick={() => handleCloseAllModals((state) => (state = !isOpen))}
        >
          Отмена
        </button>
        <button type="submit" className="confirm-button zero-button">
          Создать
        </button>
      </div>
    </ModalWindowWithForm>
  );
};

export default CreateStorekeeperModal;
