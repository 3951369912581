import { useEffect } from "react";
import { useUserStore } from "../store/user";

export const useDecoratedPaginationStore = (
    store,
    pageSize) => {
    const { token } = useUserStore();
    const {
        filters,
        search,
        sorts,
        loadDataList,
        loadMoreData,
        setTargetPage,
        updateDataList,
        setPageSize } = store()


    useEffect(() => {
        setPageSize(pageSize)
        if (token) {
            loadDataList(token);
        }
    }, [token, search, filters])

    useEffect(() => {
        if (token) {
            updateDataList(token)
        }
    }, [sorts])

    const loadMore = () => {
        if (token) {
            loadMoreData(token)
        }
    }
    const togglePage = (value) => {
        if (token) {
            setTargetPage(value)
            loadDataList(token);
        }
    }
    return {
        loadMore,
        togglePage
    }
}