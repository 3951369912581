import { createPaginationStore } from '../common/createPaginationStore';
import { getAttributeList } from '../service/attributes';
import { getUserList } from '../service/users';
import { TYPE_ATTRIBUTE_DATA } from '../utils/constants';
import { ReactComponent as User } from '../images/User_light.svg';
import { ReactComponent as File } from '../images/File_dock_light.svg';
import { ReactComponent as Copy } from '../images/Copy_light.svg';
export const useUsersStore = createPaginationStore(
	(options, props) => {
		const { token, pageSize, targetPage, search, sorts, filters } = options;
		getUserList(
			token,
			pageSize,
			targetPage,
			search,
			sorts,
			filters,
			(attributes, totalAttributes, maxPages) => {
				if (
					attributes &&
					typeof totalAttributes === 'number' &&
					maxPages
				) {
					props(attributes, totalAttributes, maxPages);
				}
			}
		);
	},
	10,
	[
		{
			id: '111',
			title: 'Дата создания',
			value: false,
			target: 'date_joined',
		},
		// { id: '111', title: '08.09.2023', value: false, target: 'id' },
		// { id: '222', title: 'Категория', value: false, target: 'attribute_category' },
		// { id: '333', title: 'Тип', value: false, target: 'data_type' },
		// { id: '444', title: 'Название', value: false, target: 'name' },
	],
	{
		select: [
			{
				id: '11',
				title: 'Город',
				target: 'city',
				values: [],
				dataList: [],
			},
			{
				id: '22',
				title: 'Компания',
				target: 'company',
				values: [],
				dataList: [],
			},
		],
		checked: [
			{
				id: '11111',
				target: 'groups',
				dataList: [],
				values: [],
			},
		],
		trueFalseBoth: [
			{
				id: '1111',
				title: 'Налоговый режим',
				target: 'Physical_LegalFace',
				values: [
					{ lable: <User />, value: 1 },
					{ lable: <File />, value: 2 },
					{ lable: <Copy />, value: undefined },
				],
			},
		],
	}

	// {
	//     id: '0000',
	//     type: 'selects',
	//     target: 'buy_price',
	//     firstItem: {
	//         title: 'Категория1.1',
	//         icon: '',
	//         targetValue: undefined,
	//         valueList: TYPE_ATTRIBUTE_DATA,
	//     },
	//     secondItem: {
	//         title: 'Категория1.2',
	//         icon: '',
	//         targetValue: undefined,
	//         valueList: TYPE_ATTRIBUTE_DATA,
	//     },
	//     // titleFirst: { title: 'Категория1', icon: '' },
	//     // titleSecond: { title: 'Тип1', icon: '' },
	//     // valueListFirst: TYPE_ATTRIBUTE_DATA,
	//     // valueListSecond: TYPE_ATTRIBUTE_DATA,
	//     // targetFirstValue: undefined,
	//     // targetSecondtValue: undefined,
	// },
	// {
	//     id: '1111',
	//     type: 'radio',
	//     title: { title: 'Категория2', icon: '' },
	//     target: 'Тип',
	//     valueList: [1, 2, 3],
	//     targetValue: undefined
	// },
	// {
	//     id: '2222',
	//     type: 'range',
	//     title: 'Категория3',
	//     target: 'id',
	//     value_min: undefined,
	//     value_max: undefined
	// },
);
