import axios from 'axios';
import { convertSortListForServer } from '../utils/convertSortListForServer';
import { convertFiltersForServer } from '../utils/convertFiltersForServer';
import { URL } from '../utils/constants';

export const getAttributeCategoryList = async (
	token,
	perPage,
	page,
	search,
	sorts,
	filtersData,
	props
) => {
	try {
		const sorters = sorts && convertSortListForServer(sorts);

		// const filters = filtersData.reduce((value, item) => {
		//     const filter = { type: "min_max", value_min: item.value_min || -99999999999, value_max: item.value_max || 99999999999 }
		//     return { ...value, [item.target]: filter }
		// }, {})
		const filters = filtersData && convertFiltersForServer(filtersData);

		const { data } = await axios.post(
			`${URL}/adminpanel/attr_category/smart_list/`,
			{
				page,
				search,
				per_page: perPage,
				sorters,
				filters,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data.results, data.count, data.max_pages);
	} catch (error) {
		console.error('Error:', error);
		props?.(null, null, null);
	}
};

export const getAttributeCategories = async (token, props) => {
	try {
		const { data } = await axios.get(`${URL}/adminpanel/attr_category/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		});
		props?.(data);
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
	}
};

export const createAttributeCategory = async (token, name, props) => {
	try {
		const { data } = await axios.post(
			`${URL}/adminpanel/attr_category/`,
			{
				name,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
	}
};

export const updateAttributeCategory = async (token, id, name, props) => {
	try {
		const { data } = await axios.patch(
			`${URL}/adminpanel/attr_category/${id}/`,
			{
				name,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
	}
};
