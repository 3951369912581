import React from 'react';
import noImage from '../../images/no-image.png';
import { Link } from 'react-router-dom';
export default function SecondCard({ setNextCard }) {
	return (
		<form onSubmit={setNextCard}>
			<div className='max-w-[730px] mt-[30px]'>
				<h3 className='text-[22px] font-semibold'>
					Изображения <span className='text-[#F98026]'>*</span>
				</h3>
				<p className='text-[#999999] text-[16px] font-normal mt-[10px]'>
					Добавьте хотябы одно изображение товара, а лучше несколько.
					Тогда мы сможем быстро проверить товар и создать карточку,
					если ее еще нет в каталоге.
				</p>
				<div className='flex justify-between items-center mt-[20px]'>
					<div className='bg-[#F8F8F8] text-[#999999] text-[14px] font-semibold px-[12px] py-[8px] rounded-[8px]'>
						0 фото
					</div>
					<div>
						<label
							htmlFor='file-upload'
							class='text-[#007AFF] text-[14px] font-medium cursor-pointer'>
							Добавить фото
						</label>
						<input
							className='hidden'
							id='file-upload'
							type='file'
						/>
					</div>
				</div>
				<div className='mt-[20px]'>
					<img src={noImage} alt='no image' />
				</div>
			</div>
			<div className='max-w-[730px] mt-[30px] flex justify-end items-center'>
				<Link to={'/catalog'}>
					<button
						type='button'
						className='py-[10px] px-[24px] bg-[#13131408] hover:bg-[#13131414] text-[#909090] rounded-[10px] mr-[20px]'>
						Отмена
					</button>
				</Link>
				<button
					type='submit'
					className='py-[10px] px-[32px] bg-[#007AFF] hover:bg-[#0d81ff]  text-[#FFFFFF] rounded-[10px]'>
					Сохранить и продолжить
				</button>
			</div>
		</form>
	);
}
