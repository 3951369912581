

function PhysicalCartItem(params) {
  return (
    <article className="physical-cart-item">
      <p className="physical-cart-item__text">Приход на 05.03.2023</p>
      <p className="physical-cart-item__text">5 743$</p>
      <p className="physical-cart-item__text">53 шт.</p>
    </article>
  )
};

export default PhysicalCartItem;
