import React, { useEffect } from "react";
import Categorys from "./Categorys";
import NavMenu from "./NavMenu";
import { useUserStore } from "../store/user";
import Header from "./Header";
import Footer from "./Footer";
import AuthController from "./AuthController";

export default function Wrapper({ children }) {
    const [isCategorysOpen, setCategorysOpen] = React.useState(false);
    const { checkLocalToken } = useUserStore()

    useEffect(() => {
        checkLocalToken()
    }, [])
    const toggleCategorysMenu = () => {
        setCategorysOpen(!isCategorysOpen);
    }

    const closeCategorysMenu = () => {
        setCategorysOpen(false);
    }
    return (
        <AuthController>
            <div className="page__wrapper">
                <NavMenu isCategorysOpen={isCategorysOpen} handleToggleCategorysMenu={toggleCategorysMenu} handleCloseCategorysMenu={closeCategorysMenu} />
                <section className="main-section">
                    <div className="main-section__container">
                        <Header />
                        {children}
                        <Footer />

                    </div>
                </section>
                <Categorys isCategorysOpen={isCategorysOpen} />
            </div>
        </AuthController>

    )
}
