import React, { useState } from 'react';
import Main from '../../components/Main';
import FirstCard from '../../components/ProductCreation/FirstCard';
import SecondCard from '../../components/ProductCreation/SecondCard';
import ThirdCard from '../../components/ProductCreation/ThirdCard';
import FourthCard from '../../components/ProductCreation/FourthCard';

const ProductCreation = () => {
	const [cardPosition, setCardPosition] = useState(1);

	function renderCard(param) {
		switch (param) {
			case 1:
				return <FirstCard setNextCard={() => setCardPosition(2)} />;
			case 2:
				return <SecondCard setNextCard={() => setCardPosition(3)} />;
			case 3:
				return <ThirdCard setNextCard={() => setCardPosition(4)} />;
			case 4:
				return <FourthCard setNextCard={() => setCardPosition(1)} />;
			default:
				return <p>Ошибочное значение</p>;
		}
	}

	return (
		<Main
			isInteractionPanel={false}
			isPagination={false}
			vendorCode={false}
			element={renderCard(cardPosition)}
			createButton={false}
			isFilterButton={false}
			handleOpenCreateModals={() => {}}
			title='Создание товара'
		/>
	);
};

export default ProductCreation;
