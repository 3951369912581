import { createPaginationStore } from '../common/createPaginationStore';
import { getAttributeCategoryList } from '../service/attributeCategory';
import { ReactComponent as MinMax } from '../images/MinMax.svg';

export const useAttributeCategoryStore = createPaginationStore(
	(options, props, errorProps) => {
		const { token, pageSize, targetPage, search, sorts, filters } = options;
		getAttributeCategoryList(
			token,
			pageSize,
			targetPage,
			search,
			sorts,
			filters,
			(attributeCategories, totalAttributeCategories, maxPages) => {
				if (
					attributeCategories &&
					typeof totalAttributeCategories === 'number' &&
					maxPages
				) {
					props(
						attributeCategories,
						totalAttributeCategories,
						maxPages
					);
				} else {
					errorProps();
				}
			}
		);
	},
	10,
	[
		{ id: '111', title: '№', value: false, target: 'id' },
		{ id: '333', title: 'Наименование', value: false, target: 'name' },
		{
			id: '222',
			title: 'Кол-во атрибутов',
			value: false,
			target: 'attr_count',
		},
	],
	{
		minMax: [
			{
				id: '1111',
				type: 'number',
				title: 'Кол-во атрибутов',
				icon: <MinMax />,
				target: 'attr_count',
				value: {},
			},
		],
	}
);
