import { Link } from "react-router-dom";

function EmptyCart(props) {
  return (
    <section className="empty-cart">
      <div className="empty-cart__image"></div>
      <p className="empty-cart__text">Похоже, в данный момент корзина пуста...</p>
      <Link to='/catalog' className="empty-cart__button">Перейти в каталог</Link>
    </section>
  )
};

export default EmptyCart;
