import emptyImage from "../images/nothing.jpg";

function EmptyContent() {
  return (
    <section className="empty-content">
      <img className="empty-content__image" src={emptyImage} alt="women near board" />
      <p className="empty-content__text">Похоже, в данный момент здесь ничего нет...</p>
    </section>
  )
};

export default EmptyContent;
