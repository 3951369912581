import { Link, NavLink } from 'react-router-dom';
import NavMenuCategory from './NavMenuCategory';
import React, { useState } from 'react';

function NavMenu(props) {
	const [targetNav, setTargetNav] = useState('');

	const checkIsOpenNav = (value) => {
		if (targetNav && targetNav === value) return true;
		return (
			window.location.href.includes(value) &&
			!window.location.href.endsWith(value)
		);
	};

	const checkIsAnimationNav = (value) => {
		return (
			window.location.href.includes(value) &&
			!window.location.href.endsWith(value)
		);
	};

	const onTargetNav = (value) => () => {
		if (
			window.location.href.includes(value) &&
			!window.location.href.endsWith(value)
		)
			return;

		if (value !== targetNav) setTargetNav(value);
		else setTargetNav('');
	};

	const clickNavStorekeeperButton = () => {
		props.handleCloseCategorysMenu();
	};

	const clickNavAdminpanelButton = () => {
		props.handleCloseCategorysMenu();
	};

	const clickNavCardButton = () => {
		props.handleCloseCategorysMenu();
	};

	const clickNavCatalogButtonCategory = () => {
		props.handleToggleCategorysMenu();
	};

	const resetNavButton = () => {
		props.handleCloseCategorysMenu();
	};

	return (
		<aside className='nav-menu'>
			<Link to='/catalog' className='nav-menu__logo' />
			<nav className='nav-menu__button-list'>
				<NavLink
					to='/catalog'
					className={({ isActive }) =>
						isActive
							? 'nav-menu__button_active'
							: 'nav-menu__button'
					}
					onClick={onTargetNav('catalog')}>
					Каталог
				</NavLink>
				<NavMenuCategory
					isOpen={checkIsOpenNav('catalog')}
					isAnimation={window.location.href.includes('catalog')}>
					<Link
						to='/catalog'
						onClick={clickNavCatalogButtonCategory}
						className={
							props.isCategorysOpen
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Категории
					</Link>
				</NavMenuCategory>
				<NavLink
					to='/carts'
					className={({ isActive }) =>
						isActive
							? 'nav-menu__button_active'
							: 'nav-menu__button'
					}
					onClick={onTargetNav('carts')}>
					Корзина
				</NavLink>
				<NavMenuCategory
					isOpen={checkIsOpenNav('carts')}
					isAnimation={window.location.href.includes('carts')}>
					<NavLink
						to='/carts/physicals'
						onClick={clickNavCardButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Физические корзины
					</NavLink>
				</NavMenuCategory>
				<NavLink
					to='/logist'
					onClick={resetNavButton}
					className={({ isActive }) =>
						isActive
							? 'nav-menu__button_active'
							: 'nav-menu__button'
					}>
					Логистика
				</NavLink>
				<Link
					className={
						checkIsOpenNav('storekeeper')
							? 'nav-menu__button_active'
							: 'nav-menu__button'
					}
					onClick={onTargetNav('storekeeper')}>
					Склады
				</Link>
				<NavMenuCategory
					isOpen={checkIsOpenNav('storekeeper')}
					isAnimation={checkIsAnimationNav('storekeeper')}>
					<NavLink
						to='/storekeeper/inventory_control'
						onClick={clickNavStorekeeperButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Оприходования
					</NavLink>
					<NavLink
						to='/storekeeper/relocation'
						onClick={clickNavStorekeeperButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Перемещения
					</NavLink>
					<NavLink
						to='/storekeeper/balances'
						onClick={clickNavStorekeeperButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Остатки
					</NavLink>
				</NavMenuCategory>
				{/* <NavLink
                    to='/deals'
                    onClick={resetNavButton}
                    className={({ isActive }) => isActive ? 'nav-menu__button_active' : 'nav-menu__button'}>
                    Сделки
                </NavLink> */}
				<Link
					className={
						checkIsOpenNav('adminpanel')
							? 'nav-menu__button_active'
							: 'nav-menu__button'
					}
					onClick={onTargetNav('adminpanel')}>
					Админ панель
				</Link>
				<NavMenuCategory
					isOpen={checkIsOpenNav('adminpanel')}
					isAnimation={checkIsAnimationNav('adminpanel')}>
					<NavLink
						to='/adminpanel/attributes'
						onClick={clickNavAdminpanelButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Атрибуты
					</NavLink>
					<NavLink
						to='/adminpanel/attributes_category'
						onClick={clickNavAdminpanelButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Категории атрибутов
					</NavLink>
					<NavLink
						to='/adminpanel/products_category'
						onClick={clickNavAdminpanelButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Категории товаров
					</NavLink>
					<NavLink
						to='/adminpanel/system_users'
						onClick={clickNavAdminpanelButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Пользователи
					</NavLink>
					<NavLink
						to='/adminpanel/brands'
						onClick={clickNavAdminpanelButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Бренды
					</NavLink>
					<NavLink
						to='/adminpanel/suppliers/'
						onClick={clickNavAdminpanelButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Поставщики
					</NavLink>
					<NavLink
						to='/adminpanel/logist'
						onClick={clickNavAdminpanelButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Логистика
					</NavLink>
					<NavLink
						to='/adminpanel/storekeeper'
						onClick={clickNavAdminpanelButton}
						className={({ isActive }) =>
							isActive
								? 'nav-menu__category-button_active'
								: 'nav-menu__category-button'
						}>
						Склады
					</NavLink>
				</NavMenuCategory>
			</nav>
		</aside>
	);
}

export default NavMenu;
