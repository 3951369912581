class Api {
    constructor(options) {
        this._basePath = options.basePath;
    }

    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(`Ошибка: ${res.status}`);
    }

    getPageData(path, body) {
        return fetch(`${this._basePath + path}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body),
        })
            .then(this._checkResponse);
    }
}

export const api = new Api({
    basePath: process.env.REACT_APP_PATH,
});

export const API = new Api({
    basePath: process.env.REACT_APP_PATH,
});