import axios from 'axios';

import { URL } from '../utils/constants';

export const getDocumentType = async (token, props) => {
	try {
		const { data } = await axios.get(
			`${URL}/logist/viewsets/documents_type/`,
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
	}
};
