import { useForm } from 'react-hook-form';
import ModalWindowWithForm from './ModalWindowWithForm';
import { useUserStore } from '../store/user';
import { useEffect, useState } from 'react';
import { useProductCategoryStore } from '../store/productCategory';
import {
	createProductCategory,
	updateProductCategory,
} from '../service/productCategory';
import { useDecoratedCommonStore } from '../hooks/useDecoratedCommonStore';

import { useCommonAttributeCategoryStore } from '../store/commonAttributeCategory';
import Select from './UI/Select';
import { useCommonProductCategoryStore } from '../store/commonProductCategory';

function CategoryItemsModalWindow({
	title,
	name,
	modalWidth,
	isOpen,
	handleCloseAllModals,
	confirmButtonText,
	initialData,
}) {
	const { data: productCategoryData } = useDecoratedCommonStore(
		useCommonProductCategoryStore
	);
	const { token } = useUserStore();

	const [selectedProductCategoryItem, setSelectedProductCategoryItem] =
		useState();

	const { updateDataList } = useProductCategoryStore();
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		if (!token) return;
		const { name } = data;
		if (initialData) {
			updateProductCategory(
				token,
				initialData.id,
				name,
				selectedProductCategoryItem?.id,
				(result) => {
					if (result) {
						updateDataList(token);
						handleCloseAllModals();
					}
				}
			);
		} else {
			createProductCategory(
				token,
				name,
				selectedProductCategoryItem?.id,
				(result) => {
					if (result) {
						updateDataList(token);
						handleCloseAllModals();
					}
				}
			);
		}
	};

	useEffect(() => {
		if (productCategoryData && initialData) {
			setSelectedProductCategoryItem(
				productCategoryData.find((item) => {
					if (item.id === initialData.parent) {
						return item;
					}
				})
			);
		}
	}, [initialData, productCategoryData]);

	useEffect(() => {
		if (initialData) {
			setValue('name', initialData.name);
		}
	}, [initialData]);

	return (
		<ModalWindowWithForm
			resetForm={reset}
			onSubmit={handleSubmit(onSubmit)}
			title={title}
			name={name}
			modalWidth={modalWidth}
			isOpen={isOpen}
			handleCloseAllModals={handleCloseAllModals}>
			<div className='attributes-modal'>
				<div className='attributes-modal__container'>
					<label className='attributes-modal__field-name'>
						Название категории
					</label>
					<input
						id='category-name-input'
						placeholder='Название атрибута'
						className='attributes-modal__input zero-input'
						{...register('name', { required: true })}
					/>
				</div>
				<div className='attributes-modal__container'>
					<label className='attributes-modal__field-name'>
						Род. категория
					</label>
					<Select
						id='parent-category-select'
						filter={(item, search) =>
							item.name
								?.toLowerCase()
								.includes(search.toLowerCase())
						}
						options={productCategoryData}
						targetOptionst={selectedProductCategoryItem}
						setTargetOptions={(value) => {
							console.log(value);
							setSelectedProductCategoryItem({
								id: value.id,
								name: value.name,
							});
						}}
						placeholder='Категория атрибута'
					/>
				</div>
				<div className='document-item'>
					<label className='document-list-header'>
						Фото категории
					</label>
					<div className='document-status'>
						<p className=''>Добавить</p>
					</div>
				</div>
			</div>
			<div className='attributes-modal__flex-wrapper'>
				<button
					type='button'
					className='cansel-button zero-button'
					onClick={() =>
						handleCloseAllModals((state) => (state = !isOpen))
					}>
					Отмена
				</button>
				<button type='submit' className='confirm-button zero-button'>
					{confirmButtonText}
				</button>
			</div>
		</ModalWindowWithForm>
	);
}

export default CategoryItemsModalWindow;
