import Select from './UI/Select';

function CheckedsFilterPopup({ filter, setValueFilter }) {
	const { dataList, title, values } = filter;
	const toggleValue = (targetId) => {
		return () => {
			const isIncludes = values?.includes((id) => id === targetId);
			if (isIncludes) {
				setValueFilter(values.filter((id) => id !== targetId));
			} else {
				setValueFilter([...values, targetId]);
			}
		};
	};

	return (
		<div className='filter-popup__wrapper w-full flex flex-col pl-4 gap-4 py-4'>
			{/* <h2 className='filter-popup__subtitle flex gap-x-2'>{title}</h2> */}
			{dataList.map((item) => {
				const isChecked = values?.includes((id) => id === item.id);
				return (
					<label className='flex w-full justify-between cursor-pointer p-2'>
						<p className='filter-popup__subtitle !m-0 flex gap-x-2'>
							{item.name}
						</p>
						<input
							type='checkbox'
							className='peer hidden'
							onChange={toggleValue(item.id)}
							value={isChecked}
						/>
						<div
							className={` relative w-10 h-5 bg-[#B5B5B5] peer-checked:bg-[#007AFF] peer-checked:*:left-6 *:left-[3px] rounded-2xl mr-[10px]`}>
							<div
								className={`absolute top-[3px]  w-3.5 h-3.5 rounded-full bg-[#FFF] transition-all duration-150 ease-out`}></div>
						</div>
					</label>
				);
			})}
			{/* <Select
				options={dataList}
				targetOptionst={values[0]}
				setTargetOptions={toggleValue}
				placeholder={title}
			/> */}
		</div>
	);
}

export default CheckedsFilterPopup;
