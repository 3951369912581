import { useEffect, useState } from "react";
import Main from "../../components/Main";
import Table from "../../components/Table";
import { useAPLogistStore } from "../../store/adminPanelLogist";
import {
  acceptanceOfApplication,
  rejectionOfApplication,
  getInformationAboutApplication,
  getInformationVendorName,
} from "../../service/adminPanelLogist";
import { useUserStore } from "../../store/user";
import ViewLogistInfo from "../../components/modals/ViewLogistInfo";
import { useDecoratedCommonStore } from "../../hooks/useDecoratedCommonStore";
import { useCommonAPLogistStore } from "../../store/commonAPLogist";

export default function Logist() {
  const { data, isLoading, setFilters, targetPage, updateDataList, filters } = useAPLogistStore();
  const { data: vendorNames } = useDecoratedCommonStore(useCommonAPLogistStore);
  const { token } = useUserStore();

  const [isNothingFound, setIsNothingFound] = useState(false);

  const [isOpenViewBrandModal, setIsOpenViewBrandModal] = useState(false);
  const [selectedItemFromTable, setSelectedItemFromTable] = useState(false);
  const [isVendorName, setIsVendorName] = useState(null);
  const titles = [
    {
      title: "№",
      style: "",
    },
    {
      title: "Инвойс",
      style: "",
    },
    {
      title: "Старый статус",
      style: "text-center",
    },
    {
      title: "Новый статус",
      style: "text-center",
    },
    {
      title: "ФИО Логиста",
      style: "",
    },
    {
      title: "Статус заявки",
      style: "text-center",
    },
  ];

  useEffect(() => {
    if (vendorNames) {
      let isUpdated = false;
      const data = {
        ...filters,
        checkedColumn: filters.checkedColumn.map((item) => {
          if (item.target !== "shipping__user_id") return item;
          isUpdated = true;
          return {
            ...item,
            dataList: vendorNames,
          };
        }),
      };
      if (isUpdated) setFilters(data);
    }
  }, [vendorNames]);

  const closeModals = () => {
    setIsOpenViewBrandModal(false);
  };

  const statusLogist = {
    0: {
      name: "конвертация",
      style: "bg-[#FFB628] text-[#FFF] text-[16px] text-center w-full px-[12px] py-[6px] rounded-[8px]",
    },
    1: {
      name: "в пути",
      style: "bg-[#007AFF] text-[#FFF] text-[16px] text-center w-full px-[12px] py-[6px] rounded-[8px]",
    },
    2: {
      name: "на таможне",
      style: "bg-[#ABB9C6] text-[#FFF] text-[16px] text-center w-full px-[12px] py-[6px] rounded-[8px]",
    },
    3: {
      name: "товар растаможен",
      style: "bg-[#3CDE7D] text-[#FFF] text-[16px] text-center w-full px-[12px] py-[6px] rounded-[8px]",
    },
    4: {
      name: "на складе",
      style: "",
    },
  };

  const applicationStatusLogist = {
    0: {
      name: "кнопки",
      style: "",
    },
    1: {
      name: "Принята",
      style: "text-[#3CDE7D] text-[16px] text-center w-full px-[12px] py-[6px]",
    },
    2: {
      name: "Отклонена",
      style: "text-[#FF3E3E] text-[16px] text-center w-full px-[12px] py-[6px]",
    },
  };

  const onGetInformation = async (id, event) => {
    if (!!token && event.target.localName !== "button") {
      const data = await getInformationAboutApplication(token, id);
      setSelectedItemFromTable(data);
      setIsOpenViewBrandModal(true);
    }
  };

  const onApplicationSubmit = (id) => {
    if (!!token) {
      acceptanceOfApplication(token, id);
      updateDataList(token);
    }
  };

  const onRejectSubmit = (id) => {
    if (!!token) {
      rejectionOfApplication(token, id);
      updateDataList(token);
    }
  };

  return (
    <Main
      isInteractionPanel={true}
      paginationStore={useAPLogistStore}
      modals={
        <>
          <ViewLogistInfo
            title="Просмотр заявки"
            handleCloseAllModals={closeModals}
            isOpen={isOpenViewBrandModal}
            name="view-logist-modal"
            initialData={selectedItemFromTable}
            modalWidth={{ width: "600px" }}
          />
        </>
      }
      element={
        <Table
          handleCloseAllModals={() => {}}
          handleOpenTableModals={() => {}}
          isNothingFound={isNothingFound}
          isLoading={isLoading}
          tableData={{
            titles,
            content: data,
          }}
          depicter={(item, onClick, specialClass) => {
            const { id, invoice, curr_stage, need_stage, first_name, last_name, middle_name, status, shipping } = item;
            return (
              <tr
                onClick={(event) => {
                  onGetInformation(shipping, event);
                }}
                className={`table__row ${specialClass}`}
              >
                <td className="table__content">{id}</td>
                <td className="table__content block !truncate">{invoice}</td>
                <td className={`table__content block !truncate ${statusLogist[curr_stage].style}`}>
                  {statusLogist[curr_stage].name}
                </td>
                <td className={`table__content block !truncate ${statusLogist[need_stage].style}`}>
                  {statusLogist[need_stage].name}
                </td>
                <td className="table__content block !truncate">{`${first_name} ${last_name} ${
                  middle_name === null && ""
                }`}</td>
                <td className={`table__content block !truncate ${applicationStatusLogist[status]?.style}`}>
                  {status !== 0 ? (
                    applicationStatusLogist[status].name
                  ) : (
                    <div className="flex justify-between ">
                      <button
                        className="bg-[#2886EB1A] text-[#007AFF] px-[26px] py-[10px] rounded-[10px] hover:bg-[#2886eb3b]"
                        onClick={() => onApplicationSubmit(id)}
                      >
                        Да
                      </button>
                      <button
                        className="bg-[#13131408] text-[#909090] px-[26px] py-[10px] rounded-[10px] hover:bg-[#13131417]"
                        onClick={() => onRejectSubmit(id)}
                      >
                        Нет
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            );
          }}
          specialClass="grid-cols-APlogistColumns gap-[40px]"
        />
      }
      createButton={false}
      isFilterButton={true}
      title="Логистика"
      placeholderText="Номер инвойса"
    />
  );
}
