function CartInfo(props) {
  return (
    <section className="cart-info">
      <article className="cart-info__total">
        <div className="cart-info__flex-wrapper">
          <h2 className="cart-info__title">Итого</h2>
          <p className="cart-info__price">732 823 $</p>
        </div>
        <p className="cart-info__items">Всего: 14 товаров</p>
      </article>
    </section>
  )
};

export default CartInfo;
