import React from 'react';

function ModalWindowWithForm({
	isOpen,
	handleCloseAllModals,
	modalWidth,
	title,
	name,
	children,
	onSubmit,
}) {
	const ref = React.useRef();
	const handleClickCloseButton = () => {
		handleCloseAllModals();
	};

	const handleClickOutBorder = (evt) => {
		if (evt.target.classList.contains('modal')) {
			handleCloseAllModals();
		}
	};

	const onKeydown = (evt) => {
		if (evt.key === 'Escape') {
			handleCloseAllModals();
		}
	};

	React.useEffect(() => {
		if (isOpen) document.addEventListener('keydown', onKeydown);
		return () => {
			document.removeEventListener('keydown', onKeydown);
		};
	}, [isOpen]);

	return (
		<>
			{isOpen && (
				<div
					className={`  max-h-screen overflow-y-auto fixed left-0 top-0 right-0 bottom-0 z-40 ${
						!isOpen && 'hidden'
					}`}>
					<section
						onMouseDown={handleClickOutBorder}
						className={` py-5 min-h-screen relative modal `}>
						<div className='modal__container' style={modalWidth}>
							<button
								onClick={handleClickCloseButton}
								className='modal__close-button zero-button'></button>
							{!!title && (
								<h2 className='modal__title max-w-xs'>
									{title}
								</h2>
							)}
							<form
								onSubmit={onSubmit}
								ref={ref}
								action='#'
								method='post'
								name={name}
								className='modal__form'
								id={`${name}-form`}>
								{children}
							</form>
						</div>
					</section>
				</div>
			)}
		</>
	);
}

export default ModalWindowWithForm;
