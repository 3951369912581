import React, { useEffect } from "react";
import EmptyContent from "./EmptyContent";
import Preloader from "./Preloader";
function Table({
  isLoading,
  specialClass,
  tableData: { content, titles },
  handleOpenTableModals,
  handleCloseAllModals,
  depicter,
}) {
  const handleClickRow = (evt) => {
    // handleOpenTableModals(Array.from(evt.target.closest(".table__row").children).map((item) => item.textContent));
  };
  useEffect(() => {
    handleCloseAllModals();
    // getData();
  }, [specialClass]);

  return (
    <div className="table-container">
      {isLoading && <Preloader />}
      {isLoading ? (
        ""
      ) : content?.length === 0 ? (
        <EmptyContent />
      ) : (
        <div className="block">
          <div className="table__head">
            <div className={`border-b-[1px] border-[#0000001F] table__row ${specialClass}`}>
              {titles.map((title, index) => (
                <div className={`table__title ${title.style}`} key={index}>
                  {title.title}
                </div>
              ))}
            </div>
          </div>
          <div className="table__body">
            {content.map((item, index) => {
              return depicter ? (
                depicter(item, handleClickRow, specialClass)
              ) : (
                <div onClick={handleClickRow} key={index} className={`table__row ${specialClass}`}>
                  {item?.map((item, index) => (
                    <div className="table__content" key={index}>
                      {item}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Table;
