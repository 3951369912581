import axios from 'axios';
import { URL } from '../utils/constants';

export const getCompany = async (token, props) => {
	try {
		const { data } = await axios.get(`${URL}/adminpanel/company_crud/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		});
		props?.(data);
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
	}
};
