import { Link } from "react-router-dom";
import CategorysSearch from "./CategorysSearch";

function Categorys(props) {
    
    return (

        <section className={`${props.isCategorysOpen ? 'categorys_opened' : ' !-left-full'} categorys h-full`}>
            <CategorysSearch />
            <div className="categorys__grid-wrapper">
                <article className="categorys__section">
                    <h2 className="categorys__title">Category</h2>
                    <Link className="categorys__item">Хим. продукты</Link>
                    <Link className="categorys__item">Металл</Link>
                    <Link className="categorys__item">Молочные продукты</Link>
                    <Link className="categorys__item">Стеклотара</Link>
                    <Link className="categorys__item">Каучук</Link>
                    <Link className="categorys__item">Торф</Link>
                    <Link className="categorys__item">Ферросплавы</Link>
                </article>
                <article className="categorys__section">
                    <h2 className="categorys__title">Category</h2>
                    <Link className="categorys__item">Хим. продукты</Link>
                    <Link className="categorys__item">Металл</Link>
                    <Link className="categorys__item">Молочные продукты</Link>
                    <Link className="categorys__item">Стеклотара</Link>
                    <Link className="categorys__item">Каучук</Link>
                    <Link className="categorys__item">Торф</Link>
                    <Link className="categorys__item">Ферросплавы</Link>
                </article>
                <article className="categorys__section">
                    <h2 className="categorys__title">Category</h2>
                    <Link className="categorys__item">Хим. продукты</Link>
                    <Link className="categorys__item">Металл</Link>
                    <Link className="categorys__item">Молочные продукты</Link>
                    <Link className="categorys__item">Стеклотара</Link>
                    <Link className="categorys__item">Каучук</Link>
                    <Link className="categorys__item">Торф</Link>
                    <Link className="categorys__item">Ферросплавы</Link>
                </article>
                <article className="categorys__section">
                    <h2 className="categorys__title">Category</h2>
                    <Link className="categorys__item">Хим. продукты</Link>
                    <Link className="categorys__item">Металл</Link>
                    <Link className="categorys__item">Молочные продукты</Link>
                    <Link className="categorys__item">Стеклотара</Link>
                    <Link className="categorys__item">Каучук</Link>
                    <Link className="categorys__item">Торф</Link>
                    <Link className="categorys__item">Ферросплавы</Link>
                </article>
            </div>
        </section>
    )
};

export default Categorys;
