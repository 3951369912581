import axios from "axios";
import { URL } from "../utils/constants";
import { convertFiltersForServer } from "../utils/convertFiltersForServer";
import { convertSortListForServer } from "../utils/convertSortListForServer";

export const getAPLogistList = async (token, perPage, page, search, sorts, filtersData, props) => {
  try {
    const sorters = convertSortListForServer(sorts);

    const filters = filtersData && convertFiltersForServer(filtersData);

    const { data } = await axios.post(
      `${URL}/logist/viewsets/logist_shipping_admin_panel/smart_list/`,
      {
        page,
        search,
        per_page: perPage,
        sorters,
        filters,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    props?.(data.results, data.count, data.max_pages);
    return { logistAPList: data.results, totalLogistAP: data.count, maxPages: data.max_pages };
  } catch (error) {
    console.error("Error:", error);
    props?.(null, null);
    return null;
  }
};

export const rejectionOfApplication = async (token, id, props) => {
  try {
    const { data } = await axios.get(`${URL}/logist/viewsets/logist_shipping_admin_panel/${id}/reject_need_stage/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    props?.(data);
  } catch (error) {
    console.error("Error:", error);
    props?.(null);
  }
};

export const acceptanceOfApplication = async (token, id, props) => {
  try {
    const { data } = await axios.get(`${URL}/logist/viewsets/logist_shipping_admin_panel/${id}/accept_need_stage/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    props?.(data);
  } catch (error) {
    console.error("Error:", error);
    props?.(null);
  }
};

export const getInformationAboutApplication = async (token, id, props) => {
  try {
    const { data } = await axios.get(`${URL}/logist/viewsets/logist_shippings/${id}/smart_detail/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    props?.(data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    props?.(null);
  }
};

export const getInformationVendorName = async (token, props) => {
  try {
    const { data } = await axios.post(
      `${URL}/role_dynamic_link/get_users_by_role_codename/`,
      { codename: "logist" },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    props?.(data);
    return data;
  } catch (error) {
    console.error("Error:", error);
    if (axios.isAxiosError(error)) {
      props(error?.response?.data.success);
    } else {
      console.log(error);
      console.error("Error:", error);
    }
  }
};
