import { createCommonStore } from "../common/createCommonStore";
import { getInformationVendorName } from "../service/adminPanelLogist";

export const useCommonAPLogistStore = createCommonStore((token, props) => {
  getInformationVendorName(token, (result) => {
    if (result) {
      props(result);
    }
  });
});
