import axios from 'axios';
import { convertSortListForServer } from '../utils/convertSortListForServer';
import { URL } from '../utils/constants';
import { convertFiltersForServer } from '../utils/convertFiltersForServer';

export const getProductCategoryList = async (
	token,
	perPage,
	page,
	search,
	sorts,
	filtersData,
	props
) => {
	try {
		const sorters = sorts && convertSortListForServer(sorts);

		const filters = filtersData && convertFiltersForServer(filtersData);

		const { data } = await axios.post(
			`${URL}/adminpanel/category/smart_list/`,
			{
				page,
				search,
				per_page: perPage,
				sorters,
				// filters,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data.results, data.count, data.max_pages);
	} catch (error) {
		console.error('Error:', error);
		props?.(null, null, null);
	}
};

export const getProductCategories = async (token, props) => {
	try {
		const { data } = await axios.get(`${URL}/adminpanel/category/`, {
			headers: {
				Authorization: `Token ${token}`,
			},
		});
		props?.(data);
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
	}
};

export const createProductCategory = async (token, name, parent, props) => {
	try {
		const { data } = await axios.post(
			`${URL}/adminpanel/category/`,
			{
				name,
				parent,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
	}
};

export const updateProductCategory = async (token, id, name, parent, props) => {
	try {
		const { data } = await axios.patch(
			`${URL}/adminpanel/category/${id}/`,
			{
				name,
				parent,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
	}
};
