import React from "react";
import Main from "../../components/Main";
import Table from "../../components/Table";

export default function Balances() {
    return (
        <Main
            isInteractionPanel={true}
            createButton={true}
            isFilterButton={true}
            handleOpenCreateModals={() => { }}
            title="Остатки"
            placeholderText="Артикул или наименование"
        >
            <div className="block">
                <div className="block">
                    <div className={`grid-cols-balanceColumns grid mt-[20px] gap-[30px] p-[10px] items-center  border-b-[1px]`}>
                        <div className="text-[#2886EB] text-bl text-base font-normal text-start">Артикул</div>
                        <div className="text-[#2886EB] text-base font-normal text-start">Наименование</div>
                        <div className="text-[#2886EB] text-base font-normal text-center">Доступно к бронированию</div>
                        <div className="text-[#2886EB] text-base font-normal text-center">Забронировано</div>
                    </div>
                </div>
                <div class="table__body">
                    <div class=" grid-cols-balanceColumns grid mt-[20px] gap-[30px] p-[10px] items-center cursor-pointer hover:bg-[#1313140a] hover:rounded-[5px]">
                        <div class="text-[#787878] text-base font-normal text-left">100 045 646</div>
                        <div class="text-[#787878] text-base font-normal text-left">Zxc Ghoul SSl+8345s</div>
                        <div class="text-[#787878] text-base font-normal text-center">2 шт.</div>
                        <div class="text-[#787878] text-base font-normal text-center">14шт.</div>
                    </div>
                    <div class="grid-cols-balanceColumns grid mt-[20px] gap-[30px] p-[10px] items-center cursor-pointer hover:bg-[#1313140a] hover:rounded-[5px]">
                        <div class="text-[#787878] text-base font-normal text-left">764 312 745</div>
                        <div class="text-[#787878] text-base font-normal text-left">Venti 32Fjvs312</div>
                        <div class="text-[#787878] text-base font-normal text-center">5 шт.</div>
                        <div class="text-[#787878] text-base font-normal text-center">5 шт.</div>
                    </div>
                </div>
            </div>
        </Main>

    );
}
