import axios from 'axios';
import { URL } from '../utils/constants';
import { uploadFile } from './file';

export const getLogistShipping = async (
	token,
	perPage,
	page,
	filters,
	props
) => {
	try {
		const { data } = await axios.post(
			`${URL}/logist/viewsets/logist_shippings/smart_list/`,
			{
				filters,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);

		props?.(data, 1);
		return { logistShippingList: data, totalLogistShipping: 1 };
	} catch (error) {
		console.error('Error:', error);
		props?.(null, null);
		return null;
	}
};

export const getLogistShippingDetail = async (token, id, props) => {
	try {
		const { data } = await axios.get(
			`${URL}/logist/viewsets/logist_shippings/${id}/smart_detail/`,
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
		return data;
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
		return null;
	}
};

export const changeStageToNextLogistShipping = async (token, id, props) => {
	try {
		const { data } = await axios.post(
			`${URL}/logist/viewsets/logist_shippings/${id}/change_stage_to_next/`,
			{},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
		return data;
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
		return null;
	}
};

export const changeStageToPrevLogistShipping = async (token, id, props) => {
	try {
		const { data } = await axios.post(
			`${URL}/logist/viewsets/logist_shippings/${id}/change_stage_to_prev/`,
			{},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
		return data;
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
		return null;
	}
};

export const createLogistShipping = async (
	token,
	invoice_str,
	vendor,
	pay_date,
	user,
	warehouse,
	unique_products,
	services,
	props
) => {
	try {
		const { data } = await axios.post(
			`${URL}/logist/viewsets/logist_shippings/smart_create/`,
			{
				invoice_str,
				vendor,
				pay_date,
				user,
				warehouse,
				unique_products,
				services,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
		return { data };
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
		return null;
	}
};

export const attachDocumentsToLogistShipping = async (
	token,
	LogistShippingId,
	files,
	props
) => {
	try {
		const data = await files.map(async (item) => {
			const formData = new FormData();
			const { id, file } = item;
			const spliteFileName = file.name?.split('.');
			console.log(spliteFileName);
			//    {
			//     "id": 1,
			//     "name": "Документ Уникальный",
			//     "is_required": false,
			//     "required_stage": 2,
			//     "show_always": true,
			//     "unique": true,
			//     file: file
			//   },
			const { data } = await axios.post(
				`${URL}/logist/viewsets/logist_documents/${LogistShippingId}/create_document_upload_meta/`,
				{
					file_extension: spliteFileName[1],
					document_type_id: id,
					file_name: spliteFileName[0],
				},
				{
					headers: {
						Authorization: `Token ${token}`,
					},
				}
			);
			const { upload_meta: fileData, media_id } = data;
			const { key, AWSAccessKeyId, policy, signature } = fileData.fields;
			formData.append('key', key);
			formData.append('AWSAccessKeyId', AWSAccessKeyId);
			formData.append('policy', policy);
			formData.append('signature', signature);
			formData.append('file', file);
			await axios.post(fileData.url, formData, {
				headers: {
					Authorization: `Token ${token}`,
				},
			});
			return media_id;
		});

		// const { data } = await axios.post(
		// 	`${URL}/logist/viewsets/logist_shippings/smart_create/`,
		// 	{
		// 		invoice_str,
		// 		vendor,
		// 		pay_date,
		// 		user,
		// 		warehouse,
		// 		products,
		// 		services,
		// 	},
		// 	{
		// 		headers: {
		// 			Authorization: `Token ${token}`,
		// 		},
		// 	}
		// );
		props?.(data);
		return { data };
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
		return null;
	}
};
