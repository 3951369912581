import { ReactComponent as PathArrow } from '../images/pathArrow.svg';
import user from '../images/user.svg';
import dollar from '../images/Dollar.svg';
import arrow from '../images/arrow.svg';
import userDuotone from '../images/UserDuotone.svg';
import SignOutSqureFill from '../images/Sign_out_squre_fill.svg';
import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { useDecoratedCommonStore } from '../hooks/useDecoratedCommonStore';
import { useCommonCurrentRateStore } from '../store/commonCurrentRate';
import { useUserStore } from '../store/user';

function Header() {
	const { data: currentRate } = useDecoratedCommonStore(
		useCommonCurrentRateStore
	);
	const { exit, rate, setRate } = useUserStore();
	const navigate = useNavigate();
	const exitBTN = () => {
		exit();
		navigate('/login');
	};
	const [isOpenProfile, setIsOpenProfile] = useState(false);

	const onChangeCheckbox = (e) => {
		setRate(!rate);
		console.log(1);
	};

	const onChangeButtonProfile = (e) => {
		setIsOpenProfile((state) => !state);
	};
	const nav = window.location;
	let path = nav.hash.slice(1, nav.hash.length);
	useEffect(() => {
		path = nav.hash.slice(1, nav.hash.length);
	}, [nav]);

	const renderRoot = (path) => {
		switch (path) {
			case '/user':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>
							Домашняя страница
						</span>
					</div>
				);
			case '/adminpanel/attributes':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>
							Панель администрирования
						</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>Атрибуты</span>
					</div>
				);

			case '/adminpanel/attributes_category':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>
							Панель администрирования
						</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>
							Категории атрибутов
						</span>
					</div>
				);
			case '/adminpanel/products_category':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>
							Панель администрирования
						</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>
							Категории товаров
						</span>
					</div>
				);
			case '/adminpanel/system_users':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>
							Панель администрирования
						</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>
							Пользователи системы
						</span>
					</div>
				);
			case '/storekeeper/inventory_control':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>Склады</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>Оприходования</span>
					</div>
				);
			case '/storekeeper/relocation':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>Склады</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>Перемещения</span>
					</div>
				);
			case '/storekeeper/balances':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>Склады</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>Остатки</span>
					</div>
				);

			case '/catalog':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>Каталог</span>
					</div>
				);

			case '/carts':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>Корзины</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>
							Физические корзины
						</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>
							Поставка 03.03.2023
						</span>
					</div>
				);
			case '/carts/physicals':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>Корзины</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>
							Физические корзины
						</span>
					</div>
				);
			case '/logist':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>Логистика</span>
					</div>
				);
			case '/adminpanel/brands':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>
							Панель администрирования
						</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>Бренды</span>
					</div>
				);

			case '/adminpanel/suppliers/':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>
							Панель администрирования
						</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>Поставщики</span>
					</div>
				);
			case '/adminpanel/logist':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>
							Панель администрирования
						</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>Логистика</span>
					</div>
				);
			case '/adminpanel/storekeeper':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>EkomUz</span>
						<PathArrow className='header__arrow' />
						<span className='header__previous-path'>
							Панель администрирования
						</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>Склады</span>
					</div>
				);

			case '/adminpanel/create_prodact/':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>Каталог</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>
							Создание товара
						</span>
					</div>
				);
			case '/adminpanel/update_prodact/':
				return (
					<div className='header__path'>
						<span className='header__previous-path'>Каталог</span>
						<PathArrow className='header__arrow' />
						<span className='header__real-path'>
							Редактирование товара
						</span>
					</div>
				);

			default:
				break;
		}
	};
	const root = renderRoot(path);
	return (
		<header className='header'>
			<div className='flex justify-between w-full'>
				<div>{root}</div>

				<div className='flex'>
					<div className='flex items-center h-[45px] mr-[16px] text-[#007AFF] bg-[#13131408] px-[16px] rounded-[10px]'>
						<div className=' flex items-center'>
							Цена в
							<div className='ml-[8px] mr-[16px]'>
								<img src={dollar} width={28} height={28} />
							</div>
						</div>

						<div
							className='flex h-[33px] items-center cursor-pointer'
							// onClick={onChangeCheckbox}
						>
							<label
								className={`cursor-pointer relative w-10 h-5  ${
									rate ? 'bg-[#007AFF]' : 'bg-[#B5B5B5]'
								}  rounded-2xl mr-[10px]`}>
								<div
									className={`absolute top-[3px]  ${
										rate ? 'left-6' : 'left-[3px]'
									} w-3.5 h-3.5 rounded-full bg-[#FFF] transition-all duration-150 ease-out`}></div>
								<input
									type='checkbox'
									className='hidden'
									value={rate}
									onChange={onChangeCheckbox}
								/>
							</label>
						</div>
					</div>

					<div className='flex items-center h-[45px] mr-[16px] text-[#909090] bg-[#13131408] px-[16px] rounded-[10px]'>
						{currentRate?.rate_usd_sum} Сум/$
					</div>
					<div className='relative'>
						<div className='header__user-panel bg-[#13131408]'>
							<img
								src={user}
								alt='Пользователь'
								className='header__user-icon'
							/>
							<div className='header__user-data'>
								<span className='header__user-name text-[#007AFF]'>
									Иванов И. И.
								</span>
							</div>
							<button
								className=''
								type='button'
								onClick={onChangeButtonProfile}>
								<img src={arrow} alt='btn arrow' />
							</button>
						</div>
						{!!isOpenProfile && (
							<div className=" bg-[#13131408] w-full p-[15px] rounded-[10px] mt-[15px] absolute before:content-['▲'] before:text-[#13131408] before:absolute before:top-[-16px] before:left-[50%]">
								<div className='flex items-center cursor-pointer px-[5px] group'>
									<div className='mr-[10px]'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											className='fill-[#9198A1] group-hover:fill-[#595E64]'>
											<circle cx='12' cy='8' r='4' />{' '}
											{/**fill="#595E64" */}
											<path
												fill-rule='evenodd'
												clip-rule='evenodd'
												d='M11.9999 13C8.3302 13 5.32004 15.4204 5.02383 18.5004C4.9974 18.7753 5.22377 19 5.49991 19H18.4999C18.7761 19 19.0024 18.7753 18.976 18.5004C18.6798 15.4204 15.6696 13 11.9999 13Z'
											/>
										</svg>
									</div>
									<p className='text-base font-normal text-[#9198A1] group-hover:text-[#595E64]'>
										Мой профиль
									</p>
								</div>
								<button
									onClick={exitBTN}
									className='flex items-center cursor-pointer px-[5px] group'>
									<div className='mr-[10px]'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											className='fill-[#9198A1] group-hover:fill-[#595E64]'>
											<path
												fill-rule='evenodd'
												clip-rule='evenodd'
												d='M9.37905 4.23344L11.1322 3.95795C14.3527 3.45186 15.963 3.19881 17.0133 4.09693C18.0636 4.99505 18.0636 6.62511 18.0636 9.88521V11.3473H13.2507L15.3977 8.66353L13.836 7.41414L10.3892 11.7226L9.88948 12.3473L10.3892 12.9719L13.836 17.2804L15.3977 16.031L13.2507 13.3473H18.0636V14.8093C18.0636 18.0694 18.0636 19.6995 17.0133 20.5976C15.963 21.4957 14.3527 21.2427 11.1322 20.7366L9.37905 20.4611C7.76632 20.2076 6.95995 20.0809 6.47998 19.5196C6 18.9583 6 18.1421 6 16.5096V8.18495C6 6.55242 6 5.73616 6.47998 5.17487C6.95995 4.61358 7.76632 4.48687 9.37905 4.23344Z'
											/>
										</svg>
									</div>
									<p className='text-base font-normal text-[#9198A1] group-hover:text-[#595E64]'>
										Выход
									</p>
								</button>
							</div>
						)}
					</div>
				</div>
			</div>
		</header>
	);
}

export default Header;
