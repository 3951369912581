import {  useEffect } from 'react';
import { useUserStore } from '../store/user';
import { useNavigate } from 'react-router-dom';

const AuthController = ({ children }) => {
    const { token, checkLocalToken } = useUserStore()
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            checkLocalToken((result => {
                console.log(result)
                if (!result) navigate('/login');
                // else navigate('/adminpanel/attributes');
            }));
            // if (!result) navigate('/login');
        }
    }, [token])


    return (
        <>
            {token ? children : <></>}

        </>
    )
}

export default AuthController;