import { useEffect } from 'react';
import { useUserStore } from '../store/user';

export const useDecoratedCommonStore = (commonStore) => {
	const { data, loadData } = commonStore();
	const { token } = useUserStore();

	useEffect(() => {
		if (token) {
			if (!data) {
				loadData(token);
			} else if (!Array.isArray(data) && !data) {
				loadData(token);
			}
		}
	}, [token, data]);

	return commonStore();
};
