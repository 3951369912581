import { useEffect, useState } from 'react';

import ModalWindowWithForm from '../ModalWindowWithForm';

import passwordIcon from '../../images/passwordIcon.svg';
import phonIcon from '../../images/Phone_fill.svg';
import tgIcon from '../../images/tg.svg';
import closeBtn from '../../images/Close_round_duotone_line.svg';

import arrow from '../../images/arrow.svg';
import arrowBlue from '../../images/ArrowBlue.svg';
import Select from '../UI/Select';
import { useForm } from 'react-hook-form';
import { useDecoratedCommonStore } from '../../hooks/useDecoratedCommonStore';
import { useCommonUserRight } from '../../store/commonUserRight';
import { useCommonCompanyStore } from '../../store/commonCompany';
import { useCommonCityStore } from '../../store/commonCity';
import { useDecoratedPaginationStore } from '../../hooks/usePaginationStoreDecoration';
import { useUserStore } from '../../store/user';
import { useUsersStore } from '../../store/users';
import {
	createUser,
	updateUser,
	updateUserPassword,
} from '../../service/users';

export default function EditUserModal({
	title,
	name,
	modalWidth,
	isOpen,
	handleCloseAllModals,
	initialData,
}) {
	const { data: userRightData } = useDecoratedCommonStore(useCommonUserRight);
	const { data: companyData } = useDecoratedCommonStore(
		useCommonCompanyStore
	);
	const { data: cityData } = useDecoratedCommonStore(useCommonCityStore);
	const { updateDataList } = useUsersStore();
	const { token } = useUserStore();
	//checked
	const [checkedUserRights, setCheckedUserRights] = useState([]);

	const [activeCheckbox, setActiveCheckbox] = useState(false);
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: {
			search: '',
			city: undefined,
			company: undefined,
		},
	});

	useEffect(() => {
		setCheckedUserRights([]);
	}, []);

	useEffect(() => {
		if (initialData) {
			setValue('password', '');
			setValue('repeatPassword', '');
			setValue('last_name', initialData.last_name);
			setValue('first_name', initialData.first_name);
			setValue('middle_name', initialData.middle_name);
			setValue('phone', initialData.phone_number);
			setValue('telegram', initialData.telegram);
			setActiveCheckbox(initialData.Physical_LegalFace > 1);
		}
	}, [initialData]);

	useEffect(() => {
		if (initialData) {
			setValue(
				'company',
				companyData?.find((item) => item.id === initialData.company)
			);
			setValue(
				'city',
				cityData?.find((item) => item.id === initialData.city)
			);
			setCheckedUserRights(
				userRightData
					.filter((item) => initialData.groups.includes(item.id))
					.map((item) => item.id)
			);
		}
	}, [initialData, companyData, cityData, userRightData]);

	useEffect(() => {
		console.log(errors);
	}, [errors]);

	const { password, repeatPassword, search, city, company } = watch();

	const onChangeCheckbox = (e) => {
		setActiveCheckbox((state) => !state);
	};

	const onSetUserRight = (value) => () => {
		console.log(value, checkedUserRights);
		if (checkedUserRights.includes(value)) {
			setCheckedUserRights(
				checkedUserRights.filter((item) => item !== value)
			);
		} else {
			setCheckedUserRights([...checkedUserRights, value]);
		}
	};

	const onSubmit = (data) => {
		if (!token || !initialData) return;
		const {
			first_name,
			last_name,
			middle_name,
			email,
			phone,
			telegram,
			company,
			password,
			city,
		} = data;
		updateUser(
			token,
			initialData?.id,
			first_name,
			last_name,
			middle_name,
			email,
			password,
			phone,
			telegram,
			city?.id,
			company?.id,
			activeCheckbox ? 2 : 1,
			checkedUserRights,
			() => {
				updateDataList(token);
				handleCloseAllModals();
			}
		);
	};

	const editUserPassword = () => {
		if (repeatPassword === password && token && initialData) {
			updateUserPassword(token, initialData.id, password, () => {
				updateDataList(token);
				handleCloseAllModals();
			});
		}
	};

	return (
		<ModalWindowWithForm
			resetForm={() => {}}
			onSubmit={handleSubmit(onSubmit)}
			title={title}
			name={name}
			modalWidth={modalWidth}
			isOpen={isOpen}
			handleCloseAllModals={handleCloseAllModals}>
			<div>
				<h2 className='text-base font-semibold text-[#414141]'>
					Данные для входа
				</h2>

				<div className=' flex mb-[25px] mt-[25px]'>
					<div className='flex flex-col w-full mr-[10px]'>
						<label className='text-[#007AFF] text-base font-semibold flex'>
							<img
								src={passwordIcon}
								alt='icon password'
								className='mr-[10px]'
								width={16}
								height={18}
							/>
							Новый пароль
						</label>
						<input
							id='new-password-input'
							{...register('password')}
							className='text-[#909090] text-sm font-medium mt-[10px] bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full rounded-[10px] h-[33px] outline-none border-none'
							type='password'
							placeholder='•••••••••••'
						/>
					</div>

					<div className='flex flex-col w-full'>
						<label className='text-[#007AFF] text-base font-semibold flex'>
							<img
								src={passwordIcon}
								alt='icon password'
								className='mr-[10px]'
								width={16}
								height={18}
							/>
							Повтор пароля
						</label>
						<input
							id='repeat-new-password-input'
							{...register('repeatPassword', {
								validate: (value) => value === password,
							})}
							className='text-[#909090] text-sm font-medium mt-[10px] bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full rounded-[10px] h-[33px] outline-none border-none'
							type='password'
							placeholder='•••••••••••'
						/>
					</div>
				</div>
				<div className='flex justify-end mt-[35px] mb-[45px]'>
					<button
						type='button'
						onClick={editUserPassword}
						className='py-[10px] px-[32px] bg-[#007AFF] text-[#FFFFFF] rounded-[10px]'>
						Сохранить
					</button>
				</div>

				<h2 className='text-base font-semibold text-[#414141]'>
					Общие сведения
				</h2>

				<div className=' flex mb-[35px] mt-[25px]'>
					<div className='flex flex-col min-w-[185px] mr-[10px]'>
						<label className='text-[#007AFF] text-base font-semibold'>
							Фамилия
						</label>
						<input
							id='last-name-input'
							{...register('last_name', { required: true })}
							className='text-[#909090] text-sm font-medium mt-[10px] bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full min-w-[185px] rounded-[10px] h-[33px] outline-none border-none'
							placeholder='Фамилия'
						/>
					</div>

					<div className='flex flex-col min-w-[185px] mr-[10px]'>
						<label className='text-[#007AFF] text-base font-semibold'>
							Имя
						</label>
						<input
							id='first-name-input'
							{...register('first_name', { required: true })}
							className='text-[#909090] text-sm font-medium mt-[10px] bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full min-w-[185px] rounded-[10px] h-[33px] outline-none border-none'
							placeholder='Имя'
						/>
					</div>

					<div className='flex flex-col min-w-[185px]'>
						<label className='text-[#007AFF] text-base font-semibold'>
							Отчество
						</label>
						<input
							id='middle-name-input'
							{...register('middle_name', { required: true })}
							className='text-[#909090] text-sm font-medium mt-[10px] bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full min-w-[185px] rounded-[10px] h-[33px] outline-none border-none'
							placeholder='Отчество'
						/>
					</div>
				</div>

				<div className=' flex mb-[35px] mt-[25px]'>
					<div className='flex flex-col min-w-[185px] mr-[10px]'>
						<label className='text-[#007AFF] text-base font-semibold'>
							Город
						</label>

						<Select
							id='city-select'
							filter={(item, search) =>
								item.name
									?.toLowerCase()
									.includes(search.toLowerCase())
							}
							options={
								cityData?.length
									? cityData
									: [{ id: 1, name: 'ТЕСТ Город 1' }]
							}
							targetOptionst={city}
							setTargetOptions={(value) => {
								setValue('city', value);
							}}
							placeholder='Город'
						/>
					</div>

					<div className='flex flex-col min-w-[185px] mr-[10px]'>
						<label className='text-[#007AFF] text-base font-semibold'>
							Налоговый режим
						</label>
						<div className='flex items-center mt-[10px] h-[33px]'>
							<div
								className='flex mt-[10px] h-[33px] cursor-pointer'
								onClick={onChangeCheckbox}>
								{' '}
								{/*items-center  */}
								<label
									className={`cursor-pointer relative w-10 h-5  ${
										activeCheckbox
											? 'bg-[#007AFF]'
											: 'bg-[#B5B5B5]'
									}  rounded-2xl mr-[10px]`}>
									<div
										className={`absolute top-[3px]  ${
											activeCheckbox
												? 'left-6'
												: 'left-[3px]'
										} w-3.5 h-3.5 rounded-full bg-[#FFF] transition-all duration-150 ease-out`}></div>
									<input
										// {...register('taxRegime', { required: true })}
										id='tax-regime-input'
										type='checkbox'
										className='hidden'
										value={activeCheckbox}
										onChange={onChangeCheckbox}
									/>
								</label>
								<label className='cursor-pointer'>
									{activeCheckbox ? 'Физ лицо' : 'Юр лицо'}
								</label>
							</div>
						</div>
					</div>

					<div className='flex flex-col min-w-[185px]'>
						<label className='text-[#007AFF] text-base font-semibold'>
							Компания
						</label>
						<Select
							id='company-select'
							// filter={(item, search) =>
							// 	item.name
							// 		?.toLowerCase()
							// 		.includes(search.toLowerCase())
							// }
							options={companyData}
							targetOptionst={company}
							setTargetOptions={(value) => {
								setValue('company', value);
							}}
							placeholder='Компания'
						/>
					</div>
				</div>

				<div className=' flex mb-[35px] mt-[25px]'>
					<div className='flex flex-col min-w-[185px] mr-[10px]'>
						<label className='text-[#007AFF] text-base font-semibold flex'>
							<img
								src={phonIcon}
								alt='icon mail'
								className='mr-[10px]'
							/>
							Телефон
						</label>
						<input
							id='phone-input'
							{...register('phone', { required: true })}
							className='text-[#909090] text-sm font-medium mt-[10px] bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full min-w-[185px] rounded-[10px] h-[33px] outline-none border-none'
							placeholder='558612112'
						/>
					</div>

					<div className='flex flex-col min-w-[185px] mr-[10px]'>
						<label className='text-[#007AFF] text-base font-semibold flex'>
							<img
								src={tgIcon}
								alt='icon mail'
								className='mr-[10px]'
							/>
							Telegram
						</label>
						<input
							id='telegram-input'
							{...register('telegram', { required: true })}
							className='text-[#909090] text-sm font-medium mt-[10px] bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full min-w-[185px] rounded-[10px] h-[33px] outline-none border-none'
							placeholder='Telegram'
						/>
					</div>

					{/* <div className='flex flex-col min-w-[185px]'>
						<label className='text-[#007AFF] text-base font-semibold flex'>
							<img
								src={phonIcon}
								alt='icon mail'
								className='mr-[10px]'
							/>
							Телефон Комп
						</label>
						<input
							id='company-phone-input'
							className='text-[#909090] text-sm font-medium mt-[10px] bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full min-w-[185px] rounded-[10px] h-[33px] outline-none border-none'
							placeholder='558612112'
						/>
					</div> */}
				</div>

				<h2 className='text-base font-semibold text-[#414141]'>
					Документы
				</h2>

				<div className='flex mt-[15px] mb-[45px]'>
					<div className='mr-[10px] mb-[10px] max-w-[125px] max-h-[70px] border-[1px] border-dashed bordr-[#1d5deb33] rounded-[10px] py-[8px] pl-[15px] pr-[45px]'>
						<label className='text-[#404040] text-[13px] font-semibold'>
							Паспорт
						</label>
						<label className='cursor-pointer' htmlFor='imgFile'>
							<input
								type='file'
								accept='.jpg, .jpeg, .png'
								class=' hidden'
								id='img-file-input'
								name='imgFile'
							/>
							<p className='text-[#2886eb]  text-[13px] text-base font-normal'>
								Добавить
							</p>
						</label>
					</div>

					<div className='mr-[10px] mb-[10px] max-w-[140px] w-full max-h-[70px] border-[1px] border-dashed bordr-[#1d5deb33] rounded-[10px] py-[8px] pl-[15px] pr-[45px]'>
						<label className='text-[#404040] text-[13px] font-semibold'>
							Договор
						</label>
						<div className='flex'>
							<img
								src={closeBtn}
								alt='icon mail'
								className='mr-[10px]'
							/>
							<p className='text-[#2886eb]  text-[13px] text-base font-medium flex'>
								file.pdf
							</p>
						</div>
					</div>
				</div>

				<h2 className='text-base font-semibold text-[#414141]'>
					Права пользователя
				</h2>

				<div className='interaction__search mt-[25px]'>
					<button className='interaction__search-button zero-button'></button>
					<input
						id='role-search-input'
						className='interaction__search-line'
						placeholder='Название'
						{...register('search')}
					/>
					<button className='interaction__close-button zero-button'></button>
				</div>

				{userRightData?.map((item) => {
					const { name, id, codename } = item;
					if (
						!name
							?.toLocaleLowerCase()
							.includes(search?.toLocaleLowerCase())
					)
						return <></>;
					const isChecked = checkedUserRights.includes(id);
					return (
						<div className='mt-[15px]' key={id + codename}>
							<div className='flex justify-between bg-[#F8F8F8] py-[15px] pl-[10px] pr[10px] rounded-[15px]'>
								<div className='mr-[10px] text-base text-[#2886EB] font-semibold max-w-[125px] w-full'>
									{name}
								</div>
								<div className='mr-[55px] text-sm text-[#909090] font-normal'>
									Предоставляет доступ к странице логистики
									для отслеживания статуса доставки товара из
									вне от этапа "конвертация" до этапа "товар
									растаможен".
								</div>
								<label className='flex items-center h-[33px] cursor-pointer'>
									<div
										className={`cursor-pointer relative w-10 h-5  ${
											isChecked
												? 'bg-[#007AFF]'
												: 'bg-[#B5B5B5]'
										}  rounded-2xl mr-[10px]`}>
										<div
											className={`absolute top-[3px]  ${
												isChecked
													? 'left-6'
													: 'left-[3px]'
											} w-3.5 h-3.5 rounded-full bg-[#FFF] transition-all duration-150 ease-out`}></div>
										<input
											id={`${codename}:${id}-input`}
											type='checkbox'
											checked
											className='hidden'
											value={isChecked}
											onChange={onSetUserRight(id)}
										/>
									</div>
								</label>
							</div>
						</div>
					);
				})}

				<div className='flex justify-end mt-[35px]'>
					<button
						type='button'
						onClick={handleCloseAllModals}
						className='py-[10px] px-[24px] bg-[#13131408] text-[#909090] rounded-[10px] mr-[20px]'>
						Отмена
					</button>
					<button
						type='submit'
						className='py-[10px] px-[32px] bg-[#007AFF] text-[#FFFFFF] rounded-[10px]'>
						Сохранить все
					</button>
				</div>
			</div>
		</ModalWindowWithForm>
	);
}
