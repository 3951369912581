import { useState } from "react";
import Main from "../../components/Main";
import Table from "../../components/Table";
import { useSuppliersStore } from "../../store/suppliers";
import CreateStorekeeperModal from "../../components/modals/CreateStorekeeperModal";
import EditStorekeeperModal from "../../components/modals/EditStorekeeperModal";

export default function Storekeeper () {
  const { data, isLoading } = useSuppliersStore();
  const [isNothingFound, setIsNothingFound] = useState(false);

  const [isOpenCreateStorekeeper, setIsOpenCreateStorekeeper] = useState(false);
  const [isOpenEditStorekeeper, setIsOpenEditStorekeeper] = useState(false);
  const [selectedItemFromTable, setSelectedItemFromTable] = useState(false);
  
  const titles = [
    {
      title: "№",
      style: "",
    },
    {
      title: "Склады",
      style: "",
    },
  ];

  const closeModals = () => {
    setIsOpenCreateStorekeeper(false);
    setIsOpenEditStorekeeper(false);
  };

  return (
    <Main
      isInteractionPanel={true}
      paginationStore={useSuppliersStore}
      modals={
        <>
          <CreateStorekeeperModal
            title="Создание Склада"
            handleCloseAllModals={closeModals}
            isOpen={isOpenCreateStorekeeper}
            name=""
            modalWidth={{ width: "500px" }}
          />

          <EditStorekeeperModal
            title="Редактирование Склада"
            initialData={selectedItemFromTable}
            handleCloseAllModals={closeModals}
            isOpen={isOpenEditStorekeeper}
            name=""
            modalWidth={{ width: "500px" }}
          />
        </>
      }
      element={
        <Table
          handleCloseAllModals={() => {}}
          handleOpenTableModals={() => {}}
          isNothingFound={isNothingFound}
          isLoading={isLoading}
          tableData={{
            titles,
            content: [],
          }}
          depicter={(item, onClick, specialClass) => {
            const {
              id, name
            } = item;
            return (
              <tr
                onClick={() => {
                  setIsOpenEditStorekeeper(true);
                  setSelectedItemFromTable(item);
                }}
                className={`table__row ${specialClass}`}
              >
                <td className="table__content">{id}</td>
                <td className="table__content block !truncate">
                {name}
                </td>
              </tr>
            );
          }}
          specialClass="grid-cols-supplierColumns"
        />
      }
      createButton={true}
      isFilterButton={true}
      handleOpenCreateModals={() => {
        setIsOpenCreateStorekeeper(true);
      }}
      title="Склады"
      placeholderText="Наименование склада"
    />
  );
}
