import { ReactComponent as SortButton } from "../images/sort-button.svg";
import { ReactComponent as SortUp } from "../images/Sort_up_light.svg";
import { ReactComponent as SortDown } from "../images/Sort_down_light.svg";

function SortItem({ handleOnMouseEnter, handleOnMouseLeave, provided, caption, id, name, value, toggleOrderSorting }) {
  const toggler = (newValue) => {
    return () => {
      toggleOrderSorting(id, newValue);
    };
  };

  return (
    <form
      className="filter-popup__sort-wrapper"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
    >
      <SortButton
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        className="filter-popup__sort-button"
      />
      <span className="filter-popup__sort-name">{caption}</span>
      <div className="filter-popup__flex-wrapper">
        <div className="filter-popup__radio-button" onClick={toggler(false)}>
          <input
            type="radio"
            disabled={!value}
            checked={!value}
            id={`${id}-1`}
            name={name}
            className="filter-popup__radio"
          ></input>
          <label className={`filter-popup__radio-label ${!value ? " text-white" : " text-blue-500"} `} mlFor={`${id}-1`}>
            <SortUp />
          </label>
        </div>
        <div className="filter-popup__radio-button" onClick={toggler(true)}>
          <input
            type="radio"
            disabled={value}
            checked={value}
            id={`${id}-2`}
            name={name}
            className="filter-popup__radio"
          ></input>
          <label className={`filter-popup__radio-label ${value ? " text-white" : " text-blue-500"} `} htmlFor={`${id}-2`}>
            <SortDown />
          </label>
        </div>
      </div>
    </form>
  );
}

export default SortItem;
