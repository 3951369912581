import { create } from 'zustand';

export const useUserStore = create((set) => ({
	token: undefined,
	userData: null,
	rate: !false,
	setRate(value) {
		set(() => ({ rate: value }));
	},
	exit() {
		localStorage.removeItem('token');
		sessionStorage.removeItem('token');
		set(() => ({ token: undefined }));
	},
	checkLocalToken(props) {
		const token =
			localStorage.getItem('token') || sessionStorage.getItem('token');

		if (!token) {
			props?.(null);
			return null;
		}

		set(() => ({ token }));
		props?.(token);

		return token;
	},
	setToken(token, save) {
		set(() => ({ token }));
		if (save) {
			localStorage.setItem('token', token);
		} else {
			localStorage.removeItem('token');
			sessionStorage.setItem('token', token);
		}
	},
	setUserData(userData) {
		set(() => ({
			userData,
		}));
	},
}));
