import CustomSelect from "./CustomSelect";
import Search from "./Search";
import linkVendorCode from "../images/link.svg";

function InteractionPanel({
  placeholderText,
  isFilterButton,
  select,
  isCreateButton,
  onOpenFilterPopup,
  // isResetFiltersButton,
  // isResetSortsButton,
  onReset,
  handleOpenCreateModals,
  searchValue,
  setSearchValue,
  vendorCode,
}) {
  const handleClickFiltersButton = () => {
    onReset();
  };

  const handleClickAddButton = () => {
    handleOpenCreateModals();
  };

  return (
    <section className={vendorCode ? "interaction items-stretch" : "interaction"}>
      <Search searchValue={searchValue} setSearchValue={setSearchValue} placeholderText={placeholderText} />
      {isFilterButton && (
        <button className="interaction__filter-button zero-button" onClick={onOpenFilterPopup}>
          Фильтры & сортировка
        </button>
      )}
      {select && <CustomSelect size={174} />}
      {isCreateButton && (
        <button onClick={handleClickAddButton} className="ineraction__default-button zero-button">
          Добавить
        </button>
      )}
      {vendorCode && (
        <div className="flex justify-between min-w-[380px] items-end">
          <div className="flex items-center">
            <div className="max-w-[24px] max-h-[24px] w-full h-full">
              <img src={linkVendorCode} alt="icon vendor code" />
            </div>
            <p className="text-[#007AFF] text-base font-semibold leading-[17px]">Артикул</p>
          </div>
          <div>
            <p className="text-[#000000] text-base font-semibold leading-[17px]">Jfjs362302-wekf93</p>
          </div>
        </div>
      )}
      {/* {isResetFiltersButton && (
                <button
                    onClick={handleClickFiltersButton}
                    className='ineraction__default-button ineraction__default-button_type_filter zero-button'
                >
                    Сбросить фильтры
                </button>
            )}
            {isResetSortsButton && (
                <button className='ineraction__default-button ineraction__default-button_type_sort zero-button'>
                    Сбросить Сортировку
                </button>
            )} */}
    </section>
  );
}

export default InteractionPanel;
