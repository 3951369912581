import axios from "axios";
import { convertSortListForServer } from "../utils/convertSortListForServer";
import { URL } from "../utils/constants";
import { convertFiltersForServer } from "../utils/convertFiltersForServer";

export const getBrandList = async (token, perPage, page, search, sorts, filtersData, props) => {
  try {
    const sorters = convertSortListForServer(sorts);

    const filters = filtersData && convertFiltersForServer(filtersData);

    const { data } = await axios.post(
      `${URL}/adminpanel/brand/smart_list/`,
      {
        page,
        search,
        per_page: perPage,
        sorters,
        filters,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    console.log("data>>", data);
    props?.(data.results, data.count, data.max_pages);
    return { materialList: data.results, totalMaterials: data.count, maxPages: data.max_pages };
  } catch (error) {
    console.error("Error:", error);
    props?.(null, null, null);
    return null;
  }
};

export const getBrand = async (token, props) => {
  try {
    const { data } = await axios.get(`${URL}/adminpanel/brand/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    props?.(data);
  } catch (error) {
    console.error("Error:", error);
    props?.(null);
  }
};

export const createBrand = async (token, name, props) => {
  try {
    const { data } = await axios.post(
      `${URL}/adminpanel/brand/`,
      { name },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    props?.(data);
  } catch (error) {
    console.error("Error:", error);
    props?.(null);
  }
};

export const updateBrand = async (token, id, name, props) => {
  try {
    const { data } = await axios.patch(
      `${URL}/adminpanel/brand/${id}/`,
      { name },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    props?.(data);
  } catch (error) {
    console.error("Error:", error);
    props?.(null);
  }
};
