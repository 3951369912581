import React, { useEffect } from "react";
import ModalWindowWithForm from "../ModalWindowWithForm";
import { useForm } from "react-hook-form";
import { updateBrand } from "../../service/brand";
import { useUserStore } from "../../store/user";
import { useBrandStore } from "../../store/brand";

const ViewLogistInfo = ({ title, name, modalWidth, isOpen, handleCloseAllModals, initialData }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { shipping, services, products, documents_loaded, documents } = initialData;
  console.log("initialData>>??", initialData);
  return (
    <ModalWindowWithForm
      resetForm={reset}
      onSubmit={() => {}}
      title={title}
      name={name}
      modalWidth={modalWidth}
      isOpen={isOpen}
      handleCloseAllModals={handleCloseAllModals}
    >
      <div className="modal__info-order mb-[35px]">
        <div className="flex flex-col max-w-[185px] w-full mr-[35px]">
          <label className="modal__headline-overall mb-[12px] mx-0">Инвойс</label>
          <p className="text-[#909090] text-[14px] font-medium">№{shipping?.invoice_str}</p>
        </div>
        <div className="flex flex-col max-w-[185px] w-full">
          <label className="modal__headline-overall mb-[12px] mx-0">Дата доставки</label>
          <span className="text-[#909090] text-[14px] font-medium">
            {shipping?.pay_date?.split("-")[2]}.{shipping?.pay_date?.split("-")[1]}.{shipping?.pay_date?.split("-")[0]}
          </span>
        </div>
      </div>

      <div className="modal__supplier-selection">
        <label className="modal__headline-overall mb-[12px] mx-0">Поставщик</label>
        <div className="text-[#909090] text-[14px] font-medium">{shipping?.vendor?.name}</div>
      </div>

      <div className="modal__list-product">
        <h2 className="modal__headline-overall mb-[10px] mx-0">Товары</h2>

        <div className="flex flex-col mt-[10px]">
          {initialData?.shipping?.unique_products &&
            initialData?.shipping?.unique_products.map((item) => {
              const { name, price, count, sum_price } = item;
              return (
                <div className="flex justify-between items-center mb-[10px]">
                  <div className="text-[#6F6F6F] text-[14px] font-medium mr-[20px]">{name}</div>
                  <div className="flex items-center">
                    <div className="text-[#ADADAD] text-[14px] font-medium mr-[20px]">{count} шт</div>
                    <div className="text-[#ADADAD] text-[14px] font-medium mr-[20px]">{price}$</div>
                    <div className="text-[#6F6F6F] text-[14px] font-medium ">{sum_price}$</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="modal__services-box">
        <h2 className="modal__headline-overall mb-[10px] mx-0 font-semibold text-[16px] text-[#2886EB]">Услуги</h2>
        {initialData?.services &&
          initialData?.services.map((item) => {
            const { name, price } = item;
            return (
              <div className="flex mb-[12px] justify-between">
                <label className="modal__service-info font-medium text-[14px] text-[#6F6F6F]">{name}</label>
                <p className="text-[#909090] font-medium text-[14px]">{price} $</p>
              </div>
            );
          })}
      </div>

      <div className="modal__last-price my-[35px]">Итого: {initialData?.shipping?.global_sum} $</div>

      <div className="modal__document-box">
        <h2 className="modal__headline-overall">Документы</h2>
        <div className="box-added-documents">
          {initialData?.documents &&
            initialData?.documents.map((item) => {
              console.log("??>>", item);
              return (
                <div class="document-item max-w-[250px]" key={item.id}>
                  <label className="document-list-header">{item.file_name.length > 32 ? item.file_name.slice(0,32)+"..." : item.file_name}</label>
                  <div className="document-status gap-2">
                    <a
                      href={item.file}
                      rel="noreferrer"
                      target="_blank"
                      className="text-[#007AFF] text-[13px] font-bold overflow-hidden text-ellipsis"
                    >
                      {item?.file.length > 35 ? item?.file.slice(0,35) : item?.file}
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="attributes-modal__flex-wrapper justify-start">
        <button
          type="button"
          className="cansel-button zero-button bg-[#13131408]"
          onClick={() => handleCloseAllModals((state) => (state = !isOpen))}
        >
          Отмена
        </button>
      </div>
    </ModalWindowWithForm>
  );
};

export default ViewLogistInfo;
