import { createPaginationStore } from "../common/createPaginationStore";
import { getLogistShipping } from "../service/logistShipping";


export const useLogistShippingStore = createPaginationStore(
    (options, props) => {
        const { token, pageSize, targetPage, filters } = options
        getLogistShipping(
            token,
            1,
            targetPage + 10,
            filters,
            (LogistShippings, totalAttributes) => {

                if (LogistShippings && typeof totalAttributes === "number") {
                    const maxPages = totalAttributes / pageSize;

                    props(LogistShippings, totalAttributes, maxPages)
                }
            })
    },
    12,
    [],
    {}
)
