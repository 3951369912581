import ModalWindowWithForm from "../ModalWindowWithForm";

import arrow from "../../images/arrow.svg";
import search from "../../images/Search.svg";
import sortBlue from "../../images/Sort_random_blue.svg";
import closeBtn from "../../images/Close_round_duotone_line.svg";
export default function InventoryControlModalPosting({
  title,
  name,
  modalWidth,
  isOpen,
  handleCloseAllModals,
  initialData,
}) {
  return (
    <ModalWindowWithForm
      resetForm={() => {}}
      onSubmit={() => {}}
      title={title}
      name={name}
      modalWidth={modalWidth}
      isOpen={isOpen}
      handleCloseAllModals={handleCloseAllModals}
    >
      <div>
        <div className="flex mb-[35px] mt-[40px]">
          <div className="flex flex-col min-w-[185px]">
            <label className="text-[#007AFF] text-base font-semibold">Инвойс</label>
            <p className="text-[#909090] text-sm font-medium mt-[10px]">558612112</p>
          </div>

          <div className="flex flex-col min-w-[185px]">
            <label className="text-[#007AFF] text-base font-semibold">Время</label>
            <p className="text-[#909090] text-sm font-medium mt-[10px]">02.01.2023 07:52</p>
          </div>
        </div>

        <div className="flex flex-col">
          <label className="text-[#007AFF] text-base font-semibold ml-[10px] mb-[10px]">Комментарий</label>
          <textarea className="pt-[15px] pr-[45px] pb-[35px] pl-[15px] bg-[#13131408] resize-none outline-none text-[#787878]"></textarea>
          <div className="flex justify-end">
            <button onClick={handleCloseAllModals}  className="px-[32px] py-[10px] text-sm font-medium bg-[#007AFF] text-white rounded-[10px] my-[35px]">
              Сохранить
            </button>
          </div>
        </div>

        <div className="flex flex-col">
          <label className="text-[#007AFF] text-base font-semibold mb-[25px]">Товары</label>
          <div className="flex items-center justify-between mb-[25px]">
            <div className="flex items-center bg-[#13131408] max-w-[155px] rounded-lg">
              <label htmlFor="input-name-product" className="pl-[10px]">
                <img src={search} alt="search" className="w-[20px] h-[20px]" />
              </label>
              <input
                id="input-name-product"
                placeholder="Название модели"
                className="bg-transparent outline-none border-none w-full pl-[10px] pr-[5px] py-[8px] text-sm font-medium text-[#B5B5B5] rounded-lg"
              />
            </div>

            <div className="flex items-center bg-[#13131408] max-w-[155px]  rounded-lg">
              <input
                id="input-number"
                placeholder="Серийный номер"
                className="bg-transparent outline-none border-none w-full pl-[8px] pr-[5px] py-[8px] text-sm font-medium text-[#B5B5B5] rounded-lg"
              />
              <label htmlFor="input-number" className="pl-[5px] py-[8px] pr-[8px]">
                <img src={sortBlue} alt="search" className="w-[24px] h-[24px]" />
              </label>
            </div>
            <button onClick={handleCloseAllModals}  className="px-[15px] py-[10px] text-sm font-medium bg-[#007AFF] text-white rounded-[10px]">
              Добавить
            </button>
          </div>
          <div className="">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img src={arrow} alt="arrow btn" className="mr-[10px]" />
                <p className="text-[#606060] text-sm">Mikrotik 89983SFdsa </p>
              </div>
              <div className="flex">
                <p className="mr-[10px]">
                  Отсканировано: <span className="text-[#007AFF] text-base font-semibold">5 шт</span>
                </p>
                <p>
                  Всего: <span className="text-[#FF3E3E] text-base font-semibold">7 шт</span>
                </p>
              </div>
            </div>
            <div className="border-l-[1px] border-[#606060] ml-[45px] mt-[10px] after:content-[''] after:bg-white after:w-2 after:h-4 after:absolute after:translate-y-[-11.3px] after:translate-x-[-4px]">
              <p className="flex items-center text-[#606060]">
                <span className="w-[10px] inline-block bg-[#606060] h-[1px] mr-[8px]"></span>
                <button className="mr-[5px]">
                  <img src={closeBtn} alt="delete" />
                </button>
                fgdfg558612112
              </p>
              <p className="flex items-center text-[#606060]">
                <span className="w-[10px] inline-block bg-[#606060] h-[1px] mr-[8px]"></span>
                <button className="mr-[5px]">
                  <img src={closeBtn} alt="delete" />
                </button>
                aedrfg653518670
              </p>
              <p className="flex items-center text-[#606060]">
                <span className="w-[10px] inline-block bg-[#606060] h-[1px] mr-[8px]"></span>
                <button className="mr-[5px]">
                  <img src={closeBtn} alt="delete" />
                </button>
                sdfa267400456
              </p>
              <p className="flex items-center text-[#606060]">
                <span className="w-[10px] inline-block bg-[#606060] h-[1px] mr-[8px]"></span>{" "}
                <button className="mr-[5px]">
                  <img src={closeBtn} alt="delete" />
                </button>
                gfghnfgdsfa4356
              </p>
              <p className="flex items-center text-[#606060]">
                <span className="w-[10px] inline-block bg-[#606060] h-[1px] mr-[8px]"></span>{" "}
                <button className="mr-[5px]">
                  <img src={closeBtn} alt="delete" />
                </button>
                sdfsdsd2674004dfvss56
              </p>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img src={arrow} className="mr-[10px] rotate-[-90deg] " />
              <p className="text-[#606060] text-sm">Ьщгшрвамыу98о </p>
            </div>
            <div className="flex items-center">
              <p className="mr-[10px]">
                Отсканировано: <span className="text-[#007AFF] text-base font-semibold">5 шт</span>
              </p>
              <p>
                Всего: <span className="text-[#FF3E3E] text-base font-semibold">12 шт</span>
              </p>
            </div>
          </div>
        </div>
       
        <div className="flex justify-end mt-[20px]">
          <button onClick={handleCloseAllModals} className="px-[15px] py-[10px] text-sm font-medium bg-[#007AFF] text-white rounded-[10px]">Оприходовано</button>
        </div>
      </div>
    </ModalWindowWithForm>
  );
}
