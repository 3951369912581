import { createCommonStore } from '../common/createCommonStore';
import { getCity } from '../service/city';

export const useCommonCityStore = createCommonStore((token, props) => {
	getCity(token, (result) => {
		if (result) {
			props(result);
		}
	});
});
