import { createPaginationStore } from '../common/createPaginationStore';
import { getProductList } from '../service/product';

export const useProductStore = createPaginationStore(
	(options, props) => {
		const { token, pageSize, targetPage, search, sorts, filters } = options;
		getProductList(
			token,
			pageSize,
			targetPage,
			search,
			sorts,
			filters,
			(attributeCategories, totalAttributeCategories, maxPages) => {
				if (
					attributeCategories &&
					typeof totalAttributeCategories === 'number' &&
					maxPages
				) {
					props(
						attributeCategories,
						totalAttributeCategories,
						maxPages
					);
				}
			}
		);
	},
	10,
	[
		// { id: '111', title: '№', value: false, target: 'id' },
		// { id: '333', title: 'Категория', value: false, target: 'name' },
		// { id: '222', title: 'Род категория', value: false, target: 'parent' },
	],
	{}
);
