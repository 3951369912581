
// conversion
// on-road
// on-customs
// customs-cleared

export const getContentForShippingByType = (type) => {
    if (type === 'conversion') {
        return {
            next: {
                content: 'в пути',
                style: 'logist__column-name_type_on-road'
            },
            current: {
                content: 'конвертация',
                style: 'logist__column-name_type_conversion'
            },
            previous: {
                content: 'в пути',
                style: 'logist__column-name_type_on-road'
            },

        }
    }
    if (type === 'on-road') {
        return {
            next: {
                content: 'на таможне',
                style: 'logist__column-name_type_on-customs'
            },
            current: {
                content: 'в пути',
                style: 'logist__column-name_type_on-road'
            },
            previous: {
                content: 'конвертация',
                style: 'logist__column-name_type_conversion'
            },

        }
    }
    if (type === 'on-customs') {
        return {
            next: {
                content: 'товар растаможен',
                style: 'logist__column-name_type_customs-cleared'
            },
            current: {
                content: 'на таможне',
                style: 'logist__column-name_type_on-customs'
            },
            previous: {
                content: 'в пути',
                style: 'logist__column-name_type_on-road'
            },

        }
    }
    if (type === 'customs-cleared') {
        return {
            next: {
                content: 'на таможне',
                style: 'logist__column-name_type_on-customs'
            },
            current: {
                content: 'товар растаможен',
                style: 'logist__column-name_type_customs-cleared'
            },
            previous: {
                content: 'на таможне',
                style: 'logist__column-name_type_on-customs'
            },

        }
    }

}