import React, { useState, useEffect } from 'react';
import ModalWindowWithForm from '../ModalWindowWithForm';
import closeBtn from '../../images/Close_round_duotone_line.svg';
import { useUserStore } from '../../store/user';
import {
	attachDocumentsToLogistShipping,
	getLogistShippingDetail,
} from '../../service/logistShipping';
import Select from '../UI/Select';
import { useCommonDocumentType } from '../../store/commonDocumentType';
import { useDecoratedCommonStore } from '../../hooks/useDecoratedCommonStore';
import LogistFileCard from '../LogistFileCard';

const EditLogistModal = ({ closeModals, isOpen, target }) => {
	const { token } = useUserStore();
	const { data: documentTypes } = useDecoratedCommonStore(
		useCommonDocumentType
	);
	const [selectedDocumentType, setSelectedDocumentType] = useState();
	const [documentCards, setDocumentCards] = useState([]);
	const [data, setData] = useState();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (target && token && !isLoading && isOpen) {
			setIsLoading(true);
			console.log(target);
			getLogistShippingDetail(token, target.id, (result) => {
				if (result) {
					setData(result);
				}
				setIsLoading(false);
			});
		}
	}, [target, token]);

	const addFileInCard = (id, file) => {
		setDocumentCards(
			documentCards.map((item) => {
				if (item.id !== id) return item;
				return {
					...item,
					file,
				};
			})
		);
	};

	const addFileCard = () => {
		//    {
		//     "id": 1,
		//     "name": "Документ Уникальный",
		//     "is_required": false,
		//     "required_stage": 2,
		//     "show_always": true,
		//     "unique": true
		//   },

		const isAlreadyExists =
			documentCards.findIndex(
				(item) => item.id === selectedDocumentType.id
			) >= 0;

		if (!selectedDocumentType || isAlreadyExists) return;

		const newCard = {
			...selectedDocumentType,
			file: undefined,
		};
		setDocumentCards([...documentCards, newCard]);
		setSelectedDocumentType(undefined);
	};

	const deleteFileCard = (targetId) => {
		setDocumentCards(documentCards.filter((item) => item.id !== targetId));
		setSelectedDocumentType(undefined);
	};

	const saveFiles = () => {
		if (target && token && documentCards.length) {
			attachDocumentsToLogistShipping(
				token,
				target.id,
				documentCards,
				(result) => {
					if (result) {
						closeModals();
					}
					// getLogistShippingDetail(token, target.id, (result) => {
					// 	if (result) {
					// 		setData(result);
					// 		setDocumentCards([]);
					// 	}
					// });
				}
			);
		}
	};

	return (
		<ModalWindowWithForm
			resetForm={() => {}}
			onSubmit={() => {}}
			title='Редактирование заказа'
			name='create-logist-modal'
			modalWidth={{ width: '612px' }}
			isOpen={isOpen}
			handleCloseAllModals={closeModals}>
			<div className=''>
				<div className='modal__info-order mb-[35px]'>
					<div className='flex flex-col max-w-[185px] w-full mr-[35px]'>
						<label className='modal__headline-overall'>
							Инвойс
						</label>
						<p className='modal__input-overall'>
							№{target?.invoice_str}
						</p>
					</div>
					<div className='flex flex-col max-w-[185px] w-full'>
						<label className='modal__headline-overall'>
							Дата доставки
						</label>
						<span className='modal__input-overall'>
							{target?.pay_date}
						</span>
					</div>
				</div>

				<div className='modal__supplier-selection'>
					<label className='modal__headline-overall'>Поставщик</label>
					<div className='select-providers text-[#909090] pl-2.5'>
						{target?.vendor_name}
					</div>
				</div>

				<div className='modal__list-product'>
					<h2 className='modal__headline-overall'>Товары</h2>

					<div className='flex flex-col mt-[10px]'>
						{data?.unique_products.map((item) => {
							const { name, price, count, sum_price, id } = item;
							return (
								<div className='flex justify-between items-center mb-[10px] pl-2.5'>
									<div className='text-[#6F6F6F] text-base font-bold mr-[20px]'>
										{name} {id}
									</div>
									<div className='flex items-center'>
										<div className='text-[#ADADAD] text-base font-bold mr-[20px]'>
											{count} шт
										</div>
										<div className='text-[#ADADAD] text-base font-bold mr-[20px]'>
											{price}$
										</div>
										<div className='text-[#6F6F6F] text-base font-bold '>
											{sum_price}$
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className='modal__services-box'>
					<h2 className='modal__headline-overall'>Услуги</h2>
					{data?.services.map((item) => {
						const { name, price } = item;
						return (
							<div className='flex mb-[12px] justify-between pl-2.5'>
								<label className='modal__service-info'>
									{name}
								</label>
								<p className='text-[#909090]'>{price} $</p>
							</div>
						);
					})}
				</div>

				<div className='modal__last-price mb-[35px]'>
					Итого: {data?.shipping.global_sum} $
				</div>

				<div className='modal__document-box'>
					<h2 className='modal__headline-overall'>Документы</h2>

					<div className='box-document'>
						<div className='box-document-add gap-2'>
							{/* <div
								className='box-document-button'
								onClick={() =>
									setDocumentListOpen((state) => !state)
								}>
								<div className='document-btn'>
									Выбрать документ
								</div>

								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='19'
									height='19'
									viewBox='0 0 19 19'
									fill='none'>
									<path
										d='M8.8636 13.1364C9.21508 13.4879 9.78492 13.4879 10.1364 13.1364L15.864 7.40883C16.2154 7.05736 16.2154 6.48751 15.864 6.13604C15.5125 5.78457 14.9426 5.78457 14.5912 6.13604L9.5 11.2272L4.40883 6.13604C4.05736 5.78457 3.48751 5.78457 3.13604 6.13604C2.78457 6.48751 2.78457 7.05736 3.13604 7.40883L8.8636 13.1364ZM8.6 11.5L8.6 12.5L10.4 12.5L10.4 11.5L8.6 11.5Z'
										fill='#B5B5B5'
									/>
								</svg>
							</div>
							{isDocumentListOpen && (
								<div className='document-options max-h-[140px]'>
									<ul className='select-document-list'>
										<li className='select-item-document'>
											Инвойс
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='14'
												height='14'
												viewBox='0 0 14 14'
												fill='none'>
												<path
													d='M7.16667 0.5C3.48667 0.5 0.5 3.48667 0.5 7.16667C0.5 10.8467 3.48667 13.8333 7.16667 13.8333C10.8467 13.8333 13.8333 10.8467 13.8333 7.16667C13.8333 3.48667 10.8467 0.5 7.16667 0.5ZM7.83333 10.5H6.5V9.16667H7.83333V10.5ZM7.83333 7.83333H6.5V3.83333H7.83333V7.83333Z'
													fill='#FF3E3E'
												/>
											</svg>
										</li>
										<li className='select-item-document'>
											Экспортная декларация
										</li>
										<li className='select-item-document'>
											Сертификат происхождения
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='14'
												height='14'
												viewBox='0 0 14 14'
												fill='none'>
												<path
													d='M7.16667 0.5C3.48667 0.5 0.5 3.48667 0.5 7.16667C0.5 10.8467 3.48667 13.8333 7.16667 13.8333C10.8467 13.8333 13.8333 10.8467 13.8333 7.16667C13.8333 3.48667 10.8467 0.5 7.16667 0.5ZM7.83333 10.5H6.5V9.16667H7.83333V10.5ZM7.83333 7.83333H6.5V3.83333H7.83333V7.83333Z'
													fill='#FF3E3E'
												/>
											</svg>
										</li>
										<li className='select-item-document'>
											Таможенная пошлина
										</li>
									</ul>
								</div>
							)} */}
							<Select
								// id='attribute-category-select'
								withSearch={false}
								filter={(option) => {
									const isAlreadyExists =
										documentCards.findIndex(
											(card) => card.id === option.id
										) >= 0 ||
										data?.documents.findIndex(
											(file) => file.type === option.id
										) >= 0;
									if (option.unique && isAlreadyExists) {
										return false;
									}
									return true;
								}}
								customOption={(
									option,
									toggleIsOpen,
									targetOptionst,
									setTargetOptions
								) => {
									return (
										<li
											className='select-item-document'
											key={option.id}
											onClick={() => {
												toggleIsOpen();
												if (
													targetOptionst?.id !==
													option?.id
												) {
													setTargetOptions(option);
												}
											}}>
											{option.name}
											{option.required_stage ===
												target.typeId &&
												option.is_required && (
													<div className=' inline-block px-1'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='14'
															height='14'
															viewBox='0 0 14 14'
															fill='none'>
															<path
																d='M7.16667 0.5C3.48667 0.5 0.5 3.48667 0.5 7.16667C0.5 10.8467 3.48667 13.8333 7.16667 13.8333C10.8467 13.8333 13.8333 10.8467 13.8333 7.16667C13.8333 3.48667 10.8467 0.5 7.16667 0.5ZM7.83333 10.5H6.5V9.16667H7.83333V10.5ZM7.83333 7.83333H6.5V3.83333H7.83333V7.83333Z'
																fill='#FF3E3E'
															/>
														</svg>
													</div>
												)}
										</li>
									);
								}}
								options={documentTypes}
								targetOptionst={selectedDocumentType}
								setTargetOptions={setSelectedDocumentType}
								placeholder='Выбрать документ'
							/>
							<button
								type='button'
								onClick={addFileCard}
								className='cursor-pointer bg-[#4E89FD] text-[#FFF] py-[6px] px-[30px] rounded-[5px]'>
								Добавить
							</button>
						</div>
					</div>

					<div className='box-added-documents'>
						{data?.documents.map((item) => {
							const {
								file_name,
								file_extension,
								file,
								id,
								type,
							} = item;
							return (
								<LogistFileCard
									key={id}
									attached
									name={`${file_name}.${file_extension}`}
									url={file}
									title={
										documentTypes?.find(
											(item) => item.id === type
										)?.name || 'Неизвестный файл'
									}
								/>
							);
						})}
						{documentCards.map((item) => {
							const { name, id, file } = item;
							return (
								<LogistFileCard
									key={id + name}
									name={file?.name || 'Добавить'}
									title={name}
									deleteCard={() => deleteFileCard(id)}
									addFile={(file) => addFileInCard(id, file)}
								/>
							);
						})}
					</div>
				</div>

				<div className='box-btn'>
					<button
						type='button'
						className='cancel-modal-btn bg-[#13131408]'
						onClick={closeModals}>
						Отмена
					</button>
					<button
						type='button'
						onClick={saveFiles}
						className='bg-[#007AFF] text-[#FFF] py-[6px] px-[32px] rounded-[5px] ml-[10px]'>
						Сохранить
					</button>
				</div>
			</div>
		</ModalWindowWithForm>
	);
};

export default EditLogistModal;
