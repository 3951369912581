import React from "react";
import ModalWindowWithForm from "../ModalWindowWithForm";
import leftMove from "../../images/left-move.svg";
import { useUserStore } from "../../store/user";
import { changeStageToPrevLogistShipping } from "../../service/logistShipping";
import { useLogistShippingStore } from "../../store/logistShipping";
import { getContentForShippingByType } from "../../utils/getContentForShippingByType";

const RequestMoveLogisticsOrder = ({ isOpen, closeModals,
    target: {
        id,
        invoice_str,
        pay_date,
        vendor_name,
        count,
        sum_price,
        type
    } }) => {
    const { token } = useUserStore()
    const { updateDataList } = useLogistShippingStore()
    const data = getContentForShippingByType(type)

    const moveShipping = () => {
        if (token) {
            changeStageToPrevLogistShipping(token, id, () => {
                updateDataList(token)
                closeModals()
            })
            
        }
    }
    return (
        <ModalWindowWithForm
            resetForm={() => { }}
            onSubmit={() => { }}
            title="Перемещение заказа"
            name="request-move-logistics-order-modal"
            modalWidth={{ width: "550px" }}
            isOpen={isOpen}
            handleCloseAllModals={closeModals}
        >
            <h2 className="my-[25px] max-w-[400px] text-[#474747] text-[15px] font-bold leading-[21px]">
                Вы действительно хотите отправить запрос на изменение статуса
                <span className="text-[#4E89FD] text-[15px] font-bold leading-[21px]"> Инвойса №{invoice_str} </span> на
                <span className="text-[#4E89FD] text-[15px] font-bold leading-[21px]"> {data.previous.content}</span> ?
            </h2>
            <div className="my-[25px]">
                <div className="flex justify-between my-[10px]">
                    <p className="text-[#929292] text-[13px] font-bold">Инвойс № {invoice_str}</p>
                    <p className="text-[#6081F6] text-[13px] font-bold">{pay_date}</p>
                </div>
                <div className="flex justify-between my-[10px]">
                    <p className="text-[#929292] text-[13px] font-bold">Поставщик:</p>
                    <p className="text-[#6081F6] text-[13px] font-bold">{vendor_name}</p>
                </div>
                <div className="flex justify-between my-[10px]">
                    <p className="text-[#929292] text-[13px] font-bold">Товаров:</p>
                    <p className="text-[#6081F6] text-[13px] font-bold">{count} шт.</p>
                </div>
                <p className="text-[#A4B4DE] text-[13px] font-bold">
                    <span className="text-[#4E6DC0] text-[13px] font-bold">итого: {sum_price}P</span> UZS
                </p>
            </div>

            <div className="my-[25px]">
                <div className="flex items-center justify-center">
                    <div
                        className={` ${data.previous.style} text-[#FFF] py-[9px] px-[32px] rounded-[5px] mr-[5px] cursor-not-allowed`}
                    >
                        {data.previous.content}
                    </div>
                    <div>
                        <img src={leftMove} alt="change order status" />
                    </div>
                    <div
                        className={` ${data.current.style} text-[#FFF] py-[9px] px-[32px] rounded-[5px] ml-[5px]`}>
                        {data.current.content}
                    </div>
                </div>
            </div>

            <div className="flex justify-end">
                <button onClick={closeModals} type="button" className="bg-[#FF5454] text-[#FFF] py-[8px] px-[25px] rounded-[5px] mr-[10px]">
                    Отмена
                </button>
                <button type="button" onClick={moveShipping} className="bg-[#4E89FD] text-[#FFF] py-[8px] px-[40px] rounded-[5px]">
                    Да
                </button>
            </div>
        </ModalWindowWithForm>
    );
};

export default RequestMoveLogisticsOrder;
