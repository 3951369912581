import image1 from "../images/image 11.png";
import image2 from "../images/image 10.png";
import image3 from "../images/image 12.png";
import image4 from "../images/image 13.png";
import image6 from "../images/image 16.png";
import boxes from "../images/Boxes_light.svg";
import dots from "../images/dots.svg";

import arrow from "../images/arrow.svg";
import arrowBlue from "../images/ArrowBlue.svg";

import React, { useState } from "react";
import Main from "../components/Main";
import { useNavigate } from "react-router-dom";
import CreatePhysicalCart from "../components/modals/CreatePhysicalCart";

const Product = () => {
  const [isOpenCreatePhysicalCart, setIsOpenCreatePhysicalCart] = useState(false);

  const [activeInputStorageField, setActiveInputStorageField] = useState(false);
  const [activeInputCartField, setActiveInputCartField] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [images, setImages] = useState([
    { id: 0, image: image1 },
    { id: 1, image: image2 },
    { id: 2, image: image6 },
    { id: 3, image: image3 },
    { id: 4, image: image4 },
  ]);
  const [mainImage, setMainImage] = useState({ id: 0, image: image1 });

  const switchingImages = (id) => {
    const getImageClick = images.filter((itemId) => itemId.id === id);
    // setMainImage(...getImageClick);
    setMainImage(...images.filter((itemId) => itemId.id === id));
  };

  const navigate = useNavigate();

  const rederectUpdateProduct = () => {
    navigate("/adminpanel/update_prodact/");
  };

  const closeModals = () => {
    setIsOpenCreatePhysicalCart(false);
  };

  return (
    <Main
      isInteractionPanel={true}
      isPagination={false}
      vendorCode={true}
      modals={
          <CreatePhysicalCart
            title="Создание физической корзины"
            handleCloseAllModals={closeModals}
            isOpen={isOpenCreatePhysicalCart}
            name="create-physical-cart-modal"
            modalWidth={{ width: "500px" }}
          />
      }
      element={
        <>
          <div className="flex justify-between">
            <div>
              <div className="flex justify-between items-center">
                <h2 className="text-[22px] font-normal text-[#000]">MikroTik SXTsq 5 ac</h2>
                <div className="relative max-w-[200px] w-full flex justify-end">
                  <button className="py-[10px] px-[4px]" onClick={() => setIsPopupOpen(state => !state)}><img src={dots} alt="dots"/></button>
                  {
                    isPopupOpen &&
                    <div className="absolute top-[23px] left-[5px] bg-[#FFF] max-w-[200px] w-full border-[1px] border-[#D9D9D9] rounded-lg ">
                        <button className="text-[#666666]  border-b-[1px] border-[#D9D9D9] w-full text-left py-[5px] pl-[10px] text-[16px] font-normal">Скрыть товар</button>
                        <button className=" text-[#666666] w-full text-left py-[5px] pl-[10px] text-[16px] font-normal" onClick={rederectUpdateProduct}>Редактировать товар</button>
                    </div>

                  }
                </div>
                
              </div>
              <div className="mt-[20px] flex max-h-[525px] h-full">
                <div className="mr-[20px] w-full max-w-[150px] flex flex-col gap-y-[10px] py-[55px] border-[1px] border-[#2886EB1A] rounded-[15px]">
                  {images.map((item) => {
                    return (
                      <div
                        className="flex justify-center items-center cursor-pointer hover:ease-in-out hover:transition-all hover:bg-[#f6f6f6] "
                        onClick={() => switchingImages(item.id)}
                      >
                        <img key={item.id} src={item.image} alt="" className=" w-[100px] min-h-[80px] object-cover" />
                      </div>
                    );
                  })}
                </div>
                <div className="border-[1px] border-[#2886EB1A] rounded-[15px] flex justify-center items-center w-[960px] max-h-[525px] h-full p-[125px]">
                  <div className="">
                    <img src={mainImage.image} alt="" className="max-w-[710px] w-full max-h-[525px] h-full" />
                  </div>
                </div>
              </div>
              <div className=" max-w-[1130px]">
                <div className="p-[30px] border-[1px] border-[#EBEBEC] rounded-[15px] my-[45px]">
                  <h2 className="text-[24px] font-medium text-[#414141] leading-normal mb-[25px]">Описание</h2>
                  <p className="text-[14px] font-normal text-[#414141] leading-[19px] mb-[20px]">
                    hAP ax3 — полностью готовое устройство MikroTik, которое может функционировать в качестве маршрутизатора
                    или точки доступа, работающее одновременно в двух частотных диапазонах 2400–2483,5 МГц и 5150–5875 МГц, и
                    поддерживающее стандарты беспроводной связи 802.11a/b/g/n/ac/ax. Устройство оснащено 4-мя
                    Ethernet-портами 1 Гбит, а так же 1 Ethernet-портом 2.5 Гбит. Форм-фактор устройства предполагает как
                    настольное размещение (в горизонтальном или вертикальном положении), так и на стене.
                  </p>
                  <p className="text-[14px] font-normal text-[#414141] leading-[19px] mb-[20px]">
                    Устройство работает под управлением операционной системы RouterOS Level 6, обеспечивающей распределение
                    полосы пропускания (QoS), межсетевой экран, контроль доступа пользователей и многие другие возможности.
                    При этом допускается не только режим работы в качестве независимой точки доступа 'standalone', но и
                    совместная работа устройства с программным контроллером MikroTik CAPsMAN, благодаря которому управление
                    всеми точками доступа осуществляется в одном централизованном месте.
                  </p>
                  <p className="text-[14px] font-normal text-[#414141] leading-[19px] mb-[20px]">
                    Питание hAP ax3 может осуществляться за счет разъёма питания (блок питания 24 В, 1.5 А в комплекте), а
                    также посредством Passive PoE (PoE-инжектор 1 Гбит приобретается отдельно).
                  </p>
                </div>

                <div className="grid grid-cols-catalogCharacteristics gap-[10px]">
                  <div className="flex flex-col gap-[10px]">
                    <div className="border-[1px] border-[#EBEBEC] rounded-[15px] p-[30px] max-w-[370px] w-full self-start	">
                      <h2 className="mb-[25px] text-[#414141] text-[19px] font-medium">Основные характеристики</h2>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Артикул</p>
                        <p className="text-[#414141] text-[14px] font-normal">RBD53iG-5HacD2HnD</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Архитектура процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">ARM 32bit</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>
                    </div>
                    <div className="border-[1px] border-[#EBEBEC] rounded-[15px] p-[30px] max-w-[370px] w-full self-start	">
                      <h2 className="mb-[25px] text-[#414141] text-[19px] font-medium">Основные характеристики</h2>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Артикул</p>
                        <p className="text-[#414141] text-[14px] font-normal">RBD53iG-5HacD2HnD</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Архитектура процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">ARM 32bit</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>
                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>
                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <div className="border-[1px] border-[#EBEBEC] rounded-[15px] p-[30px] max-w-[370px] w-full self-start	">
                      <h2 className="mb-[25px] text-[#414141] text-[19px] font-medium">Основные характеристики</h2>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Артикул</p>
                        <p className="text-[#414141] text-[14px] font-normal">RBD53iG-5HacD2HnD</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Архитектура процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">ARM 32bit</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Артикул</p>
                        <p className="text-[#414141] text-[14px] font-normal">RBD53iG-5HacD2HnD</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Архитектура процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">ARM 32bit</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>
                    </div>
                    <div className="border-[1px] border-[#EBEBEC] rounded-[15px] p-[30px] max-w-[370px] w-full self-start	">
                      <h2 className="mb-[25px] text-[#414141] text-[19px] font-medium">Основные характеристики</h2>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Артикул</p>
                        <p className="text-[#414141] text-[14px] font-normal">RBD53iG-5HacD2HnD</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Архитектура процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">ARM 32bit</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <div className="border-[1px] border-[#EBEBEC] rounded-[15px] p-[30px] max-w-[370px] w-full self-start	">
                      <h2 className="mb-[25px] text-[#414141] text-[19px] font-medium">Основные характеристики</h2>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Артикул</p>
                        <p className="text-[#414141] text-[14px] font-normal">RBD53iG-5HacD2HnD</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Архитектура процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">ARM 32bit</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>
                    </div>
                    <div className="border-[1px] border-[#EBEBEC] rounded-[15px] p-[30px] max-w-[370px] w-full self-start	">
                      <h2 className="mb-[25px] text-[#414141] text-[19px] font-medium">Основные характеристики</h2>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Артикул</p>
                        <p className="text-[#414141] text-[14px] font-normal">RBD53iG-5HacD2HnD</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Архитектура процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">ARM 32bit</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>
                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>
                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Количество ядер</p>
                        <p className="text-[#414141] text-[14px] font-normal">процессора 4</p>
                      </div>

                      <div className="flex justify-between mb-[15px]">
                        <p className="text-[#B5B5B5] text-[14px] font-normal">Частота процессора</p>
                        <p className="text-[#414141] text-[14px] font-normal">716 MHz</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="max-w-[400px] w-full">
              <div className=" border-[1px] border-[#EBEBEC] rounded-[15px] max-h-[265px] py-[35px] px-[25px]">
                <div className="flex items-center mb-[30px]">
                  <div className="mr-[10px]">
                    <img src={boxes} alt="Boxes light" />
                  </div>
                  <p className="text-[16px] text-[#2886EB] font-semibold">Доступные склады</p>
                </div>

                <div className="flex justify-between">
                  <div className="flex flex-col max-w-[210px] w-full max-h-[36px] mr-[15px]">
                    {!!activeInputStorageField ? (
                      <div className="relative">
                        <div className="relative w-full max-w-[210px] h-[36px]">
                          <input className="text-start border-[1px] border-[#007AFF] text-[#909090] text-sm font-medium bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full max-w-[210px] rounded-[10px] h-[36px] outline-none" />
                          <img className="absolute top-[25%] right-2" src={arrowBlue} alt="arrow btn" />
                        </div>

                        <div className="absolute z-20 bg-[#FFF] text-[#666666] max-w-[210px] w-full rounded-[10px] max-h-[125px] overflow-y-auto shadow-[#3074B21A] shadow-sm">
                          <p
                            className="py-[8px] px-[10px] cursor-pointer"
                            onClick={() => setActiveInputStorageField((state) => !state)}
                          >
                            Склад 1
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="relative w-full max-w-[210px] h-[36px]"
                        onClick={() => setActiveInputStorageField((state) => !state)}
                      >
                        <button
                          type="button"
                          className="text-start text-[#909090] text-sm font-medium bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full max-w-[210px] rounded-[10px] h-[36px] outline-none border-none"
                        >
                          Склад
                        </button>
                        <img className="absolute top-[40%] right-2" src={arrow} alt="arrow btn" />
                      </div>
                    )}
                  </div>

                  <div className="flex items-center bg-[#1313140A] max-h-[36px] px-[5px] rounded-[10px]">
                    <button className="h-full w-[28px] flex justify-center items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="4" viewBox="0 0 10 4" fill="none">
                        <path d="M9.40156 0.900391V3.10039H0.601562V0.900391H9.40156Z" fill="#B5B5B5" />
                      </svg>
                    </button>
                    <div>
                      <input
                        className="border-none text-[#414141] text-sm font-medium bg-transparent py-[8px] pl-[10px] pr-[15px] w-full max-w-[55px] rounded-[10px] h-[36px] outline-none text-center"
                        value={1}
                      />
                    </div>
                    <button className="h-full w-[28px] flex justify-center items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path d="M4.1516 10V0H5.84853V10H4.1516ZM0 5.84848V4.15151H10V5.84848H0Z" fill="#414141" />
                      </svg>
                    </button>
                  </div>
                </div>

                <div className="flex justify-between my-[15px]">
                  <div className="flex flex-col max-w-[295px] w-full max-h-[36px] mr-[15px]">
                    {!!activeInputCartField ? (
                      <div className="relative">
                        <div className="relative w-full max-w-[290px] h-[36px]">
                          <input className="text-start border-[1px] border-[#007AFF] text-[#909090] text-sm font-medium bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full max-w-[290px] rounded-[10px] h-[36px] outline-none" />
                          <img className="absolute top-[25%] right-2" src={arrowBlue} alt="arrow btn" />
                        </div>

                        <div className="absolute bg-[#FFF] text-[#666666] max-w-[290px] w-full rounded-[10px] max-h-[125px] overflow-y-auto shadow-[#3074B21A] shadow-sm">
                          <p
                            className="py-[8px] px-[10px] cursor-pointer"
                            onClick={() => setActiveInputCartField((state) => !state)}
                          >
                            Память
                          </p>
                          <p
                            className="py-[8px] px-[10px] cursor-pointer"
                            onClick={() => setActiveInputCartField((state) => !state)}
                          >
                            Операционная система
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="relative w-full max-w-[290px] h-[36px]"
                        onClick={() => setActiveInputCartField((state) => !state)}
                      >
                        <button
                          type="button"
                          className="text-start text-[#909090] text-sm font-medium bg-[#13131408] py-[8px] pl-[10px] pr-[15px] w-full max-w-[290px] rounded-[10px] h-[36px] outline-none border-none"
                        >
                          Корзина #1
                        </button>
                        <img className="absolute top-[40%] right-2" src={arrow} alt="arrow btn" />
                      </div>
                    )}
                  </div>

                  <div className="flex items-center bg-[#1313140A] max-h-[36px] px-[10px] rounded-[10px]">
                    <button className="h-full w-[28px] flex justify-center items-center" onClick={() => setIsOpenCreatePhysicalCart(true)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 5L10 15" stroke="#007AFF" stroke-width="2" stroke-linecap="round" />
                        <path d="M15 10L5 10" stroke="#007AFF" stroke-width="2" stroke-linecap="round" />
                      </svg>
                    </button>
                  </div>
                </div>
                <button className="bg-[#2886EB1A] w-full rounded-[10px] px-[24px] py-[6px] text-[#007AFF]">
                  Добавить в корзину
                </button>
              </div>

              <div className="flex justify-between items-center border-[1px] border-[#EBEBEC] rounded-[15px] max-h-[265px] py-[22px] px-[25px] mt-[35px]">
                <p className="text-[#007AFF] text-[20px] font-semibold leading-[21px]">Цена</p>
                <p className="text-[#0F0F0F] text-[28px] font-semibold leading-[29px]">
                  143 343 543 <span className="text-[#0F0F0F] text-[20px] font-semibold leading-[29px]">сум</span>
                </p>
              </div>
            </div>
          </div>
        </>
      }
      createButton={false}
      isFilterButton={false}
      handleOpenCreateModals={() => {}}
      title="Каталог"
      placeholderText="Название товара"
    />
  );
};

export default Product;
