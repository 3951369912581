import ModalWindowWithForm from "../ModalWindowWithForm";

import arrow from "../../images/arrow.svg";
export default function InventoryControlModalView ({ title, name, modalWidth, isOpen, handleCloseAllModals, initialData }) {
  return (
    <ModalWindowWithForm
      resetForm={() => {}}
      onSubmit={() => {}}
      title={title}
      name={name}
      modalWidth={modalWidth}
      isOpen={isOpen}
      handleCloseAllModals={handleCloseAllModals}
    >
      <div>
        <div className="flex mb-[35px] mt-[40px]">
          <div className="flex flex-col min-w-[185px]">
            <label className="text-[#007AFF] text-base font-semibold">Инвойс</label>
            <p className="text-[#909090] text-sm font-medium mt-[10px]">558612112</p>
          </div>

          <div className="flex flex-col min-w-[185px]">
            <label className="text-[#007AFF] text-base font-semibold">Время</label>
            <p className="text-[#909090] text-sm font-medium mt-[10px]">02.01.2023 07:52</p>
          </div>
        </div>

        <div className="flex flex-col">
          <label className="text-[#007AFF] text-base font-semibold ml-[10px] mb-[10px]">Комментарий</label>
          <textarea className="pt-[15px] pr-[45px] pb-[35px] pl-[15px] bg-[#13131408] resize-none outline-none text-[#787878]"></textarea>
          <div className="flex justify-end">
            <button className=" px-[32px] py-[10px] text-sm font-medium bg-[#007AFF] text-white rounded-[10px] my-[35px]">
              Сохранить
            </button>
          </div>
        </div>

        <div className="flex flex-col">
          <label className="text-[#007AFF] text-base font-semibold mb-[25px]">Товары</label>

          <div className="">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <img src={arrow} className="mr-[10px]" />
                <p className="text-[#606060]">Mikrotik 89983SFdsa </p>
              </div>
              <div>
                <p>
                  Всего: <span className="text-[#FF3E3E] text-base font-semibold">7 шт</span>
                </p>
              </div>
            </div>
            <div className="border-l-[1px] border-[#606060] ml-[45px] mt-[10px] after:content-[''] after:bg-white after:w-2 after:h-4 after:absolute after:translate-y-[-11.3px] after:translate-x-[-4px]">
              <p className="flex items-center text-[#606060]">
                <span className="w-[10px] inline-block bg-[#606060] h-[1px] mr-[15px]"></span>fgdfg558612112
              </p>
              <p className="flex items-center text-[#606060]">
                <span className="w-[10px] inline-block bg-[#606060] h-[1px] mr-[15px]"></span>aedrfg653518670
              </p>
              <p className="flex items-center text-[#606060]">
                <span className="w-[10px] inline-block bg-[#606060] h-[1px] mr-[15px]"></span>sdfa267400456
              </p>
              <p className="flex items-center text-[#606060]">
                <span className="w-[10px] inline-block bg-[#606060] h-[1px] mr-[15px]"></span>gfghnfgdsfa4356
              </p>
              <p className="flex items-center text-[#606060]">
                <span className="w-[10px] inline-block bg-[#606060] h-[1px] mr-[15px]"></span>sdfsdsd2674004dfvss56
              </p>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img src={arrow} className="mr-[10px] rotate-[-90deg] " />
              <p className="text-[#606060]">Ьщгшрвамыу98оа9ц83г983 </p>
            </div>
            <div>
              <p>
                Всего: <span className="text-[#FF3E3E] text-base font-semibold">12 шт</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalWindowWithForm>
  );
}
