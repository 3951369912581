import React from "react";
import Draggable from "react-draggable";
import FilterPopupFilterSection from "./FilterPopupFilterSection";
import FilterPopupSortSection from "./FilterPopupSortSection";
import { useAttributeStore } from "../store/attribute";

function FilterPopup({
  isOpenFilterPopup,
  position,
  onDrag,
  onClose,
  isFiltersConfirm,
  onFiltersConfirm,
  paginationStore = useAttributeStore,
}) {
  const nodeRef = React.useRef(null);
  const { sorts, setSorts, filters, setFilters } = paginationStore();

  return (
    <Draggable nodeRef={nodeRef} cancel="button, input, label, select, svg, h2" position={position} onDrag={onDrag}>
      <section ref={nodeRef} className={`${isOpenFilterPopup ? "" : "filter-popup_state_fade"} !h-auto filter-popup`}>
        <div className="filter-popup__container">
          <button className="filter-popup__close-button zero-button" onClick={onClose}></button>
          {!!filters && (
            <FilterPopupFilterSection
              filters={filters}
              setFilters={setFilters}
              isFiltersConfirm={isFiltersConfirm}
              onFiltersConfirm={onFiltersConfirm}
            />
          )}

          <FilterPopupSortSection setSorts={setSorts} sorts={sorts} />
        </div>
      </section>
    </Draggable>
  );
}

export default FilterPopup;
