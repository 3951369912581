export {
	catalogApiData,
	balancesApiData,
	inventoryControlaApiData,
	attributesCategoryApiData,
	attributesApiData,
	productsCategoryApiData,
	systemUsersApiData,
};

export const URL =
	process.env.REACT_APP_PATH || 'https://api.dev.sales.ekom.uz';

export const TYPE_ATTRIBUTE_DATA = [
	{ id: 1, name: 'Целое' },
	{ id: 2, name: 'Строка' },
	{ id: 3, name: 'да/нет' },
	{ id: 4, name: 'дробное' },
];

export const TYPE_APLOGIST_DATA = [
	// { id: -0, name: "Убрать статус" },
	{ id: 0, name: 'Конвертация' },
	{ id: 1, name: 'В пути' },
	{ id: 2, name: 'На таможне' },
	{ id: 3, name: 'Товар растаможен' },
];

const attributesCategoryApiData = {
	path: 'adminpanel/json/post_get_all_attributes_category/',
	body: {
		page: 1,
		filters: {},
		sorters: {
			id: {
				type: 'asc_desc',
				pose: 0,
				value: 'true',
			},
			attr_count: {
				type: 'asc_desc',
				pose: 1,
				value: 'true',
			},
		},
		search_str: '',
	},
};

const attributesApiData = {
	path: 'adminpanel/json/post_get_all_attributes/',
	body: {
		page: 1,
		filters: {},
		sorters: {
			id: {
				type: 'asc_desc',
				pose: 0,
				value: 'true',
			},
			data_type: {
				type: 'asc_desc',
				pose: 1,
				value: 'true',
			},
			attribute_category: {
				type: 'asc_desc',
				pose: 2,
				value: 'true',
			},
		},
		search_str: '',
	},
};

const productsCategoryApiData = {
	path: 'adminpanel/json/post_get_all_products_category/',
	body: {
		page: 1,
		filters: {
			image: {
				type: 'true_false_both',
				value: 'both',
			},
		},
		sorters: {
			image: {
				type: 'asc_desc',
				pose: 0,
				value: 'true',
			},
			parent_name: {
				type: 'asc_desc',
				pose: 1,
				value: 'true',
			},
			category_name: {
				type: 'asc_desc',
				pose: 2,
				value: 'true',
			},
		},
		search_str: '',
	},
};

const systemUsersApiData = {
	path: 'adminpanel/json/post_get_all_system_users/',
	body: {
		page: 1,
		filters: {
			confirmation_document: {
				type: 'true_false_both',
				value: 'both',
			},
		},
		sorters: {
			id: {
				type: 'asc_desc',
				pose: 0,
				value: 'true',
			},
		},
		search_str: '',
	},
};

const inventoryControlaApiData = {
	path: 'storekeeper/inventory_control/post_get_all/',
	body: {
		page: 1,
		filters: {
			printed: {
				type: 'true_false_both',
				value: 'both',
			},
			accepted: {
				type: 'true_false_both',
				value: 'false',
			},
		},
		sorters: {
			id: {
				type: 'asc_desc',
				pose: 0,
				value: 'true',
			},
			datetime: {
				type: 'asc_desc',
				pose: 1,
				value: 'false',
			},
			accepted: {
				type: 'false_true',
				pose: 2,
				value: 'true',
			},
			printed: {
				type: 'false_true',
				pose: 3,
				value: 'true',
			},
		},
		search_str: '',
	},
};

const balancesApiData = {
	path: 'storekeeper/balances/json/post_get_all/',
	body: {
		page: 1,
		filters: {},
		sorters: {
			part_number: {
				type: 'asc_desc',
				pose: 0,
				value: 'true',
			},
			name: {
				type: 'asc_desc',
				pose: 1,
				value: 'false',
			},
			count: {
				type: 'asc_desc',
				pose: 2,
				value: 'true',
			},
		},
		search_str: '',
	},
};

const catalogApiData = {
	path: 'catalog/json/',
	body: {
		view_id: null,
		request_type: null,
		search_str: null,
		page: 1,
		filters: [],
	},
};
