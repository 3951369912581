import { useEffect, useState } from 'react';
import Main from '../../components/Main';
import Table from '../../components/Table';
import { useUsersStore } from '../../store/users';
import CreateUserModal from '../../components/modals/CreateUserModal';
import EditUserModal from '../../components/modals/EditUserModal';
import { useCommonAttributeCategoryStore } from '../../store/commonAttributeCategory';
import { useDecoratedCommonStore } from '../../hooks/useDecoratedCommonStore';
import { useCommonCityStore } from '../../store/commonCity';
import { useCommonCompanyStore } from '../../store/commonCompany';
import { useCommonUserRight } from '../../store/commonUserRight';

export default function Users() {
	const { data, isLoading, setFilters, filters } = useUsersStore();
	const [isNothingFound, setIsNothingFound] = useState(false);
	const { data: cities } = useDecoratedCommonStore(useCommonCityStore);
	const { data: companies } = useDecoratedCommonStore(useCommonCompanyStore);
	const { data: userRights } = useDecoratedCommonStore(useCommonUserRight);

	const [isOpenCreateUserModal, setIsOpenCreateUserModal] = useState(false);
	const [selectedItemFromTable, setSelectedItemFromTable] = useState(false);
	const [isOpenEditUserModal, setIsOpenEditUserModal] = useState(false);

	const titles = [
		{
			title: '№',
			style: '',
		},
		{
			title: 'ФИО',
			style: '',
		},
		{
			title: 'Почта',
			style: '',
		},
		{
			title: 'Физ/Юр. лицо',
			style: 'text-center',
		},
		{
			title: 'Телефон',
			style: 'text-center',
		},
		{
			title: 'Телеграмм',
			style: '',
		},
		{
			title: 'Город',
			style: '',
		},
		{
			title: 'Компания',
			style: '',
		},
	];

	// ["№", "ФИО", "Почта", "Физ/Юр. лицо", "Телефон", "Телеграмм", "Город", "Компания"]

	useEffect(() => {
		let data = { ...filters };
		let isUpdated = false;
		if (cities) {
			data = {
				...data,
				select: data.select.map((item) => {
					if (item.target !== 'city') return item;
					isUpdated = true;

					return {
						...item,
						dataList: cities,
					};
				}),
			};
		}
		if (companies) {
			data = {
				...data,
				select: data.select.map((item) => {
					if (item.target !== 'company') return item;
					isUpdated = true;
					return {
						...item,
						dataList: companies,
					};
				}),
			};
		}
		if (userRights) {
			data = {
				...data,
				checked: data.checked.map((item) => {
					if (item.target !== 'groups') return item;
					isUpdated = true;
					return {
						...item,
						dataList: userRights,
					};
				}),
			};
		}
		console.log(data);
		setFilters(data);
	}, [cities, companies, userRights]);

	const closeModals = () => {
		setIsOpenCreateUserModal(false);
		setIsOpenEditUserModal(false);
	};

	return (
		<Main
			isInteractionPanel={true}
			paginationStore={useUsersStore}
			modals={
				<>
					<CreateUserModal
						title='Создание пользователя'
						// initialData={selectedItemFromTable}
						handleCloseAllModals={closeModals}
						isOpen={isOpenCreateUserModal}
						name='create-user-modal'
						modalWidth={{ width: '710px' }}
					/>

					<EditUserModal
						title='Редактировать профиль пользователя'
						initialData={selectedItemFromTable}
						handleCloseAllModals={closeModals}
						isOpen={isOpenEditUserModal}
						name='edit-user-modal'
						modalWidth={{ width: '655px' }}
					/>
				</>
			}
			element={
				<Table
					handleCloseAllModals={() => {}}
					handleOpenTableModals={() => {}}
					isNothingFound={isNothingFound}
					isLoading={isLoading}
					tableData={{
						titles,
						content: data,
					}}
					depicter={(item, onClick, specialClass) => {
						const {
							id,
							first_name,
							middle_name,
							last_name,
							email,
							Physical_LegalFace,
							phone_number,
							telegram,
							city,
							company,
						} = item;
						return (
							<tr
								onClick={() => {
									setIsOpenEditUserModal(true);
									setSelectedItemFromTable(item);
								}}
								className={`table__row ${specialClass}`}>
								<td className='table__content'>{id}</td>
								<td className='table__content block !truncate'>
									{first_name} {middle_name} {last_name}
								</td>
								<td className='table__content block !truncate '>
									{email}
								</td>
								<td className='table__content block !truncate'>
									<div
										className={`${
											Physical_LegalFace === 1
												? 'bg-[#007AFF]'
												: 'bg-[#3CDE7D]'
										} w-[3.125rem] h-[1.375rem] flex items-center justify-center rounded-[1.25rem] font-medium text-xs leading-[14px] text-white`}>
										{Physical_LegalFace === 1
											? 'Физ'
											: 'Юр'}
									</div>
								</td>
								<td className='table__content block !truncate'>
									{phone_number}
								</td>
								<td className='table__content block !truncate'>
									{telegram}
								</td>
								<td className='table__content block !truncate'>
									{city}
								</td>
								<td className='table__content block !truncate'>
									{company}
								</td>
							</tr>
						);
					}}
					specialClass='table__row_grid_system-users'
				/>
			}
			createButton={true}
			isFilterButton={true}
			handleOpenCreateModals={() => {
				setIsOpenCreateUserModal(true);
			}}
			title='Пользователи системы'
			placeholderText='Фио или почта'
		/>
	);
}
