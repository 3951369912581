import axios from "axios";
import { URL } from "../utils/constants";
export const getToken = async (
    email,
    password,
    props
) => {
    try {
        const response = await axios.post(`${URL}/get_token/`, {
            email,
            password
        });
        console.log(response)
        props(response.data.authtoken);
    } catch (error) {
        console.error('Error:', error);
        if (axios.isAxiosError(error)) {
            props(error?.response?.data.success)

        } else {
            console.log(error);
            console.error('Error:', error);
        }
    }
};
