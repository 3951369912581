import logo from "../images/ekomuzLogo.svg";
import { useForm } from "react-hook-form";
import { useUserStore } from "../store/user";
import { getToken } from "../service/auth";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ModalWindowWithForm from "../components/ModalWindowWithForm";

import RestartCircle from "../images/RestartCircle.svg";

const Login = () => {
  const navigate = useNavigate();
  const { setToken } = useUserStore();
  const [errorLogin, setErrorLogin] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isOpenPasswordRecovery, setIsOpenPasswordRecovery] = useState(false);

  const handleCloseAllModals = () => {
    setIsOpenPasswordRecovery(false);
  };

  const onChanngeRememberMe = () => {
    setRememberMe((state) => !state);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (errorLogin) {
      setErrorMessage("Данные авторизации неверны");
    } else if (errors.email || errors.password) {
      setErrorMessage("Заполните поля");
    } else setErrorMessage("");
  }, [errorLogin, errors]);

  const onSubmit = (data) => {
    const { email, password, save } = data;
    getToken(email, password, (token) => {
      if (!token) {
        setErrorLogin(true);
      } else if (token) {
        setErrorLogin(false);
        setToken(token, save);

        navigate("/");
        console.log({ token });
      }
    });
  };

  return (
    <>
      <div className="bg-white w-full flex flex-col justify-between items-center h-full overflow-auto">
        {" "}
        {/*login__wrapper*/}
        <div></div>
        <div className="h-auto w-full flex items-center flex-col justify-center mt-[50px]">
          {" "}
          {/* login__form-block  */}
          <div className="">
            <img src={logo} alt="logo" />
          </div>
          <div className="mt-16 bg-[#f8f8f8] flex flex-col text-center w-[315px] max-w-full px-6 py-8 rounded-[20px]">
            {/*login__auth-form*/}
            <h2 className="text-[21px] font-semibold leading-[29px] m-auto mb-[43px] text-[#414141]">Авторизация</h2> {/*login__header */}
            <form onSubmit={handleSubmit(onSubmit)} className=" flex flex-col">
              {" "}
              {/*login__form */}
              <input
                className="rounded-[10px] h-9 py-2 px-[15px] border-0 outline-none focus:border-[1px] focus:border-[#007AFF] focus:text-[#007AFF] focus:bg-transparent bg-[#13131408] text-[#909090] mb-[27px] text-sm font-medium"
                type="email"
                placeholder="admin@sales.ekom.uz"
                {...register("email", { required: true })}
              />{" "}
              {/*login__input */}
              <input
                className="rounded-[10px] h-9 py-2 px-[15px] border-0 outline-none focus:border-[1px] focus:border-[#007AFF] focus:text-[#007AFF] focus:bg-transparent bg-[#13131408] text-[#909090] mb-[27px] text-sm font-medium"
                type="password"
                placeholder="•••••••••••"
                {...register("password", { required: true })}
              />{" "}
              {/*login__input*/}
              <div className="flex px-[4px] py-[6px] items-center">
                <label className="cursor-pointer" onClick={onChanngeRememberMe}>
                  <div
                    className={`border-[1.4px] border-solid border-[#9A9A9B] w-[20px] h-[20px] rounded-[6px] relative ${
                      rememberMe &&
                      "bg-[#007AFF] border-none before:absolute before:content-[''] before:left-[50%] before:top-[45%] before:w-[6.5px] before:h-[12px] before:border-[#FFF] before:border-t-[0] before:border-r-[2px] before:border-b-[2px] before:border-l-[0] 0] before:rounded-sm m-[ -1px -1px 0 -1px] before:rotate-[45deg] before:translate-x-[-50%] before:translate-y-[-50%] z-[2]"
                    }`}
                  ></div>
                  <input
                    {...register("save")}
                    type="checkbox"
                    id="checkbox-login"
                    className="hidden"
                    value={rememberMe}
                    onChange={onChanngeRememberMe}
                  />
                </label>
                <label htmlFor="checkbox-login" className="ml-2 cursor-pointer text-[#414141]" onClick={onChanngeRememberMe}>
                  Запомнить меня
                </label>
              </div>
              <div className="flex px-[4px] py-[6px]">
                {errorLogin && <p className="ml-2 text-red-500">{errorMessage}!</p>}
              </div>
              <button
                type="submit"
                className="font-medium text-sm w-full bg-[#007aff] hover:bg-[#3495FF] hover:ease-in-out hover:transition-all h-10 mb-4 py-2.5 px-8 rounded-[10px] text-white flex justify-center items-center outline-none border-0"
              >
                Войти
              </button>{" "}
              {/*login__btn-auth */}
              <button
                className="font-medium text-sm hover:bg-[#2886EB29] hover:ease-in-out hover:transition-all bg-[#2886eb1a] h-[41px] py-[10px] px-[32px] rounded-[10px] text-[#007AFF] flex justify-center items-center outline-none border-0"
                type="button"
                onClick={() => setIsOpenPasswordRecovery(true)}
              >
                Забыл пароль
              </button>{" "}
              {/*login__btn-forgot-password */}
            </form>
          </div>
        </div>
        <div className="bg-[#2886EB1A] min-h-[140px] px-[10px] py-[25px] w-full mt-[50px]">
          <p className="text-[#007AFF] text-base font-extrabold leading-[22px] text-center"> КОНТАКТЫ</p>
          <div className="flex justify-center mt-[35px] mb-[25px] flex-wrap">
            <p className="mr-[49px] text-[#414141] text-sm">г. Ташкент, ул. Алишера Навои 16A, офис: 501</p>
            <p className="mr-[49px] text-[#414141] text-sm">
              <a href="tel:+99871203-45-12">+998 71 203-45-12 </a>(звонок бесплатный)
            </p>
            <p className="mr-[49px] text-[#414141] text-sm">
              <a href="mailto:sales@ekom.uz">sales@ekom.uz</a>
            </p>
            <p className="mr-[49px] text-[#414141] text-sm">Пн-Сб 9.00 - 18.00</p>
          </div>
        </div>
      </div>

      <ModalWindowWithForm
        confirmButtonText="Сохранить"
        initialData={() => {}}
        handleCloseAllModals={handleCloseAllModals}
        modalWidth={{ width: "315px" }}
        resetForm={() => {}}
        onSubmit={() => {}}
        name="password-recovery-modal"
        isOpen={isOpenPasswordRecovery}
      >
        <div className="">
          <div className="mb-[24px] flex justify-center">
            <img src={RestartCircle} alt="Restart Circle" />
          </div>
          <div className="mb-[32px]">
            <h2 className="text-center mb-[10px] text-[21px] text-[#414141] font-semibold">Восстановление пароля</h2>
            <p className="text-center text-[#A0A0A0] text-base font-normal">Для смены пароля обратитесь к администратору</p>
          </div>
          <div>
            <button
              type="button"
              onClick={() => handleCloseAllModals(true)}
              className="w-full bg-[#007aff] h-10 py-2.5 px-8 rounded-[10px] text-white flex justify-center items-center outline-none border-0"
            >
              Отлично
            </button>
          </div>
        </div>
      </ModalWindowWithForm>
    </>
  );
};

export default Login;
