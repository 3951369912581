import { useForm } from 'react-hook-form';
import ModalWindowWithForm from '../ModalWindowWithForm';
import { useEffect } from 'react';
import { useUserStore } from '../../store/user';
import {
	createAttributeCategory,
	updateAttributeCategory,
} from '../../service/attributeCategory';
import { useAttributeStore } from '../../store/attribute';
import { useAttributeCategoryStore } from '../../store/attributeCategory';

export default function AttributeCategoryModal({
	title,
	name,
	modalWidth,
	isOpen,
	handleCloseAllModals,
	confirmButtonText,
	initialData,
}) {
	const { token } = useUserStore();
	const { updateDataList } = useAttributeCategoryStore();
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		if (!token) return;
		const { name, unit } = data;
		if (initialData) {
			updateAttributeCategory(token, initialData.id, name, (result) => {
				if (result) {
					updateDataList(token);
					handleCloseAllModals();
				}
			});
		} else {
			createAttributeCategory(token, name, (result) => {
				if (result) {
					updateDataList(token);
					handleCloseAllModals();
				}
			});
		}
	};

	useEffect(() => {
		if (initialData) {
			setValue('name', initialData.name);
		}
	}, [initialData]);

	return (
		<ModalWindowWithForm
			resetForm={reset}
			onSubmit={handleSubmit(onSubmit)}
			title={title}
			name={name}
			modalWidth={modalWidth}
			isOpen={isOpen}
			handleCloseAllModals={handleCloseAllModals}>
			<div className='attributes-modal'>
				<div className='attributes-modal__container '>
					<label className='attributes-modal__field-name'>
						Название категории
					</label>
					<input
						id='category-name-input'
						placeholder='Название категории'
						className='attributes-modal__input zero-input'
						name={`${name}-input-name`}
						{...register('name', { required: true })}
					/>
				</div>
			</div>
			<div className='attributes-modal__flex-wrapper'>
				<button
					type='button'
					className='cansel-button zero-button'
					onClick={() =>
						handleCloseAllModals((state) => (state = !isOpen))
					}>
					Отмена
				</button>
				<button type='submit' className='confirm-button zero-button'>
					{confirmButtonText}
				</button>
			</div>
		</ModalWindowWithForm>
	);
}
