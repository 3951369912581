function RadiosFilterPopup({ filter, setValueFilter }) {
	const { id, title, value, icon } = filter;

	// {
	//     id: '1111',
	//     title: 'Кол-во атрибутов',
	//     target: 'image',
	//     values: [
	//         { lable: '+', value: true },
	//         { lable: '-', value: false },
	//         { lable: '+-', value: undefined }
	//     ]
	// }

	const toggleValue = (value) => {
		return () => {
			setValueFilter(value);
		};
	};

	return (
		<div className='filter-popup__inputs'>
			<h2 className='filter-popup__subtitle flex gap-2'>
				{icon}
				{title}
			</h2>
			<div className='filter-popup__flex-wrapper'>
				{filter.values?.map((item) => {
					return (
						<div className='filter-popup__radio-button'>
							<input
								checked={item.value === value}
								type='radio'
								id={`${id + item}-1`}
								name={item}
								className='filter-popup__radio'></input>
							<label
								onClick={toggleValue(item.value)}
								className={`filter-popup__radio-label ${
									item.value === value
										? ' text-white'
										: ' text-[#007AFF]'
								}`}
								htmlFor={`${id + item}-1`}>
								{!!item.lableActive && item.value === value
									? item.lableActive
									: item.lable}
							</label>
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default RadiosFilterPopup;
