import React, { useEffect } from 'react';
import CatalogItem from './CatalogItem';
import { useDecoratedPaginationStore } from '../../hooks/usePaginationStoreDecoration';
import { useProductStore } from '../../store/product';

const CatalogList = () => {
	const { loadMore, togglePage } =
		useDecoratedPaginationStore(useProductStore);
	const { data: products } = useProductStore();
	useEffect(() => {
		console.log(products);
	}, [products]);
	return (
		<div className='flex flex-wrap'>
			{products?.map((item) => {
				return <CatalogItem {...item} />;
			})}
		</div>
	);
};

export default CatalogList;
