import axios from "axios";
import { URL } from "../utils/constants";


export const getServices = async (
    token,
    props
) => {
    try {


        const { data } = await axios.get(`${URL}/logist/viewsets/service_type/`, {
            headers: {
                Authorization: `Token ${token}`
            }

        });
        props?.(data);

    } catch (error) {
        console.error('Error:', error);
        props?.(null)
    }
};

export const createAttributeCategory = async (
    token,
    name,
    props
) => {
    try {


        const { data } = await axios.post(`${URL}/adminpanel/attribute_category/`, {
            name
        }, {
            headers: {
                Authorization: `Token ${token}`
            }

        });
        props?.(data);

    } catch (error) {
        console.error('Error:', error);
        props?.(null)
    }
};