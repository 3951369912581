import { useEffect, useState } from 'react';
import Main from '../../components/Main';
import AttributesModalWindow from '../../components/AttributesModalWindow';
import Table from '../../components/Table';
import { useAttributeStore } from '../../store/attribute';
import { useCommonAttributeCategoryStore } from '../../store/commonAttributeCategory';
import { useDecoratedCommonStore } from '../../hooks/useDecoratedCommonStore';
import { TYPE_ATTRIBUTE_DATA } from '../../utils/constants';

export default function Attributes() {
	const { data, isLoading, setFilters, filters } = useAttributeStore();
	const { data: attributeCategories } = useDecoratedCommonStore(
		useCommonAttributeCategoryStore
	);

	const [selectedItemFromTable, setSelectedItemFromTable] =
		useState(undefined);
	const [isNothingFound, setIsNothingFound] = useState(false);
	const [isCreateAttributesModalOpen, setIsCreateAttributesModalOpen] =
		useState(false);
	const [isEditAttributesModalOpen, setIsEditAttributesModalOpen] =
		useState(false);

	const titles = [
		{
			title: '№',
			style: '',
		},
		{
			title: 'Атрибут',
			style: '',
		},
		{
			title: 'Категория',
			style: '',
		},
		{
			title: 'Ед. измерения',
			style: '',
		},
		{
			title: 'Тип',
			style: '',
		},
	];

	useEffect(() => {
		if (attributeCategories) {
			let isUpdated = false;
			const data = {
				...filters,
				select: filters.select.map((item) => {
					if (item.target !== 'category') return item;
					isUpdated = true;
					return {
						...item,
						dataList: attributeCategories,
					};
				}),
			};
			if (isUpdated) setFilters(data);
		}
	}, [attributeCategories]);
	useEffect(() => {
		console.log(filters);
	}, [filters]);

	const handleCloseAllModals = () => {
		setIsCreateAttributesModalOpen(false);
		setIsEditAttributesModalOpen(false);
	};

	const handleOpenTableModals = (data) => {
		setIsEditAttributesModalOpen(true);
	};

	const handleOpenCreateModals = () => {
		setIsCreateAttributesModalOpen(true);
	};

	return (
		<Main
			paginationStore={useAttributeStore}
			isInteractionPanel={true}
			modals={
				<>
					<AttributesModalWindow
						confirmButtonText='Сохранить'
						initialData={selectedItemFromTable}
						handleCloseAllModals={handleCloseAllModals}
						isOpen={isEditAttributesModalOpen}
						title='Редактирование атрибута товаров'
						name='edit-attributes-modal'
						modalWidth={{ width: '500px' }}
					/>{' '}
					<AttributesModalWindow
						confirmButtonText='Создать'
						handleCloseAllModals={handleCloseAllModals}
						isOpen={isCreateAttributesModalOpen}
						title='Создание атрибута товаров'
						name='create-attributes-modal'
						modalWidth={{ width: '500px' }}
					/>
				</>
			}
			element={
				<Table
					handleCloseAllModals={handleCloseAllModals}
					handleOpenTableModals={handleOpenTableModals}
					isNothingFound={isNothingFound}
					tableData={{
						titles,
						content: data,
					}}
					isLoading={isLoading}
					depicter={(item, onClick, specialClass) => {
						// attribute_category: 28
						// data_type: 1
						// id: 1
						// name: "Мощность"
						// unit: "ВТ"
						const { id, name, unit, data_type, category } = item;
						return (
							<tr
								onClick={() => {
									setIsEditAttributesModalOpen(true);
									setSelectedItemFromTable(item);
								}}
								key={id}
								className={`table__row ${specialClass}`}>
								<td className='table__content'>{id}</td>
								<td className='table__content'>{name}</td>
								<td className='table__content'>
									{
										attributeCategories?.find(
											(item) => item.id === category
										)?.name
									}
								</td>
								<td className='table__content'>{unit}</td>
								<td className='table__content'>
									{
										TYPE_ATTRIBUTE_DATA?.find(
											(item) => item.id === data_type
										)?.name
									}
								</td>
							</tr>
						);
					}}
					specialClass='table__row_grid_attributes'
				/>
			}
			isFilterButton={true}
			handleOpenCreateModals={handleOpenCreateModals}
			createButton={true}
			title='Атрибуты'
			placeholderText='Наименование'
		/>
	);
}
