import { createPaginationStore } from "../common/createPaginationStore";
import { getAPLogistList } from "../service/adminPanelLogist";
import { ReactComponent as Status } from "../images/Scan_alt.svg";
import { ReactComponent as CloseBlue } from "../images/closeRing.svg";
import { ReactComponent as CloseWhite } from "../images/Close_round_white.svg";
import { ReactComponent as DoneStatusNoActive } from "../images/Done_round_blue.svg";
import { ReactComponent as DoneStatusActive } from "../images/Done_round_white.svg";
import { ReactComponent as Wait } from "../images/wait.svg";
import { ReactComponent as WaitActive } from "../images/wait_white.svg";
import { ReactComponent as FileDock } from "../images/File_dock_light.svg";
import { ReactComponent as UserRounded } from "../images/user_rounded.svg";
import { ReactComponent as CopyBlue } from "../images/Copy_light_blue.svg";
import { ReactComponent as CopyLightBlue } from "../images/Copy_light.svg";

import { TYPE_APLOGIST_DATA } from "../utils/constants";

export const useAPLogistStore = createPaginationStore(
  (options, props, errorProps) => {
    const { token, pageSize, targetPage, search, sorts, filters } = options;
    getAPLogistList(token, pageSize, targetPage, search, sorts, filters, (logist, totalLogist, maxPages) => {
      if (logist && typeof totalLogist === "number" && maxPages) {
        props(logist, totalLogist, maxPages);
      } else {
        errorProps();
      }
    });
  },
  10,
  [
    { id: "111", title: "№", value: false, target: "id" },
    { id: "222", title: "Инвойс", value: false, target: "invoice" },
    { id: "333", title: "Старый статус", value: false, target: "curr_stage" },
    { id: "444", title: "Новый статус", value: false, target: "need_stage" },
    { id: "555", title: "ФИО Логиста", value: false, target: "shipping__user_id" },
    { id: "666", title: "Статус заявки", value: false, target: "status" },
  ],
  {
    checkedColumn: [
      {
        id: "11",
        title: "Старый статус",
        target: "curr_stage",
        icon: <Status />,
        values: -0,
        dataList: TYPE_APLOGIST_DATA,
      },
      {
        id: "22",
        title: "Новый статус",
        target: "need_stage",
        icon: <Status />,
        values: -0,
        dataList: TYPE_APLOGIST_DATA,
      },
      {
        id: "33",
        title: "ФИО Логиста",
        target: "shipping__user_id",
        icon: <UserRounded />,
        values: [],
        dataList: [],
      },
    ],
    trueFalseBoth: [
      {
        id: "444",
        title: "Статус заявки",
        icon: <FileDock />,
        target: "status",
        values: [
          { lable: <DoneStatusNoActive />, lableActive: <DoneStatusActive />, value: 1 },
          { lable: <CloseBlue />, lableActive: <CloseWhite />, value: 2 },
          { lable: <CopyBlue />, lableActive: <CopyLightBlue />, value: -0 },
        ],
      },
    ],
  }
);
