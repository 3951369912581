import { useEffect, useRef, useState } from 'react';
import arrowBlue from '../../images/ArrowBlue.svg';
import arrow from '../../images/arrow.svg';

export default function SelectAPLogist({
	options,
	targetOptionst,
	setTargetOptions = () => {},
	filter,
	withSearch = true,
	placeholder,
	id,
	customOption,
	className,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const [search, setSearch] = useState('');
	const selectRef = useRef(null);
	const toggleIsOpen = () => {
		setIsOpen(!isOpen);
	};

	const handleClickOutside = (event) => {
		if (!selectRef.current || !selectRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);
	
	return (
		<>
			<div
				tabIndex='0'
				ref={selectRef}
				className={`relative z-11 w-full ${className}`}>
				<div
					onClick={() => {
						setIsOpen(!isOpen);
					}}
					className='relative w-full h-[37px] '>
					<div
						className={`text-start relative overflow-ellipsis overflow-hidden whitespace-nowrap  cursor-pointer border-[1px] border-[#0000] text-[#909090] text-sm font-medium bg-[#13131408] py-[8px] pl-[10px] pr-[20px] w-full rounded-[10px] h-[37px] outline-none
                            ${isOpen && ' !border-[#007AFF] '}`}>
						{!targetOptionst ? placeholder : targetOptionst.name || `${targetOptionst.first_name} ${targetOptionst.middle_name} ${targetOptionst.last_name}`}
					</div>
					<input
						id={id}
						className=' text-xs absolute top-0 -z-10 opacity-0'
						value={targetOptionst?.name}
					/>
					{isOpen ? (
						<img
							className='absolute top-[25%] right-2'
							src={arrowBlue}
							alt='arrow btn'
						/>
					) : (
						<img
							className='absolute top-[40%] right-2'
							src={arrow}
							alt='arrow btn'
						/>
					)}
				</div>

				{isOpen && (
					<div className='  absolute bg-[#FFF] text-[#666666] z-10 w-full rounded-[10px] max-h-[125px] overflow-y-auto shadow-[#3074B21A] shadow-sm'>
						{filter && withSearch && (
							<div className='search-options'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='20'
									height='20'
									viewBox='0 0 20 20'
									fill='none'>
									<circle
										cx='9.16663'
										cy='9.16675'
										r='5'
										stroke='#A1B6C9'
										strokeWidth='1.2'></circle>{' '}
									<path
										d='M16.6666 16.6667L14.1666 14.1667'
										stroke='#A1B6C9'
										strokeWidth='1.2'
										strokeLinecap='round'></path>
								</svg>
								<div className='flex'>
									<input
										className=' min-w-[11rem] grow w-full'
										placeholder={placeholder}
										onFocus={() => {
											setIsOpen(true);
										}}
										value={search}
										onChange={(e) =>
											setSearch(e.target.value)
										}
									/>
								</div>
							</div>
						)}
						<ul className='select-list-product'>
							{!!options &&
								options
									.filter((item) => {
										if (!filter) return true;
										return filter(item, search);
									})
									.map((item) => {
										return (
											customOption?.(
												item,
												toggleIsOpen,
												targetOptionst,
												setTargetOptions
											) || (
												<li
													className='py-[8px] px-[10px] cursor-pointer'
													key={item.id}
													onClick={() => {
														toggleIsOpen();
														if (
															targetOptionst?.id !==
															item?.id
														) {
															setTargetOptions(
																item
															);
														}
													}}>
													 {item.name ? item.name : `${item.first_name} ${item.middle_name} ${item.last_name}`}
												</li>
											)
										);
									})}
						</ul>
					</div>
				)}
			</div>
		</>
	);
}
