import React from 'react';
import PhysicalCartItem from './PhysicalCartItem';

function PhysicalCart(props) {
  const [cards, setCards] = React.useState([1, 2, 3, 3]);

  return (
    <section className="physical-cart">
      {cards.map((item) => {
        return <PhysicalCartItem />
      })}
      <button className="physical-cart__create-button zero-button">Новая корзина +</button>
    </section>
  )
};

export default PhysicalCart;
