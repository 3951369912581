function NavMenuCategory({
    isOpen,
    children,
    isAnimation
}) {
    return (
        <nav className={`${isAnimation && 'animation-none'}  ${isOpen ? 'nav-menu__category-container_open' : 'nav-menu__category-container'}`}>
            {children}
        </nav>
    )
};

export default NavMenuCategory;
