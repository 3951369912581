import { useForm } from 'react-hook-form';
import ModalWindowWithForm from './ModalWindowWithForm';
import { useState, useEffect } from 'react';
import { useUserStore } from '../store/user';
import { createAttribute, updateAttribute } from '../service/attributes';
import { useAttributeStore } from '../store/attribute';
import { TYPE_ATTRIBUTE_DATA } from '../utils/constants';
import { useDecoratedCommonStore } from '../hooks/useDecoratedCommonStore';
import { useCommonAttributeCategoryStore } from '../store/commonAttributeCategory';
import Select from './UI/Select';

function AttributesModalWindow({
	title,
	name,
	modalWidth,
	isOpen,
	handleCloseAllModals,
	confirmButtonText,
	initialData,
}) {
	const { data: attributeCategoryData } = useDecoratedCommonStore(
		useCommonAttributeCategoryStore
	);
	const { token } = useUserStore();
	const { updateDataList } = useAttributeStore();
	const [selectedAttributeCategoryItem, setSelectedAttributeCategoryItem] =
		useState();
	const [selectedTypeItem, setSelectedTypeItem] = useState();
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		if (!token || !selectedAttributeCategoryItem || !selectedTypeItem)
			return;
		const { name, unit } = data;
		if (initialData) {
			updateAttribute(
				token,
				initialData.id,
				name,
				unit,
				selectedTypeItem.id,
				selectedAttributeCategoryItem.id,
				(result) => {
					if (result) {
						updateDataList(token);
						handleCloseAllModals();
					}
				}
			);
		} else {
			createAttribute(
				token,
				name,
				unit,
				selectedTypeItem.id,
				selectedAttributeCategoryItem.id,
				(result) => {
					if (result) {
						updateDataList(token);
						handleCloseAllModals();
					}
				}
			);
		}
	};

	useEffect(() => {
		if (initialData) {
			setSelectedTypeItem(
				TYPE_ATTRIBUTE_DATA.find((item) => {
					if (item.id === initialData.data_type) {
						return item;
					}
				})
			);
			setValue('name', initialData.name);
			setValue('unit', initialData.unit);
		}
	}, [initialData]);

	useEffect(() => {
		if (attributeCategoryData && initialData) {
			setSelectedAttributeCategoryItem(
				attributeCategoryData.find((item) => {
					if (item.id === initialData.category) {
						return item;
					}
				})
			);
		}
	}, [initialData, attributeCategoryData]);

	return (
		<ModalWindowWithForm
			resetForm={reset}
			onSubmit={handleSubmit(onSubmit)}
			title={title}
			name={name}
			modalWidth={modalWidth}
			isOpen={isOpen}
			handleCloseAllModals={handleCloseAllModals}>
			<div className='attributes-modal'>
				<div className='attributes-modal__container'>
					<label className='attributes-modal__field-name'>
						Название атрибута
					</label>
					<input
						placeholder='Название атрибута'
						id='attribute-name-input'
						className='attributes-modal__input zero-input'
						name={`${name}-input-name`}
						{...register('name', { required: true })}
					/>
				</div>
				<div className='attributes-modal__container'>
					<label className='attributes-modal__field-name'>
						Категория атрибута
					</label>
					<Select
						id='attribute-category-select'
						filter={(item, search) =>
							item.name
								?.toLowerCase()
								.includes(search.toLowerCase())
						}
						options={attributeCategoryData}
						targetOptionst={selectedAttributeCategoryItem}
						setTargetOptions={(value) =>
							setSelectedAttributeCategoryItem({
								id: value.id,
								name: value.name,
							})
						}
						placeholder='Категория атрибута'
					/>
				</div>
				<div className='attributes-modal__container'>
					<label className='attributes-modal__field-name'>
						Ед. измерения атрибута
					</label>
					<input
						id='unit-dimensions-input'
						placeholder='Ед. измерения атрибута'
						className='attributes-modal__input zero-input'
						name={`${name}-input-units`}
						{...register('unit', { required: true })}
					/>
				</div>
				<div className='attributes-modal__container'>
					<label className='attributes-modal__field-name'>
						Тип атрибута
					</label>
					<Select
						id='attribute-type-select'
						options={TYPE_ATTRIBUTE_DATA}
						targetOptionst={selectedTypeItem}
						setTargetOptions={(value) =>
							setSelectedTypeItem({
								id: value.id,
								name: value.name,
							})
						}
						placeholder='Тип атрибута'
					/>
				</div>
			</div>
			<div className='attributes-modal__flex-wrapper'>
				<button
					type='button'
					className='cansel-button zero-button'
					onClick={() =>
						handleCloseAllModals((state) => (state = !isOpen))
					}>
					Отмена
				</button>
				<button type='submit' className='confirm-button zero-button'>
					{confirmButtonText}
				</button>
			</div>
		</ModalWindowWithForm>
	);
}

export default AttributesModalWindow;
