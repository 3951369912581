import React, { useEffect, useState } from 'react';
import ModalWindowWithForm from '../ModalWindowWithForm';
import closeBtn from '../../images/Close_round_duotone_line.svg';
import doneStatus from '../../images/Done_round.svg';
import rightMove from '../../images/right-move.svg';
import { changeStageToNextLogistShipping } from '../../service/logistShipping';
import { useUserStore } from '../../store/user';
import { useLogistShippingStore } from '../../store/logistShipping';
import { getContentForShippingByType } from '../../utils/getContentForShippingByType';
import { getLogistShippingDetail } from '../../service/logistShipping';
import { useCommonDocumentType } from '../../store/commonDocumentType';
import { useDecoratedCommonStore } from '../../hooks/useDecoratedCommonStore';

const MoveLogisticsOrder = ({
	isOpen,
	closeModals,
	target: {
		id,
		invoice_str,
		pay_date,
		vendor_name,
		count,
		sum_price,
		type,
		typeId,
	},
}) => {
	const { token } = useUserStore();
	const { updateDataList } = useLogistShippingStore();
	const { data: documentTypes } = useDecoratedCommonStore(
		useCommonDocumentType
	);
	const data = getContentForShippingByType(type);
	const [documets, setDocumets] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [letMove, setLetMove] = useState(false);

	useEffect(() => {
		if (id && token && !isLoading && isOpen) {
			setIsLoading(true);
			getLogistShippingDetail(token, id, (result) => {
				if (result) {
					setDocumets(result.documents);
				}
				setIsLoading(false);
			});
		}
	}, [id, token, isOpen]);

	useEffect(() => {
		if (typeof documets?.length === 'number' && !documets?.length) {
			setLetMove(!false);
		} else {
			let result = true;
			documentTypes?.forEach((item) => {
				if (
					item.required_stage !== typeId ||
					!item.is_required ||
					!result
				)
					return;
				console.log(documets, item);
				const attachedDocument =
					documets.findIndex(
						(document) => document.type === item.id
					) >= 0;
				if (attachedDocument) return;
				result = false;
			});
			setLetMove(result);
		}
	}, [documets, documentTypes, id]);

	const moveShipping = () => {
		if (token) {
			changeStageToNextLogistShipping(token, id, () => {
				updateDataList(token);
				closeModals();
			});
		}
	};
	//    {
	//     "id": 1,
	//     "name": "Документ Уникальный",
	//     "is_required": false,
	//     "required_stage": 2,
	//     "show_always": true,
	//     "unique": true
	//   },

	return (
		<ModalWindowWithForm
			resetForm={() => {}}
			onSubmit={() => {}}
			title='Перемещение заказа'
			name='move-logistics-order-modal'
			modalWidth={{ width: '550px' }}
			isOpen={isOpen}
			handleCloseAllModals={closeModals}>
			<h2 className='my-[25px] max-w-[330px] text-[#474747] text-[15px] font-bold leading-[21px]'>
				Вы действительно хотите изменить статус
				<span className='text-[#4E89FD] text-[15px] font-bold leading-[21px]'>
					{' '}
					Инвойса №{invoice_str}{' '}
				</span>{' '}
				на
				<span className='text-[#4E89FD] text-[15px] font-bold leading-[21px]'>
					{' '}
					{data.next.content}
				</span>{' '}
				?
			</h2>
			<div className='my-[25px]'>
				<div className='flex justify-between my-[10px]'>
					<p className='text-[#929292] text-[13px] font-bold'>
						Инвойс № {invoice_str}
					</p>
					<p className='text-[#6081F6] text-[13px] font-bold'>
						{pay_date}
					</p>
				</div>
				<div className='flex justify-between my-[10px]'>
					<p className='text-[#929292] text-[13px] font-bold'>
						Поставщик:
					</p>
					<p className='text-[#6081F6] text-[13px] font-bold'>
						{vendor_name}
					</p>
				</div>
				<div className='flex justify-between my-[10px]'>
					<p className='text-[#929292] text-[13px] font-bold'>
						Товаров:
					</p>
					<p className='text-[#6081F6] text-[13px] font-bold'>
						{count} шт.
					</p>
				</div>
				<p className='text-[#A4B4DE] text-[13px] font-bold'>
					<span className='text-[#4E6DC0] text-[13px] font-bold'>
						итого: {sum_price}P
					</span>{' '}
					UZS
				</p>
			</div>

			<h2 className='text-base font-semibold text-[#414141]'>
				Документы
			</h2>
			<div className='pt-2.5'>
				{documentTypes.map((item) => {
					if (item.required_stage === typeId && item.is_required) {
						const attachedDocument =
							documets.findIndex(
								(document) => document.type === item.id
							) >= 0;
						return (
							<div className='flex mt-2.5'>
								<div>
									{attachedDocument ? (
										<img
											src={doneStatus}
											alt='close button'
										/>
									) : (
										<img
											src={closeBtn}
											alt='close button'
										/>
									)}
								</div>
								<p className='ml-[15px]'>{item.name}</p>
							</div>
						);
					}
					return <></>;
				})}
			</div>

			<div className='my-[25px]'>
				<div className='flex items-center justify-center'>
					<div
						className={`${data.current.style} text-[#FFF] py-[9px] px-[32px] rounded-[5px] mr-[5px] cursor-not-allowed`}>
						{data.current.content}
					</div>
					<div>
						<img src={rightMove} alt='change order status' />
					</div>
					<div
						className={`${data.next.style} text-[#FFF] py-[9px] px-[32px] rounded-[5px] ml-[5px]`}>
						{data.next.content}
					</div>
				</div>
			</div>

			<div className='flex justify-end'>
				<button
					onClick={closeModals}
					type='button'
					className='bg-[#FF5454] text-[#FFF] py-[8px] px-[25px] rounded-[5px] mr-[10px]'>
					Отмена
				</button>
				<button
					disabled={!letMove}
					type='button'
					onClick={moveShipping}
					className='bg-[#4E89FD] text-[#FFF] py-[8px] px-[40px] rounded-[5px] disabled:opacity-80'>
					Да
				</button>
			</div>
		</ModalWindowWithForm>
	);
};

export default MoveLogisticsOrder;
