import axios from "axios";
import { URL } from "../utils/constants";


export const getCurrentRate = async (
    token,
    props
) => {
    try {


        const { data } = await axios.get(`${URL}/adminpanel/course/get_current_rate/`, {
            headers: {
                Authorization: `Token ${token}`
            }

        });
        props?.(data);

    } catch (error) {
        console.error('Error:', error);
        props?.(null)
    }
};