import { create } from 'zustand';

export const createCommonStore = (getData) => {
	return create((set, get) => ({
		data: undefined,
		isLoading: false,
		loadData(token) {
			if (get().isLoading) return;
			set({ isLoading: true });
			getData(token, (data) => {
				set({ data: data, isLoading: false });
			});
		},
	}));
};
