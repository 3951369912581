import React from "react";
import { useUsersStore } from "../../store/users";
import { useNavigate } from "react-router-dom";
import Main from "../../components/Main";
import CatalogList from "../../components/Catalog/CatalogList";
import { ReactComponent as CreditCard } from "../../images/credit_card.svg";
import { ReactComponent as Archive } from "../../images/Archive.svg";
import { ReactComponent as CloseBlue } from "../../images/closeRing.svg";
import { ReactComponent as CloseWhite } from "../../images/Close_round_white.svg";
import { ReactComponent as DoneStatusNoActive } from "../../images/Done_round_blue.svg";
import { ReactComponent as DoneStatusActive } from "../../images/Done_round_white.svg";
import { ReactComponent as CopyBlue } from "../../images/Copy_light_blue.svg";
import linkVendorCode from "../../images/link.svg";

const Catalog = () => {
  const { data, isLoading } = useUsersStore();
  const [isNothingFound, setIsNothingFound] = React.useState(false);

  const testFunc = () => {
    const sorts = [
      { id: "111", title: "Наименование", value: false, target: "name" },
      { id: "222", title: "Цена", value: false, target: "price" },
    ];
    const filters = {
      minMax: [
        {
          id: "1111",
          type: "number",
          title: "Ценa",
          icon: <CreditCard />,
          target: "attr_count",
          value: {},
        },
      ],
      trueFalseBoth: [
        {
          id: "444",
          title: "Товары в архиве",
          icon: <Archive />,
          target: "status",
          values: [
            { lable: <DoneStatusNoActive />, lableActive: <DoneStatusActive />, value: 1 },
            { lable: <CloseBlue />, lableActive: <CloseWhite />, value: 2 },
            { lable: <CopyBlue />, lableActive: <CopyBlue />, value: -0 },
          ],
        },
      ],
    };

    const setSorts = () => {};
    const setFilters = () => {};
    const setPageSize = () => {};
    const loadDataList = () => {};
    const updateDataList = () => {};

    const isLoading = false;

    return { filters, sorts, isLoading, setSorts, setFilters, setPageSize, loadDataList, updateDataList };
  };
  const navigate = useNavigate();

  const rederectCreateProduct = () => {
    navigate("/adminpanel/create_prodact/");
  };

  const artTest = () => {
    return (
      <>
        <div className="flex justify-between">
          <div>
            <div className="max-w-[24px] max-h-[24px] w-full h-full">
              <img src={linkVendorCode} alt="icon vendor code" />
            </div>
            <p className="text-[#007AFF] text-base font-semibold leading-[17px]">Артикул</p>
          </div>
          <div>
            <p className="text-[#000000] text-base font-semibold leading-[17px]">Jfjs362302-wekf93</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <Main
      isInteractionPanel={true}
      paginationStore={testFunc}
      element={
        <>
          <CatalogList />
        </>
      }
      createButton={true}
      isFilterButton={true}
      handleOpenCreateModals={rederectCreateProduct}
      title="Каталог"
      placeholderText="Название товара"
    />
  );
};

export default Catalog;
