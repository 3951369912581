import React from 'react';

function LogistCard({
	type,
	moveRight,
	moveLeft,
	openEditModal,
	data: {
		invoice_str,
		pay_date,
		vendor_name,
		count,
		sum_price,
		back_stage_response,
	},
}) {
	const handleClickButtonMoveRight = (e) => {
		e.stopPropagation();
		moveRight();
	};

	const handleClickButtonMoveLeft = (e) => {
		e.stopPropagation();
		moveLeft();
	};

	return (
		<article
			className={`${
				type === 'conversion'
					? 'logist__card_type_conversion'
					: type === 'on-road'
					? 'logist__card_type_on-road'
					: type === 'on-customs'
					? 'logist__card_type_on-customs'
					: type === 'customs-cleared'
					? 'logist__card_type_customs-cleared'
					: ''
			}
                            ${
								back_stage_response
									? 'bg-[#2886EB] bg-opacity-[15%] text-[#2886EB] '
									: 'text-[#929292]'
							} 
                            logist__card cursor-pointer`}
			onClick={openEditModal}>
			<div className='logist__flex-wrapper'>
				<p className='logist__card-key text-inherit'>
					Инвойс № {invoice_str}
				</p>
				<p className='logist__card-value'>{pay_date}</p>
			</div>
			<div className='logist__flex-wrapper'>
				<p className='logist__card-key text-inherit'>Поставщик:</p>
				<p className='logist__card-value'>{vendor_name}</p>
			</div>
			<div className='logist__flex-wrapper'>
				<p className='logist__card-key text-inherit'>Товаров:</p>
				<p className='logist__card-value'>{count}</p>
			</div>
			<div className='logist__flex-wrapper'>
				<p className='logist__total'>итого: {sum_price}</p>
				<div className='logist__buttons-container'>
					<button
						disabled={back_stage_response}
						onClick={handleClickButtonMoveLeft}
						className={`${
							type === 'conversion'
								? 'logist__move-button_hidden'
								: ''
						} logist__move-button zero-button`}></button>
					<button
						disabled={back_stage_response}
						onClick={handleClickButtonMoveRight}
						className={`${
							type === 'customs-cleared'
								? 'logist__move-button_hidden'
								: ''
						} logist__move-button zero-button`}></button>
				</div>
			</div>
		</article>
	);
}

export default LogistCard;
