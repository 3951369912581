import React, { useEffect } from "react";
import ModalWindowWithForm from "../ModalWindowWithForm";
import { useForm } from "react-hook-form";
import { updateBrand } from "../../service/brand";
import { useUserStore } from "../../store/user";
import { useBrandStore } from "../../store/brand";

const EditStorekeeperModal = ({ title, name, modalWidth, isOpen, handleCloseAllModals, initialData }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const { token } = useUserStore();
  const { updateDataList } = useBrandStore();

  const onSubmit = ({ name }) => {
    // if (!!token) {
    //   updateBrand(token, initialData.id, name, (result) => {
    //     if (!!result) {
    //       handleCloseAllModals();
    //       updateDataList(token);
    //     }
    //   });
    // }
  };

//   useEffect(() => {
//     if (!!initialData) {
//       setValue("name", initialData.name);
//     }
//   }, [initialData]);
  return (
    <ModalWindowWithForm
      resetForm={reset}
      onSubmit={handleSubmit(onSubmit)}
      title={title}
      name={name}
      modalWidth={modalWidth}
      isOpen={isOpen}
      handleCloseAllModals={handleCloseAllModals}
    >
      <div className="attributes-modal">
        <div className="attributes-modal__container ">
          <label className="attributes-modal__field-name">Склад</label>
          <input
            placeholder="Склад"
            className="attributes-modal__input zero-input"
            name={`${name}-input-name`}
            {...register("name", { required: true })}
          />
        </div>
      </div>
      <div className="attributes-modal__flex-wrapper">
        <button
          type="button"
          className="cansel-button zero-button bg-[#13131408]"
          onClick={() => handleCloseAllModals((state) => (state = !isOpen))}
        >
          Отмена
        </button>
        <button type="submit" className="confirm-button zero-button">
          Сохранить
        </button>
      </div>
    </ModalWindowWithForm>
  );
};

export default EditStorekeeperModal;
