import React, { useEffect } from "react";
import Page from "./Page";
import { useUserStore } from "../store/user";

function App() {
    const [isCategorysOpen, setCategorysOpen] = React.useState(false);
    const { checkLocalToken } = useUserStore()

    useEffect(() => {
        checkLocalToken()
    }, [])
    const toggleCategorysMenu = () => {
        setCategorysOpen(!isCategorysOpen);
    }

    const closeCategorysMenu = () => {
        setCategorysOpen(false);
    }
    // Если оставлять обертку с "NavMenu" и "Categorys", то отображается боковая панель, а так же header уже в самом "Page"
    return (
        <Page />
    );
};

export default App;
