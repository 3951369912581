import { useEffect, useState } from 'react';
import LogistCard from '../components/LogistCard';
import ModalCreatingLogisticsOrder from '../components/ModalCreatingLogisticsOrder';
import EditLogistModal from '../components/modals/EditLogistModal';
import MoveLogisticsOrder from '../components/modals/MoveLogisticsOrder';
import RequestMoveLogisticsOrder from '../components/modals/RequestMoveLogisticsOrder';
import { useLogistShippingStore } from '../store/logistShipping';
import Main from '../components/Main';
import Search from '../components/Search';
import Select from '../components/UI/Select';
import { getUserRights } from '../service/userRight';
import { useUserStore } from '../store/user';
import { getUserListByRole } from '../service/users';

function Logist() {
	const {
		data: {
			converting,
			in_delivery: onRoad,
			at_customs: onCustoms,
			customs_complete: customsCleared,
		},
		setFilters,
	} = useLogistShippingStore();
	const { token } = useUserStore();
	const [targetItem, setTargetItem] = useState();
	const [search, setSearch] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [logists, setLogists] = useState(null);
	const [targetLogist, setTargetLogist] = useState({
		id: 0,
		name: 'Все логисты',
	});

	const [isModalCreatingOrderOpen, setModalCreatingOrderOpen] =
		useState(false);
	const [isModalEditOrderOpen, setModalEditOrderOpen] = useState(false);
	const [isMoveLogisticsOrderOpen, setMoveLogisticsOrderOpen] =
		useState(false);
	const [isRequestMoveLogisticsOrderOpen, setRequestMoveLogisticsOrderOpen] =
		useState(false);

	useEffect(() => {
		if (token && !isLoading && !logists) {
			setIsLoading(true);
			getUserListByRole(token, 'logist', (result) => {
				if (result) {
					const options = [
						{ id: 0, name: 'Все логисты' },
						...result.map((item) => {
							const { id, first_name, middle_name, last_name } =
								item;
							return {
								id,
								name: first_name
									? `${first_name} ${
											middle_name || ''
									  }${last_name}`
									: `Логист ${id}`,
							};
						}),
					];
					setLogists(options);
				}
				setIsLoading(false);
			});
		}
	}, [token]);

	useEffect(() => {
		if (targetLogist.id === 0) {
			setFilters({});
		} else {
			const { id } = targetLogist;
			setFilters({
				user_id: {
					value: id,
					type: 'f_int',
				},
			});
		}
	}, [targetLogist]);

	const setEditItem = (data) => {
		return () => {
			console.log(data);
			setTargetItem(data);
			setModalEditOrderOpen(true);
		};
	};

	const closeModals = () => {
		setModalCreatingOrderOpen(false);
		setModalEditOrderOpen(false);
		setMoveLogisticsOrderOpen(false);
		setRequestMoveLogisticsOrderOpen(false);
	};
	const moveRight = (data) => () => {
		setMoveLogisticsOrderOpen(true);
		setTargetItem(data);
	};

	const moveLeft = (data) => () => {
		setRequestMoveLogisticsOrderOpen(true);
		setTargetItem(data);
	};

	return (
		<Main
			isInteractionPanel={false}
			paginationStore={useLogistShippingStore}
			modals={
				<>
					<ModalCreatingLogisticsOrder
						closeModals={closeModals}
						isOpen={isModalCreatingOrderOpen}
					/>
					{targetItem && (
						<>
							<EditLogistModal
								target={targetItem}
								closeModals={closeModals}
								isOpen={isModalEditOrderOpen}
							/>
							<MoveLogisticsOrder
								target={targetItem}
								closeModals={closeModals}
								isOpen={isMoveLogisticsOrderOpen}
							/>
							<RequestMoveLogisticsOrder
								target={targetItem}
								closeModals={closeModals}
								isOpen={isRequestMoveLogisticsOrderOpen}
							/>
						</>
					)}
				</>
			}
			element={
				<div>
					<div className='interaction'>
						<Search
							placeholderText='Номер инвойса или бренд'
							searchValue={search}
							setSearchValue={setSearch}
						/>
						<Select
							options={logists}
							targetOptionst={targetLogist}
							setTargetOptions={setTargetLogist}
						/>
					</div>

					<section className='logist'>
						<div className='logist__column'>
							<h2 className='logist__column-name logist__column-name_type_conversion'>
								конвертация ({converting?.count})
							</h2>
							<button
								className='logist__new-oreder zero-button'
								onClick={() =>
									setModalCreatingOrderOpen(true)
								}></button>
							<div className='logist__cards-container'>
								{converting?.results.map((item) => {
									if (
										search &&
										!item.invoice_str
											.toLowerCase()
											.includes(search) &&
										!item.vendor_name
											.toLowerCase()
											.includes(search)
									) {
										return <></>;
									}
									const itemWithType = {
										...item,
										type: 'conversion',
										typeId: 0,
									};

									return (
										<LogistCard
											key={item.id}
											moveLeft={moveLeft(itemWithType)}
											moveRight={moveRight(itemWithType)}
											openEditModal={setEditItem(
												itemWithType
											)}
											data={itemWithType}
											type='conversion'
										/>
									);
								})}
							</div>
						</div>
						<div className='logist__column'>
							<h2 className='logist__column-name logist__column-name_type_on-road'>
								в пути ({onRoad?.count})
							</h2>
							<p className='logist__all-price'>
								{onRoad?.global_sum} $
							</p>
							<div className='logist__cards-container'>
								{onRoad?.results.map((item) => {
									if (
										search &&
										!item.invoice_str
											.toLowerCase()
											.includes(search) &&
										!item.vendor_name
											.toLowerCase()
											.includes(search)
									) {
										return <></>;
									}

									const itemWithType = {
										...item,
										type: 'on-road',
										typeId: 1,
									};

									return (
										<LogistCard
											key={item.id}
											moveLeft={moveLeft(itemWithType)}
											moveRight={moveRight(itemWithType)}
											openEditModal={setEditItem(
												itemWithType
											)}
											data={itemWithType}
											type='on-road'
										/>
									);
								})}
							</div>
						</div>
						<div className='logist__column'>
							<h2 className='logist__column-name logist__column-name_type_on-customs'>
								на таможне ({onCustoms?.count})
							</h2>
							<p className='logist__all-price'>
								{onCustoms?.global_sum} $
							</p>
							<div className='logist__cards-container'>
								{onCustoms?.results.map((item) => {
									if (
										search &&
										!item.invoice_str
											.toLowerCase()
											.includes(search) &&
										!item.vendor_name
											.toLowerCase()
											.includes(search)
									) {
										return <></>;
									}

									const itemWithType = {
										...item,
										type: 'on-customs',
										typeId: 2,
									};

									return (
										<LogistCard
											key={item.id}
											moveLeft={moveLeft(itemWithType)}
											moveRight={moveRight(itemWithType)}
											openEditModal={setEditItem(
												itemWithType
											)}
											data={itemWithType}
											type='on-customs'
										/>
									);
								})}
							</div>
						</div>
						<div className='logist__column'>
							<h2 className='logist__column-name logist__column-name_type_customs-cleared'>
								товар растаможен ({customsCleared?.count})
							</h2>
							<p className='logist__all-price'>
								{customsCleared?.global_sum} $
							</p>
							<div className='logist__cards-container'>
								{customsCleared?.results.map((item) => {
									if (
										search &&
										!item.invoice_str
											.toLowerCase()
											.includes(search) &&
										!item.vendor_name
											.toLowerCase()
											.includes(search)
									) {
										return <></>;
									}

									const itemWithType = {
										...item,
										type: 'customs-cleared',
										typeId: 3,
									};

									return (
										<LogistCard
											key={item.id}
											moveLeft={moveLeft(itemWithType)}
											moveRight={moveRight(itemWithType)}
											openEditModal={setEditItem(
												itemWithType
											)}
											data={itemWithType}
											type='customs-cleared'
										/>
									);
								})}
							</div>
						</div>
					</section>
				</div>
			}
			title='Логистика'
		/>
	);
}

export default Logist;
