import { createPaginationStore } from '../common/createPaginationStore';
import { getAttributeCategoryList } from '../service/attributeCategory';
import {
	getProductCategoryList,
	getProductCategories,
} from '../service/productCategory';

export const useProductCategoryStore = createPaginationStore(
	(options, props) => {
		const { token, pageSize, targetPage, search, sorts, filters } = options;
		getProductCategoryList(
			token,
			pageSize,
			targetPage,
			search,
			sorts,
			filters,
			(attributeCategories, totalAttributeCategories, maxPages) => {
				if (
					attributeCategories &&
					typeof totalAttributeCategories === 'number' &&
					maxPages
				) {
					props(
						attributeCategories,
						totalAttributeCategories,
						maxPages
					);
				}
			}
		);
	},
	10,
	[
		{ id: '111', title: '№', value: false, target: 'id' },
		{ id: '333', title: 'Категория', value: false, target: 'name' },
		{ id: '222', title: 'Род категория', value: false, target: 'parent' },
		// { id: '444', title: 'Фото', value: false, target: 'image' },
	],
	{
		trueFalseBoth: [
			{
				id: '1111',
				title: 'Фото',
				target: 'image',
				values: [
					{ lable: '+', value: true },
					{ lable: '-', value: false },
					{ lable: '+-', value: undefined },
				],
			},
		],
	}
);
