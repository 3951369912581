import React from "react";

function Search({
    placeholderText,
    searchValue = '',
    setSearchValue = () => { }
}) {

    const handleChange = (evt) => {
        setSearchValue(evt.target.value)
    }

    const handleClick = () => {
        setSearchValue('');
    }

    return (
        <div className='interaction__search'>
            <button className='interaction__search-button zero-button'></button>
            <input className='interaction__search-line' id='search' onChange={handleChange} value={searchValue} placeholder={placeholderText}></input>
            <button className="interaction__close-button zero-button" onClick={handleClick}></button>
        </div>
    )
};

export default Search;
