



export const convertSortListForServer = (sorts) => {
    const result = sorts.reduce((value, item, index) => {
        const sorter = { type: 'asc_desc', value: '' + item.value, pose: index + 1 }
        return { ...value, [item.target]: sorter }
    }, {})
    return result;
}