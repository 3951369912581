import MikroTik from "../images/mikrotik.png";
import CartsCheckBox from "./CartsCheckBox";
import CounterCart from "./CounterCart";
import CustomSelect from "./CustomSelect";

function CartItem(props) {
  return (
    <article className="cart-item">
      <CartsCheckBox idName={props.idName}/>
      <img src={MikroTik} alt="k" className="cart-item__image"></img>
        <div className="cart-item__flex-column-wrapper">
        <div className="cart-item__container-grow">         
          <p className="cart-item__name">Mikrotik hAP ac²</p>
          <p className="cart-item__id">1823843umksdmnvf</p>
          <p className="cart-item__caption">MikroTik</p>
        </div>
        <button className="cart-item__delete-button zero-button">Удалить</button>
      </div>
      <div className="cart-item__container">
        <div className="cart-item__flex-wrapper">
          <p className="cart-item__selected-positions">Выбранные позиции:</p>
          <p className="cart-item__total-price">35560₽ за 6 товаров</p>
          <p className="cart-item__cost">5 926 ₽</p>
        </div>
        <div className="cart-item__flex-wrapper">
          <p className="cart-item__counter-name">Основные склады</p>
          <CounterCart />
        </div>
        <div className="cart-item__flex-wrapper">
          <p className="cart-item__counter-name">Партнерские склады</p>
          <CounterCart />
        </div>
        <div className="cart-item__flex-wrapper">
          <p className="cart-item__counter-name">Основные склады</p>
          <CounterCart />
        </div>
        <div className="cart-item__flex-wrapper">
          <CustomSelect />
          <button className="cart-item__create-button zero-button">Добавить</button>
        </div>
      </div>
    </article>
  )
};

export default CartItem;
