import { useEffect } from "react";
import { useForm } from "react-hook-form";

function InputsFilterPopup({
    subtitle,
    icon,
    setValueFilter
}) {
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useForm({ values: { min: '', max: '' } })
    const { min, max } = watch();

    useEffect(() => {
        setValueFilter({
            min,
            max
        })
        console.log(min, max)
    }, [min, max])




    return (
        <div className="filter-popup__inputs">
            <h2 className="filter-popup__subtitle flex gap-2.5">{icon} {subtitle}</h2>
            <div className="filter-popup__flex-wrapper">
                <div className="filter-popup__input">
                    <input {...register("min")} className="filter-popup__input-line" placeholder="От"></input>
                    {min && <button onClick={() => setValue('min', '')} className="zero-button filter-popup__input-close-button"></button>}
                </div>
                <div className="filter-popup__input">
                    <input {...register("max")} className="filter-popup__input-line" placeholder="До" ></input>
                    {max && <button onClick={() => setValue('max', '')} className="zero-button filter-popup__input-close-button">-</button>}
                </div>
            </div>
        </div>
    )
};

export default InputsFilterPopup;

