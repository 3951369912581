function CartsCheckBox(props) {
  return (
    <div className="cart-check-box">
      <input className="cart-check-box__input" type="checkbox" id={props.idName}></input>
      <label className="cart-check-box__label" htmlFor={props.idName}></label>
    </div>
  )
};

export default CartsCheckBox;
