import { useEffect, useState } from 'react';
import Main from '../../components/Main';
import Table from '../../components/Table';
import { useAttributeCategoryStore } from '../../store/attributeCategory';
import { useUserStore } from '../../store/user';
import AttributeCategoryModal from '../../components/modals/AttributeCategoryModal';

export default function AttributeCategories() {
	const { data, loadDataList, isLoading } = useAttributeCategoryStore();
	const { token } = useUserStore();

	const [selectedItemFromTable, setSelectedItemFromTable] = useState([]);
	const [isNothingFound, setIsNothingFound] = useState(false);
	const [isCreateAttributesModalOpen, setIsCreateAttributesModalOpen] =
		useState(false);
	const [isEditAttributesModalOpen, setIsEditAttributesModalOpen] =
		useState(false);

	const titles = [
		{
			title: '№',
			style: '',
		},
		{
			title: 'Категория',
			style: '',
		},
		{
			title: 'Кол-во атрибутов',
			style: '',
		},
	];

	useEffect(() => {
		if (token) {
			loadDataList(token);
		}
	}, [token]);

	useEffect(() => {
		console.log(isLoading);
	}, [isLoading]);

	const handleCloseAllModals = () => {
		setIsCreateAttributesModalOpen(false);
		setIsEditAttributesModalOpen(false);
	};

	const handleOpenTableModals = (data) => {
		setSelectedItemFromTable(data);
		setIsEditAttributesModalOpen(true);
	};

	const handleOpenCreateModals = () => {
		setIsCreateAttributesModalOpen(true);
	};

	return (
		<Main
			paginationStore={useAttributeCategoryStore}
			isInteractionPanel={true}
			modals={
				<>
					<AttributeCategoryModal
						confirmButtonText='Сохранить'
						initialData={selectedItemFromTable}
						handleCloseAllModals={handleCloseAllModals}
						isOpen={isEditAttributesModalOpen}
						title='Редактирование категории атрибутов'
						name='edit-attributes-modal'
						modalWidth={{ width: '500px' }}
					/>{' '}
					<AttributeCategoryModal
						confirmButtonText='Создать'
						handleCloseAllModals={handleCloseAllModals}
						isOpen={isCreateAttributesModalOpen}
						title='Создание новой категории атрибутов'
						name='create-attributes-modal'
						modalWidth={{ width: '500px' }}
					/>
				</>
			}
			element={
				<Table
					handleCloseAllModals={handleCloseAllModals}
					handleOpenTableModals={handleOpenTableModals}
					isNothingFound={isNothingFound}
					tableData={{
						titles,
						content: data,
					}}
					isLoading={isLoading}
					depicter={(item, onClick, specialClass) => {
						// attribute_category: 28
						// data_type: 1
						// id: 1
						// name: "Мощность"
						// unit: "ВТ"
						const { id, name, attr_count } = item;
						return (
							<tr
								onClick={() => {
									setIsEditAttributesModalOpen(true);
									setSelectedItemFromTable(item);
								}}
								className={`table__row ${specialClass}`}>
								<td className='table__content'>{id}</td>
								<td className='table__content'>{name}</td>
								<td className='table__content justify-end'>
									{attr_count}
								</td>
							</tr>
						);
					}}
					specialClass='table__row_grid_attributes-category'
				/>
			}
			isFilterButton={true}
			handleOpenCreateModals={handleOpenCreateModals}
			createButton={true}
			title='Категории атрибутов'
			placeholderText='Наименование'
		/>
	);
}
