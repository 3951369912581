import { createPaginationStore } from '../common/createPaginationStore';
import { getAttributeList } from '../service/attributes';
import { TYPE_ATTRIBUTE_DATA } from '../utils/constants';

export const useAttributeStore = createPaginationStore(
	(options, props) => {
		const { token, pageSize, targetPage, search, sorts, filters } = options;
		getAttributeList(
			token,
			pageSize,
			targetPage,
			search,
			sorts,
			filters,
			(attributes, totalAttributes, maxPages) => {
				if (
					attributes &&
					typeof totalAttributes === 'number' &&
					maxPages
				) {
					props(attributes, totalAttributes, maxPages);
				}
			}
		);
	},
	10,
	[
		{ id: '111', title: '№', value: false, target: 'id' },
		{
			id: '222',
			title: 'Категория',
			value: false,
			target: 'category',
		},
		{ id: '333', title: 'Тип', value: false, target: 'data_type' },
		{ id: '444', title: 'Название', value: false, target: 'name' },
	],
	{
		select: [
			{
				id: '11',
				title: 'Категория',
				target: 'category',
				values: [],
				dataList: [],
			},
			{
				id: '22',
				title: 'Тип',
				target: 'data_type',
				values: [],
				dataList: TYPE_ATTRIBUTE_DATA,
			},
		],
	}
);
