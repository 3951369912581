import React from "react";
import CartInfo from "./CartInfo";
import CartItem from "./CartItem";
import CartsCheckBox from "./CartsCheckBox";

function Cart(props) {
  const [cards, setCards] = React.useState([1, 2, 3]);
  
  return (
    <section className="cart">
      <div className="cart__panel">
        <CartsCheckBox idName='all-select' />
        <label className="cart__label">Выбрать все</label>
        <button className="cart__delete-button zero-button">{`Удалить (${cards.length})`}</button>
      </div>
      {cards.map((item, index) => {
        return <CartItem key={index} cards={cards} idName={`cart-${index}`}/>
      })}
    </section>
  )
};

export default Cart;