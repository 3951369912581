import React, { useEffect, useState } from 'react';
import closeBtn from '../images/Close_round_duotone_line.svg';

import ModalWindowWithForm from './ModalWindowWithForm';
import { useForm } from 'react-hook-form';
import Select from './UI/Select';
import { useDecoratedCommonStore } from '../hooks/useDecoratedCommonStore';
import { useCommonProductStore } from '../store/commonProduct';
import { useCommonVendorStore } from '../store/commonVendor';
import { useCommonServiceStore } from '../store/commonService';
import { useUserStore } from '../store/user';
import { createLogistShipping } from '../service/logistShipping';
import { useLogistShippingStore } from '../store/logistShipping';
import {
	attachDocumentsToLogistShipping,
	getLogistShippingDetail,
} from '../service/logistShipping';
import { useCommonDocumentType } from '../store/commonDocumentType';
import LogistFileCard from './LogistFileCard';

const ModalCreatingLogisticsOrder = ({ isOpen, closeModals }) => {
	const { token } = useUserStore();
	const { updateDataList } = useLogistShippingStore();
	const { data: documentTypes } = useDecoratedCommonStore(
		useCommonDocumentType
	);
	const [selectedDocumentType, setSelectedDocumentType] = useState();
	const [documentCards, setDocumentCards] = useState([]);
	const [data, setData] = useState();
	const { data: products } = useDecoratedCommonStore(useCommonProductStore);
	const { data: vendors } = useDecoratedCommonStore(useCommonVendorStore);
	const { data: initialServices } = useDecoratedCommonStore(
		useCommonServiceStore
	);
	const [services, setServices] = useState([]);
	const [addedProducts, setAddedProducts] = useState([]);
	const [countProducts, setCountProducts] = useState(0);
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		formState: { errors },
	} = useForm();

	const { vendor, product, productCost } = watch();
	useEffect(() => console.log(errors), [errors]);

	const onSubmit = (data) => {
		if (!token) return;
		const { invoice, vendor, date } = data;
		const convertedProducts = addedProducts.map((item) => {
			const { product, count, cost } = item;
			return {
				unique_product: product,
				count,
				price_usd: cost,
			};
		});
		const convertedServices = services.map((item) => {
			const { id, cost } = item;
			return {
				type: id,
				price_usd: cost,
			};
		});
		createLogistShipping(
			token,
			invoice,
			vendor.id,
			date,
			1,
			1,
			convertedProducts,
			convertedServices,
			(result) => {
				console.log(result);
				if (result) {
					saveFiles(result.id);
					closeModals();
				}
				updateDataList(token);
			}
		);
	};

	useEffect(() => {
		if (initialServices && !services.length) {
			setServices(
				initialServices.map((item) => {
					return { ...item, cost: undefined };
				})
			);
		}
	}, [initialServices]);

	const calculationResults = () => {
		let result = 0;
		addedProducts.forEach((item) => {
			const { count, cost } = item;
			result += Number(count) * Number(cost);
		});
		services.forEach((item) => {
			const { cost } = item;
			result += Number(cost);
		});
		return result;
	};

	const setCostService = (id, cost) => {
		setServices(
			services.map((item) => {
				if (item.id !== id) return item;
				return { ...item, cost };
			})
		);
	};

	const addProduct = () => {
		if (!product || !productCost || !countProducts) return;
		const { id, name } = product;
		setAddedProducts([
			...addedProducts,
			{
				name,
				product: id,
				id: `${id}-${Date()}`,
				count: countProducts,
				cost: productCost,
			},
		]);
		setCountProducts(0);
		setValue('product', undefined);
		setValue('productCost', null);
	};

	const deleteProduct = (targetId) => {
		setAddedProducts(addedProducts.filter((item) => item.id !== targetId));
	};

	const saveFiles = (id) => {
		if (token && documentCards.length) {
			attachDocumentsToLogistShipping(
				token,
				id,
				documentCards,
				(result) => {
					getLogistShippingDetail(token, id, (result) => {
						if (result) {
							setData(result);
							setDocumentCards([]);
						}
					});
				}
			);
		}
	};

	const addFileInCard = (id, file) => {
		setDocumentCards(
			documentCards.map((item) => {
				if (item.id !== id) return item;
				return {
					...item,
					file,
				};
			})
		);
	};

	const addFileCard = () => {
		//    {
		//     "id": 1,
		//     "name": "Документ Уникальный",
		//     "is_required": false,
		//     "required_stage": 2,
		//     "show_always": true,
		//     "unique": true
		//   },

		const isAlreadyExists =
			documentCards.findIndex(
				(item) => item.id === selectedDocumentType.id
			) >= 0;

		if (!selectedDocumentType || isAlreadyExists) return;

		const newCard = {
			...selectedDocumentType,
			file: undefined,
		};
		setDocumentCards([...documentCards, newCard]);
		setSelectedDocumentType(undefined);
	};

	const deleteFileCard = (targetId) => {
		setDocumentCards(documentCards.filter((item) => item.id !== targetId));
		setSelectedDocumentType(undefined);
	};

	return (
		<ModalWindowWithForm
			resetForm={reset}
			onSubmit={handleSubmit(onSubmit)}
			title='Создание заказа'
			name='create-logist-modal'
			modalWidth={{ width: '612px' }}
			isOpen={isOpen}
			handleCloseAllModals={closeModals}>
			<div className=''>
				<div
					className='modal__info-order'
					style={{ marginBottom: '35px' }}>
					<div className='box' style={{ marginRight: '35px' }}>
						<label className='modal__headline-overall'>
							Инвойс
						</label>
						<input
							id='invoice-input'
							{...register('invoice', { required: true })}
							placeholder='№'
							className='modal__input-overall'
						/>
					</div>
					<div className='box'>
						<label className='modal__headline-overall'>
							Дата доставки
						</label>
						<input
							id='date-input'
							{...register('date', { required: true })}
							className='modal__input-overall'
							type='date'
						/>
					</div>
				</div>
				<div>
					<h2 className='modal__headline-overall'>Поставщик</h2>
					<div className='max-w-[10rem]'>
						<Select
							id='vendor-select'
							options={vendors}
							targetOptionst={vendor}
							setTargetOptions={(value) =>
								setValue('vendor', {
									id: value.id,
									name: value.name,
								})
							}
							placeholder='Поставщик'
						/>
					</div>
				</div>

				<div className='modal__add-product pt-9'>
					<h2 className='modal__headline-overall'>
						Добавление товара
					</h2>
					<form
						onSubmit={(e) => e.preventDefault()}
						className='modal__add-product-box'>
						<Select
							id='product-select'
							options={products?.map((item) => {
								return {
									...item,
									name: `${item.name} ${item.id}`,
								};
							})}
							targetOptionst={product}
							setTargetOptions={(value) =>
								setValue('product', {
									id: value.id,
									name: value.name,
								})
							}
							placeholder='Товар'
						/>

						<div className='stepper h-[37px] w-[120px]'>
							<button
								onClick={(e) => {
									e.preventDefault();
									setCountProducts(countProducts - 1);
								}}
								disabled={countProducts <= 0}
								className=' h-full cursor-pointer '>
								<div className='stepper__btn--down'></div>
							</button>
							<div className='stepper__input'>
								<input
									id='product-count-input'
									value={countProducts}
									onChange={(e) =>
										setCountProducts(e.target.value)
									}
									placeholder='0'
									type='number'
									maxLength='3'
									className='w-[20px] !appearance-none !m-0'
								/>
								<span>шт.</span>
							</div>
							<button
								onClick={(e) => {
									e.preventDefault();
									setCountProducts(countProducts + 1);
								}}
								disabled={countProducts >= 999}
								className=' h-full cursor-pointer'>
								<div className='stepper__btn--up'></div>
							</button>
						</div>

						<div className='box-input-price h-[37px]'>
							<input
								{...register('productCost', { min: 0 })}
								id='product-cost-input'
								type='number'
								min={0}
								placeholder='Цена/шт.'
								className='modal__input-price  h-[37px]'
							/>
						</div>

						<button
							onClick={addProduct}
							type='button'
							className='add_product-disabled cursor-pointer bg-[#007AFF] h-[37px] w-[125px]'>
							Добавить
						</button>
					</form>
				</div>

				<div className='modal__list-product'>
					<h2 className='modal__headline-overall'>Товары</h2>

					<div className='flex flex-col mt-[10px]'>
						{addedProducts.map((item) => {
							const { name, id, count, cost } = item;
							return (
								<div
									key={name + count + id}
									className='flex justify-between items-center mb-[10px] pl-2.5'>
									<div className='text-[#6F6F6F] text-base font-bold mr-[20px]'>
										{name}
									</div>
									<div className='flex items-center'>
										<div className='text-[#ADADAD] text-base font-bold mr-[20px]'>
											{count} шт
										</div>
										<div className='text-[#ADADAD] text-base font-bold mr-[20px]'>
											{cost}$
										</div>
										<div className='text-[#6F6F6F] text-base font-bold mr-[20px]'>
											{count * cost}$
										</div>
										<div className='flex'>
											<button
												type='button'
												onClick={() =>
													deleteProduct(id)
												}>
												<img
													src={closeBtn}
													alt='close button'
												/>
											</button>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>

				<div className='modal__services-box'>
					<h2 className='modal__headline-overall'>Услуги</h2>
					{services.map((item) => {
						const { name, id, cost } = item;
						return (
							<div
								key={name + id}
								className='service-box items-baseline pl-2.5'>
								<label className='modal__service-info'>
									{name}
								</label>
								<input
									id={`service-${id}-input`}
									{...register(`service-${id}`, {
										required: true,
									})}
									onChange={(e) =>
										setCostService(id, e.target.value)
									}
									value={cost}
									type='number'
									min={0}
									placeholder='Цена'
									className='modal__input-price'
								/>
							</div>
						);
					})}

					{/* <div className="service-box">
                        <label className="modal__service-info">Доставка</label>
                        <input type="text" min={0} placeholder="Цена" className="modal__input-price" />
                    </div> */}
				</div>

				<div className='modal__last-price'>
					Итого: {calculationResults() || 0} $
				</div>

				<div className='modal__document-box'>
					<h2 className='modal__headline-overall'>Документы</h2>

					<div className='box-document'>
						<div className='box-document-add gap-2'>
							{/* <div
								className='box-document-button'
								onClick={() =>
									setDocumentListOpen((state) => !state)
								}>
								<div className='document-btn'>
									Выбрать документ
								</div>

								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='19'
									height='19'
									viewBox='0 0 19 19'
									fill='none'>
									<path
										d='M8.8636 13.1364C9.21508 13.4879 9.78492 13.4879 10.1364 13.1364L15.864 7.40883C16.2154 7.05736 16.2154 6.48751 15.864 6.13604C15.5125 5.78457 14.9426 5.78457 14.5912 6.13604L9.5 11.2272L4.40883 6.13604C4.05736 5.78457 3.48751 5.78457 3.13604 6.13604C2.78457 6.48751 2.78457 7.05736 3.13604 7.40883L8.8636 13.1364ZM8.6 11.5L8.6 12.5L10.4 12.5L10.4 11.5L8.6 11.5Z'
										fill='#B5B5B5'
									/>
								</svg>
							</div>
							{isDocumentListOpen && (
								<div className='document-options max-h-[140px]'>
									<ul className='select-document-list'>
										<li className='select-item-document'>
											Инвойс
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='14'
												height='14'
												viewBox='0 0 14 14'
												fill='none'>
												<path
													d='M7.16667 0.5C3.48667 0.5 0.5 3.48667 0.5 7.16667C0.5 10.8467 3.48667 13.8333 7.16667 13.8333C10.8467 13.8333 13.8333 10.8467 13.8333 7.16667C13.8333 3.48667 10.8467 0.5 7.16667 0.5ZM7.83333 10.5H6.5V9.16667H7.83333V10.5ZM7.83333 7.83333H6.5V3.83333H7.83333V7.83333Z'
													fill='#FF3E3E'
												/>
											</svg>
										</li>
										<li className='select-item-document'>
											Экспортная декларация
										</li>
										<li className='select-item-document'>
											Сертификат происхождения
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='14'
												height='14'
												viewBox='0 0 14 14'
												fill='none'>
												<path
													d='M7.16667 0.5C3.48667 0.5 0.5 3.48667 0.5 7.16667C0.5 10.8467 3.48667 13.8333 7.16667 13.8333C10.8467 13.8333 13.8333 10.8467 13.8333 7.16667C13.8333 3.48667 10.8467 0.5 7.16667 0.5ZM7.83333 10.5H6.5V9.16667H7.83333V10.5ZM7.83333 7.83333H6.5V3.83333H7.83333V7.83333Z'
													fill='#FF3E3E'
												/>
											</svg>
										</li>
										<li className='select-item-document'>
											Таможенная пошлина
										</li>
									</ul>
								</div>
							)} */}
							<Select
								// id='attribute-category-select'
								withSearch={false}
								filter={(option) => {
									const isAlreadyExists =
										documentCards.findIndex(
											(card) => card.id === option.id
										) >= 0 ||
										data?.documents.findIndex(
											(file) => file.type === option.id
										) >= 0;
									if (option.unique && isAlreadyExists) {
										return false;
									}
									return true;
								}}
								customOption={(
									option,
									toggleIsOpen,
									targetOptionst,
									setTargetOptions
								) => {
									return (
										<li
											className='py-[8px] px-[10px] cursor-pointer leading-4 tracking-tighter'
											key={option.id}
											onClick={() => {
												toggleIsOpen();
												if (
													targetOptionst?.id !==
													option?.id
												) {
													setTargetOptions(option);
												}
											}}>
											{option.name}
											{option.required_stage === 0 &&
												option.is_required && (
													<div className=' inline-block px-1'>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='14'
															height='14'
															viewBox='0 0 14 14'
															fill='none'>
															<path
																d='M7.16667 0.5C3.48667 0.5 0.5 3.48667 0.5 7.16667C0.5 10.8467 3.48667 13.8333 7.16667 13.8333C10.8467 13.8333 13.8333 10.8467 13.8333 7.16667C13.8333 3.48667 10.8467 0.5 7.16667 0.5ZM7.83333 10.5H6.5V9.16667H7.83333V10.5ZM7.83333 7.83333H6.5V3.83333H7.83333V7.83333Z'
																fill='#FF3E3E'
															/>
														</svg>
													</div>
												)}
										</li>
									);
								}}
								options={documentTypes}
								targetOptionst={selectedDocumentType}
								setTargetOptions={setSelectedDocumentType}
								placeholder='Выбрать документ'
							/>
							<button
								type='button'
								onClick={addFileCard}
								className='cursor-pointer bg-[#4E89FD] text-[#FFF] py-[6px] px-[30px] rounded-[5px]'>
								Добавить
							</button>
						</div>
					</div>

					<div className='box-added-documents'>
						{data?.documents.map((item) => {
							const {
								file_name,
								file_extension,
								file,
								id,
								type,
							} = item;
							return (
								<LogistFileCard
									key={id}
									attached
									name={`${file_name}.${file_extension}`}
									url={file}
									title={
										documentTypes?.find(
											(item) => item.id === type
										)?.name || 'Неизвестный файл'
									}
								/>
							);
						})}
						{documentCards.map((item) => {
							const { name, id, file } = item;
							return (
								<LogistFileCard
									key={id + name}
									name={file?.name || 'Добавить'}
									title={name}
									deleteCard={() => deleteFileCard(id)}
									addFile={(file) => addFileInCard(id, file)}
								/>
							);
						})}
					</div>
				</div>

				<div className='box-btn'>
					<button
						type='reset'
						className='cancel-modal-btn bg-[#13131408]'
						onClick={closeModals}>
						Отмена
					</button>
					<button
						type='submit'
						className='bg-[#4E89FD] text-[#FFF] py-[8px] px-[32px] rounded-[5px]'>
						Сохранить
					</button>
				</div>
			</div>
		</ModalWindowWithForm>
	);
};

export default ModalCreatingLogisticsOrder;
