import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import SortItem from './SortItem';
import React from 'react';

function FilterPopupSortSection({
    setSorts,
    sorts
}) {
    // const arr = [1, 2, 3, 4]

    //   const [sorts, setSorts] = React.useState(arr)
    const [isDraggable, setIsDraggable] = React.useState(true);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(sorts);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        console.log(result);
        setSorts(items);
    }

    const toggleOrderSorting = (id, value) => {
        const retuslt = sorts.map(item => {
            if (id !== item.id) return item;
            return { ...item, value }
        })
        setSorts(retuslt)
    }

    const handleOnMouseEnter = () => {
        setIsDraggable(false);
    }

    const handleOnMouseLeave = () => {
        setIsDraggable(true);
    }

    return (
        <section className="filter-popup__section">
            <h2 className="filter-popup__title filter-popup__title_type_sorts">Сортировка</h2>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId='sort'>
                    {(provided) => (
                        <div style={{ height: 57 * sorts.length }} {...provided.droppableProps} ref={provided.innerRef}>
                            {sorts?.map((item, index) => {
                                return (
                                    <Draggable isDragDisabled={isDraggable} draggableId={`${item.id}-id`} key={item.id} index={index}>
                                        {(provided) => (
                                            <>
                                                <SortItem
                                                    provided={provided}
                                                    handleOnMouseLeave={handleOnMouseLeave}
                                                    handleOnMouseEnter={handleOnMouseEnter}
                                                    name='number'
                                                    caption={item.title}
                                                    id={item.id}
                                                    value={item.value}
                                                    toggleOrderSorting={toggleOrderSorting}
                                                />
                                                {provided.placeholder}
                                            </>
                                        )}
                                    </Draggable>
                                )
                            }
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </section>
    )
}

export default FilterPopupSortSection;
