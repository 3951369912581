import React from "react";

function CustomSelect(props) {
  const [isListHidden, setIsListHidden] = React.useState(true);
  const [inputValue, setInputValue] = React.useState('');

  const handleOpenList = () => {
    setIsListHidden(false);
  }

  const handleToggleList = () => {
    setIsListHidden(!isListHidden);
  }

  const handleChange = (evt) => {
    setInputValue(evt.target.value);
  }

  const handleClickOption = (evt) => {
    setInputValue(evt.target.textContent);
    setIsListHidden(true);
  }

  return (
    <div className='custom-select' style={{maxWidth: props.size}}>
      <div className="custom-select__flex-wrapper">
        <input onChange={handleChange} value={inputValue} type="text" className='custom-select__input' onFocus={handleOpenList}></input>
        <button onClick={handleToggleList} className={`${isListHidden ? 'custom-select__dropped-list-button_closed' : ''} custom-select__dropped-list-button zero-button`}></button>
      </div>
      <div className={`${isListHidden ? 'custom-select__items_hidden' : ''} custom-select__items`}>
        <p onClick={handleClickOption} className='custom-select__option'>Склад 1</p>
        <p onClick={handleClickOption} className='custom-select__option'>Склад 1</p>
        <p onClick={handleClickOption} className='custom-select__option'>Склад 1</p>
        <p onClick={handleClickOption} className='custom-select__option'>Склад 1</p>
        <p onClick={handleClickOption} className='custom-select__option'>Склад 1</p>
        <p onClick={handleClickOption} className='custom-select__option'>2</p>
      </div>
    </div>
  )
};

export default CustomSelect;
