import React from 'react';
import FilterPopup from './FilterPopup';
import InteractionPanel from './InteractionPanel';
import CartInfo from './CartInfo';
import Pagination from './Pagination';
import Wrapper from './Wrapper';
import { useDecoratedPaginationStore } from '../hooks/usePaginationStoreDecoration';
import { useAttributeStore } from '../store/attribute';

function Main({
    children,
    isCartInfo,
    title,
    isInteractionPanel,
    isFilterButton,
    select,
    placeholderText,
    createButton,
    element,
    modals,
    handleOpenCreateModals,
    isPagination,
    vendorCode,
    paginationStore = useAttributeStore }) {
    const { togglePage } = useDecoratedPaginationStore(paginationStore, 10)
    const { search, setSearch, isLoading, data, maxPages } = paginationStore()
    const [isOpenFilterPopup, setIsOpenFilterPopup] = React.useState(false);
    const [position, setPosition] = React.useState({ x: 0, y: -800 });
    const [isFiltersConfirm, setIsFiltersConfirm] = React.useState(false);
    // const [isSortsConfirm, setIsSortsConfirm] = React.useState(false);
    // const [data, setData] = React.useState(element.props.tableData !== undefined ? element.props.tableData.content.slice(0, 10) : []);


    const handleFiltersConfirm = () => {
        setIsFiltersConfirm(true);
    }

    const handleResetFilters = () => {
        setIsFiltersConfirm(false);
    }

    const toggleFilterPopup = () => {
        setIsOpenFilterPopup(!isOpenFilterPopup);
        setTimeout(() => setPosition({ x: -35, y: 194 }), 200);
    }

    const handleDrag = (evt, position) => {
        setPosition({ x: position.x, y: position.y });
    }

    const handleClose = () => {
        setIsOpenFilterPopup(false);
        setTimeout(() => setPosition({ x: -35, y: 194 }), 200);
    }


    return (
        <Wrapper>
            <main className='main'>
                <h1 className='main__title'>{title}</h1>
                <div className={isCartInfo ? "main__flex-wrapper" : "main__container"}>
                    <div className='main__container'>
                        {isInteractionPanel
                            &&
                            <InteractionPanel
                                isFilterButton={isFilterButton}
                                handleOpenCreateModals={handleOpenCreateModals}
                                select={select}
                                onReset={handleResetFilters}
                                placeholderText={placeholderText}
                                onOpenFilterPopup={toggleFilterPopup}
                                vendorCode={vendorCode}
                                // isResetSortsButton={isSortsConfirm}
                                isResetFiltersButton={isFiltersConfirm}
                                isCreateButton={createButton}
                                searchValue={search}
                                setSearchValue={setSearch}
                            />}
                        {children || element}
                    </div>
                    {isCartInfo && <CartInfo />}
                </div>
                {isLoading ? "" : data?.length !== 0
                    && isPagination !== false
                    && maxPages > 1 &&
                    <Pagination
                        paginationStore={paginationStore}
                        togglePage={togglePage}
                    />}

                {modals}
                <FilterPopup paginationStore={paginationStore} isFiltersConfirm={isFiltersConfirm} onClose={handleClose} onFiltersConfirm={handleFiltersConfirm} isOpenFilterPopup={isOpenFilterPopup} position={position} onDrag={handleDrag} />
            </main>
        </Wrapper>

    )
}

export default Main;

