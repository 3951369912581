import axios from 'axios';
import { convertSortListForServer } from '../utils/convertSortListForServer';
import { convertFiltersForServer } from '../utils/convertFiltersForServer';
import { URL } from '../utils/constants';

export const getUserList = async (
	token,
	perPage,
	page,
	search,
	sorts,
	filtersData,
	props
) => {
	try {
		const sorters = convertSortListForServer(sorts);

		const filters = filtersData && convertFiltersForServer(filtersData);

		const { data } = await axios.post(
			`${URL}/adminpanel/users_crud/smart_list/`,
			{
				page,
				search,
				per_page: perPage,
				sorters,
				filters,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data.results, data.count, data.max_pages);
		return {
			materialList: data.results,
			totalMaterials: data.count,
			maxPages: data.max_pages,
		};
	} catch (error) {
		console.error('Error:', error);
		props?.(null, null, null);
		return null;
	}
};

export const getUserListByRole = async (token, codename, props) => {
	try {
		const { data } = await axios.post(
			`${URL}/role_dynamic_link/get_users_by_role_codename/`,
			{
				codename,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
		return { userList: data.results };
	} catch (error) {
		console.error('Error:', error);
		props?.(null, null, null);
		return null;
	}
};

export const createUser = async (
	token,
	first_name,
	last_name,
	middle_name,
	email,
	password,
	phone_number,
	telegram,
	city,
	company,
	Physical_LegalFace,
	groups,
	props
) => {
	try {
		const { data } = await axios.post(
			`${URL}/adminpanel/users_crud/`,
			{
				first_name,
				last_name,
				middle_name,
				email,
				password,
				phone_number,
				telegram,
				city,
				company,
				Physical_LegalFace,
				groups,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		console.log(data);
		props?.(data);
		return { result: data };
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
		return null;
	}
};

export const updateUserPassword = async (token, id, password, props) => {
	try {
		const { data } = await axios.patch(
			`${URL}/adminpanel/users_crud/${id}/`,
			{
				password,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		console.log(data);
		props?.(data);
		return { result: data };
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
		return null;
	}
};

export const updateUser = async (
	token,
	id,
	first_name,
	last_name,
	middle_name,
	email,
	password,
	phone_number,
	telegram,
	city,
	company,
	Physical_LegalFace,
	groups,
	props
) => {
	try {
		const { data } = await axios.patch(
			`${URL}/adminpanel/users_crud/${id}/`,
			{
				first_name,
				last_name,
				middle_name,
				email,
				password: password || undefined,
				phone_number,
				telegram,
				city,
				company,
				Physical_LegalFace,
				groups,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		console.log(data);
		props?.(data);
		return { result: data };
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
		return null;
	}
};
