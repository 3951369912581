import { createCommonStore } from '../common/createCommonStore';
import { getCompany } from '../service/company';

export const useCommonCompanyStore = createCommonStore((token, props) => {
	getCompany(token, (result) => {
		if (result) {
			props(result);
		}
	});
});
