import {useState} from 'react'
import Main from "../../components/Main";
import Table from "../../components/Table";
import CategoryItemsModalWindow from "../../components/CategoryItemsModalWindow";
import { useProductCategoryStore } from '../../store/productCategory';
import { ReactComponent as FalseStatus } from '../../images/FalseStatus.svg'
import { ReactComponent as TrueStatus } from '../../images/TrueStatus.svg'
import { useDecoratedCommonStore } from '../../hooks/useDecoratedCommonStore';
import { useCommonProductCategoryStore } from '../../store/commonProductCategory';

export default function ProductsCategory() {
    const { isLoading, data } = useProductCategoryStore()
    const {data: productCategories} = useDecoratedCommonStore(useCommonProductCategoryStore)
    const [isNothingFound, setIsNothingFound] = useState(false);
    const [isEditCategoryItemsModalOpen, setIsEditCategoryItemsModalOpen] = useState(false);
    const [isCreateCategoryItemsModalOpen, setIsCreateCategoryItemsModalOpen] = useState(false);
    const [selectedItemFromTable, setSelectedItemFromTable] = useState([]);

    const titles = [
        {
          title: "№",
          style: "",
        },
        {
          title: "Категория",
          style: "",
        },
        {
          title: "Род. категория",
          style: "",
        },
        {
            title: "Фото",
            style: "text-center",
          },
      ];


    const handleOpenCreateModals = () => {
        setIsCreateCategoryItemsModalOpen(true);
    };
        const handleOpenTableModals = (data) => {
        setIsEditCategoryItemsModalOpen(true);
    };

    const handleCloseAllModals = () => {
        setIsEditCategoryItemsModalOpen(false);
        setIsCreateCategoryItemsModalOpen(false);
    };


  return (
    <Main
          isInteractionPanel={true}
          paginationStore={useProductCategoryStore}
        modals={
        <>
            <CategoryItemsModalWindow
                    confirmButtonText="Сохранить"
                    initialData={selectedItemFromTable}
                editData={selectedItemFromTable}
                handleCloseAllModals={handleCloseAllModals}
                isOpen={isEditCategoryItemsModalOpen}
                title="Редактирование категории товаров"
                name="edit-category-items-modal"
                modalWidth={{ width: "500px" }}
            />
            <CategoryItemsModalWindow
                confirmButtonText="Создать"
                handleCloseAllModals={handleCloseAllModals}
                isOpen={isCreateCategoryItemsModalOpen}
                title="Создание категории товаров"
                name="create-category-items-modal"
                modalWidth={{ width: "500px" }}
            />
        </>
        }
        element={
            // <TableProductCategoryList isLoading={isLoading} />
            <Table
                handleCloseAllModals={handleCloseAllModals}
                handleOpenTableModals={handleOpenTableModals}
                isNothingFound={isNothingFound}
                isLoading={isLoading}
                tableData={{
                    titles,
                    content: data,
                }}
                depicter={(item, onClick, specialClass) => {
                        const {id, name, parent, image } = item;
                        return (
                            <tr
                                onClick={() => {
                                    setIsEditCategoryItemsModalOpen(true)
                                    setSelectedItemFromTable(item)
                                }}
                                className={`table__row ${specialClass}`}>
                                <td className="table__content">
                                    {id}
                                </td>
                                <td className="table__content">
                                    { name}
                                </td>
                                <td className="table__content">
                                    {productCategories?.find(item=> item.id === parent)?.name }
                                </td>
                                <td className="table__content justify-center">
                                    {image ? <TrueStatus/> : <FalseStatus/>}
                                </td>
                            </tr>)
                    }}
                specialClass="table__row_grid_products-category"
            />
        }
        isFilterButton={true}
        createButton={true}
        handleOpenCreateModals={handleOpenCreateModals}
        title="Категории товаров"
        placeholderText="Наименование"
        />
  )
}
