import React, { useEffect } from "react";
import ModalWindowWithForm from "../ModalWindowWithForm";
import { createBrand } from "../../service/brand";
import { useUserStore } from "../../store/user";
import { useBrandStore } from "../../store/brand";

import { useForm } from "react-hook-form";

const CreateBrandModal = ({ title, name, modalWidth, isOpen, handleCloseAllModals }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { token } = useUserStore();
  const { updateDataList } = useBrandStore();

  const onSubmit = ({ name }) => {
    if (!!token) {
      createBrand(token, name, (result) => {
        if (result) {
          updateDataList(token);
          handleCloseAllModals();
          setValue("name", "")
        }
      });
    }
  };

  return (
    <ModalWindowWithForm
      resetForm={reset}
      onSubmit={handleSubmit(onSubmit)}
      title={title}
      name={name}
      modalWidth={modalWidth}
      isOpen={isOpen}
      handleCloseAllModals={handleCloseAllModals}
    >
      <div className="attributes-modal">
        <div className="attributes-modal__container ">
          <label className="attributes-modal__field-name">Название бренда</label>
          <input
            placeholder="Название бренда"
            className="attributes-modal__input zero-input"
            name={`${name}-input-name`}
            {...register("name", { required: true })}
          />
        </div>
      </div>
      <div className="attributes-modal__flex-wrapper">
        <button
          type="button"
          className="cansel-button zero-button bg-[#13131408]"
          onClick={() => handleCloseAllModals((state) => (state = !isOpen))}
        >
          Отмена
        </button>
        <button type="submit" className="confirm-button zero-button">
          Создать
        </button>
      </div>
    </ModalWindowWithForm>
  );
};

export default CreateBrandModal;
