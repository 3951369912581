import SelectAPLogist from "./UI/SelectAPLogist";

function SelectsFilterPopupLogist({ filter, setValueFilter }) {
  const { dataList, title, values, icon } = filter;
  const toggleValue = (value) => {
    setValueFilter(value);
  };

  return (
    <div className="filter-popup__wrapper w-full">
      <h2 className="filter-popup__subtitle flex gap-x-2">
        {icon} {title}
      </h2>
      <SelectAPLogist options={dataList} targetOptionst={values[0]} setTargetOptions={toggleValue} placeholder={title} />
    </div>
  );
}

export default SelectsFilterPopupLogist;
