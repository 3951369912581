import { create } from 'zustand';

export const createPaginationStore = (
	getData,
	pageSize,
	sorts = [],
	filters = {
		checked: [],
		minMax: [],
		stringeEntire: [],
		trueFalseBoth: [],
	}
) => {
	return create((set, get) => ({
		data: [],
		isLoading: false,
		search: '',
		totalCount: 0,
		targetPage: 1,
		maxPages: 0,
		pageSize,
		sorts,
		filters,
		setSorts(sorts) {
			set(() => ({ sorts }));
		},
		setFilters(filters) {
			set(() => ({ filters }));
		},
		setSearch(search) {
			set(() => ({ search }));
		},
		setTotalCount(totalCount) {
			set(() => ({ totalCount }));
		},
		setTargetPage(targetPage) {
			set(() => ({ targetPage }));
		},
		setPageSize(pageSize) {
			set(() => ({ pageSize }));
		},
		loadDataList(token, errorProps) {
			if (!get().isLoading) {
				set(() => ({ isLoading: true }));
				getData(
					{
						token,
						pageSize: get().pageSize,
						targetPage: get().targetPage,
						search: get().search,
						sorts: get().sorts,
						filters: get().filters,
					},
					(data, totalCount, maxPages) => {
						console.log(data);
						set(() => ({ isLoading: false }));
						set(() => ({ data, totalCount, maxPages }));
					},
					() => {
						set(() => ({ isLoading: false }));
						errorProps?.();
					}
				);
			}
		},
		loadMoreData(token, errorProps) {
			if (!get().isLoading) {
				getData(
					{
						token,
						pageSize: get().pageSize,
						targetPage: get().targetPage + 1,
						search: get().search,
						sorts: get().sorts,
						filters: get().filters,
					},
					(data, totalCount, maxPages) => {
						if (get().data.length < get().pageSize) {
							set(() => ({ data, totalCount, maxPages }));
						} else {
							set((state) => ({
								data: [...state.data, ...data],
								totalCount,
								maxPages,
							}));
						}
					},
					() => {
						set(() => ({ isLoading: false }));
						errorProps?.();
					}
				);
				set((state) => ({
					isLoading: false,
					targetPage: state.targetPage + 1,
				}));
			}
		},
		updateDataList(token, errorProps) {
			if (!get().isLoading) {
				set(() => ({ targetPage: 1 }));
				getData(
					{
						token,
						pageSize: get().pageSize,
						targetPage: 1,
						search: get().search,
						sorts: get().sorts,
						filters: get().filters,
					},
					(data, totalCount, maxPages) => {
						set(() => ({
							data,
							totalCount,
							maxPages,
							isLoading: false,
						}));
					},
					() => {
						set(() => ({ isLoading: false }));
						errorProps?.();
					}
				);
			}
		},
	}));
};
