import { createCommonStore } from '../common/createCommonStore';
import { getDocumentType } from '../service/documentType';

export const useCommonDocumentType = createCommonStore((token, props) => {
	getDocumentType(token, (result) => {
		if (result) {
			props(result);
		}
	});
});
