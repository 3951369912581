import React from 'react';
import cartCatalog from '../../images/cartCatalog.svg';
import productImg from '../../images/image 11.png';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../store/user';
import { useDecoratedCommonStore } from '../../hooks/useDecoratedCommonStore';
import { useCommonCurrentRateStore } from '../../store/commonCurrentRate';

const CatalogItem = ({ id, name, brand, price, image }) => {
	const { rate } = useUserStore();
	const { data: currentRate } = useDecoratedCommonStore(
		useCommonCurrentRateStore
	);
	console.log(!rate);

	return (
		<div className='w-full max-w-[200px] mx-[10px] mb-[30px] cursor-pointer max-h-[340px] h-full flex flex-col justify-between'>
			<div className='bg-[#13131408] flex justify-center rounded-lg max-h-[180px] h-full'>
				<img
					src={image?.thumbnail || productImg}
					alt='MikroTik/Роутер'
					className='' //my-[30px] mx-[20px]
				/>
			</div>
			<div className='px-[10px]'>
				<div className='text-[#666] text-sm font-semibold'>
					{name} {id}
				</div>
				<div className='flex flex-wrap mt-[8px]'>
					<p className='text-[#727272] text-xs font-normal mr-[5px] m'>
						ARM 32bit
					</p>
					<p className='text-[#727272] text-xs font-normal mr-[5px]'>
						802.11b/g/n
					</p>
					<p className='text-[#727272] text-xs font-normal mr-[5px]'>
						716 MHz
					</p>
				</div>
				<div className='mt-[8px] flex justify-between items-center'>
					<p className='text-sm font-bold text-[#007AFF]'>
						{!rate ? price * currentRate?.rate_usd_sum : price}{' '}
						{!rate ? 'сум' : '$'}
					</p>
					<div className='flex justify-center items-center'>
						<button type='button'>
							<img src={cartCatalog} alt='cart img' />
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CatalogItem;
