import axios from 'axios';
import { URL } from '../utils/constants';
import { convertSortListForServer } from '../utils/convertSortListForServer';
import { convertFiltersForServer } from '../utils/convertFiltersForServer';
export const getProducts = async (token, props) => {
	try {
		const { data } = await axios.post(
			`${URL}/catalog/unique_products/smart_list/`,
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data.results);
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
	}
};

export const getProductList = async (
	token,
	perPage,
	page,
	search,
	sorts,
	filtersData,
	props
) => {
	try {
		const sorters = sorts && convertSortListForServer(sorts);

		const filters = filtersData && convertFiltersForServer(filtersData);

		const { data } = await axios.post(
			`${URL}/catalog/unique_products/smart_list/`,
			{
				page,
				search,
				per_page: perPage,
				sorters,
				// filters,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data.results, data.count, data.max_pages);
	} catch (error) {
		console.error('Error:', error);
		props?.(null, null, null);
	}
};

export const createAttributeCategory = async (token, name, props) => {
	try {
		const { data } = await axios.post(
			`${URL}/adminpanel/attribute_category/`,
			{
				name,
			},
			{
				headers: {
					Authorization: `Token ${token}`,
				},
			}
		);
		props?.(data);
	} catch (error) {
		console.error('Error:', error);
		props?.(null);
	}
};
