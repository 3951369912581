import { createPaginationStore } from '../common/createPaginationStore';
import { getSuppliersList } from '../service/suppliers';

export const useSuppliersStore = createPaginationStore(
	(options, props, errorProps) => {
		const { token, pageSize, targetPage, search, sorts, filters } = options;
		getSuppliersList(
			token,
			pageSize,
			targetPage,
			search,
			sorts,
			filters,
			(suppliers, totalSupplier, maxPages) => {
				if (
					suppliers &&
					typeof totalSupplier === 'number' &&
					maxPages
				) {
					props(suppliers, totalSupplier, maxPages);
				} else {
					errorProps();
				}
			}
		);
	},
	10,
	[
		{ id: '111', title: '№', value: false, target: 'id' },
		{ id: '222', title: 'Поставщики', value: false, target: 'name' },
	],
	null
);
