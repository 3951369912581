import React from 'react';
import Header from './Header';
import Main from './Main';
import Table from './Table';
import { Routes, Route } from 'react-router-dom';
import Footer from './Footer';
import TableSelfItem from './TableSelfItem';
import StatusIcon from './StatusIcon';
import { api } from './../utils/api';
import { APIurl } from '../backend-config';
import {
	catalogApiData,
	balancesApiData,
	inventoryControlaApiData,
	attributesApiData,
	attributesCategoryApiData,
	productsCategoryApiData,
	systemUsersApiData,
} from '../utils/constants';
import CardsList from './CardsList';
import Logist from '../pages/Logist';
import APLogist from '../pages/adminpanel/Logist';
import EmptyCart from './EmptyCart';
import Cart from './Cart';
import PhysicalCart from './PhysicalCart';
import ProductPage from './ProductPage';
import CategoryItemsModalWindow from './CategoryItemsModalWindow';
import Attributes from '../pages/adminpanel/Attributes';
import AttributeCategories from '../pages/adminpanel/AttributeCategories';
import Login from '../pages/Login';
import ProductsCategory from '../pages/adminpanel/ProductsCategory';
import Users from '../pages/adminpanel/Users';
import Balances from '../pages/storekeeper/Balances';
import InventoryControl from '../pages/storekeeper/InventoryControl';
import Catalog from '../pages/Catalog/Catalog';
import Welcome from '../pages/Welcome';
import Product from '../pages/Product';
import Brand from '../pages/adminpanel/Brand';
import Suppliers from '../pages/adminpanel/Suppliers';
import Storekeeper from '../pages/adminpanel/Storekeeper';
import ProductCreation from '../pages/adminpanel/ProductCreation';
import ProductUpdate from '../pages/adminpanel/ProductUpdate';

function Page() {
	const [isLoading, setIsLoading] = React.useState(true);
	const [isNothingFound, setIsNothingFound] = React.useState(false);
	const [isCartEmpty, setIsCartEmpty] = React.useState(false);

	const [attributesTableData, setAttributesTableData] = React.useState({
		titles: ['№', 'Атрибут', 'Категория', 'Ед. измерения', 'Тип'],
		content: [
			['№1', 'Атрибут1', 'Категория1', 'Ед. измерения1', 'Тип1'],
			['№2', 'Атрибут2', 'Категория2', 'Ед. измерения2', 'Тип2'],
		],
	});

	const [attributesCategoryTableData, setAttributesCategoryTableData] =
		React.useState({
			titles: ['№', 'Категория', 'Кол-во атрибутов'],
			content: [['№', 'Категория', 'Кол-во атрибутов']],
		});

	const [productsCategoryTableData, setProductsCategoryTableData] =
		React.useState({
			titles: ['№', 'Категория', 'Род. категория', 'Фото'],
			content: [['№', 'Категория', 'Род. категория', 'Фото']],
		});

	const [systemUsersTableData, setSystemUsersTableData] = React.useState({
		titles: [
			'№',
			'ФИО',
			'Почта',
			'Физ/Юр. лицо',
			'Телефон',
			'Телеграмм',
			'Город',
			'Компания',
		],
		content: [
			[
				'№',
				'ФИО',
				'Почта',
				'Физ/Юр. лицо',
				'Телефон',
				'Телеграмм',
				'Город',
				'Компания',
			],
		],
	});

	const [inventoryControlTableData, setInventoryControlTableData] =
		React.useState({
			titles: ['№', 'Время', 'Комментарий', 'Статус', 'Печать'],
			content: [['№', 'Время', 'Комментарий', 'Статус', 'Печать']],
		});
	const [relocationTableData, setRelocationTableData] = React.useState({
		titles: [
			'Статус',
			'№',
			'Время разгрузки',
			'Время погрузки',
			'Со склада',
			'На склад',
			'Номер машины',
			'ФИО Водителя',
			'Организация',
			'Комментарий',
			'Печать',
		],
		content: [],
	});
	const [balancesTableData, setBalancesTableData] = React.useState({
		titles: [
			'Артикул',
			'Наименование ',
			'Доступно к бронированию',
			'Забронировано',
		],
		content: [
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['2', '№', 'Время разгрузки', 'Время погрузки'],
			['3', '№', 'Время разгрузки', 'Время погрузки'],
			['4', '№', 'Время разгрузки', 'Время погрузки'],
			['5', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['7', '№', 'Время разгрузки', 'Время погрузки'],
			['8', '№', 'Время разгрузки', 'Время погрузки'],
			['9', '№', 'Время разгрузки', 'Время погрузки'],
			['10', '№', 'Время разгрузки', 'Время погрузки'],
			['11', '№', 'Время разгрузки', 'Время погрузки'],
			['12', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['19', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['2', '№', 'Время разгрузки', 'Время погрузки'],
			['3', '№', 'Время разгрузки', 'Время погрузки'],
			['4', '№', 'Время разгрузки', 'Время погрузки'],
			['5', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['7', '№', 'Время разгрузки', 'Время погрузки'],
			['8', '№', 'Время разгрузки', 'Время погрузки'],
			['9', '№', 'Время разгрузки', 'Время погрузки'],
			['10', '№', 'Время разгрузки', 'Время погрузки'],
			['11', '№', 'Время разгрузки', 'Время погрузки'],
			['12', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['19', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['2', '№', 'Время разгрузки', 'Время погрузки'],
			['3', '№', 'Время разгрузки', 'Время погрузки'],
			['4', '№', 'Время разгрузки', 'Время погрузки'],
			['5', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['7', '№', 'Время разгрузки', 'Время погрузки'],
			['8', '№', 'Время разгрузки', 'Время погрузки'],
			['9', '№', 'Время разгрузки', 'Время погрузки'],
			['10', '№', 'Время разгрузки', 'Время погрузки'],
			['11', '№', 'Время разгрузки', 'Время погрузки'],
			['12', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['19', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['2', '№', 'Время разгрузки', 'Время погрузки'],
			['3', '№', 'Время разгрузки', 'Время погрузки'],
			['4', '№', 'Время разгрузки', 'Время погрузки'],
			['5', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['7', '№', 'Время разгрузки', 'Время погрузки'],
			['8', '№', 'Время разгрузки', 'Время погрузки'],
			['9', '№', 'Время разгрузки', 'Время погрузки'],
			['10', '№', 'Время разгрузки', 'Время погрузки'],
			['11', '№', 'Время разгрузки', 'Время погрузки'],
			['12', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['19', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['2', '№', 'Время разгрузки', 'Время погрузки'],
			['3', '№', 'Время разгрузки', 'Время погрузки'],
			['4', '№', 'Время разгрузки', 'Время погрузки'],
			['5', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['7', '№', 'Время разгрузки', 'Время погрузки'],
			['8', '№', 'Время разгрузки', 'Время погрузки'],
			['9', '№', 'Время разгрузки', 'Время погрузки'],
			['10', '№', 'Время разгрузки', 'Время погрузки'],
			['11', '№', 'Время разгрузки', 'Время погрузки'],
			['12', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['19', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['2', '№', 'Время разгрузки', 'Время погрузки'],
			['3', '№', 'Время разгрузки', 'Время погрузки'],
			['4', '№', 'Время разгрузки', 'Время погрузки'],
			['5', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['7', '№', 'Время разгрузки', 'Время погрузки'],
			['8', '№', 'Время разгрузки', 'Время погрузки'],
			['9', '№', 'Время разгрузки', 'Время погрузки'],
			['10', '№', 'Время разгрузки', 'Время погрузки'],
			['11', '№', 'Время разгрузки', 'Время погрузки'],
			['12', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['19', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['2', '№', 'Время разгрузки', 'Время погрузки'],
			['3', '№', 'Время разгрузки', 'Время погрузки'],
			['4', '№', 'Время разгрузки', 'Время погрузки'],
			['5', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['7', '№', 'Время разгрузки', 'Время погрузки'],
			['8', '№', 'Время разгрузки', 'Время погрузки'],
			['9', '№', 'Время разгрузки', 'Время погрузки'],
			['10', '№', 'Время разгрузки', 'Время погрузки'],
			['11', '№', 'Время разгрузки', 'Время погрузки'],
			['12', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['19', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['2', '№', 'Время разгрузки', 'Время погрузки'],
			['3', '№', 'Время разгрузки', 'Время погрузки'],
			['4', '№', 'Время разгрузки', 'Время погрузки'],
			['5', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['6', '№', 'Время разгрузки', 'Время погрузки'],
			['7', '№', 'Время разгрузки', 'Время погрузки'],
			['8', '№', 'Время разгрузки', 'Время погрузки'],
			['9', '№', 'Время разгрузки', 'Время погрузки'],
			['10', '№', 'Время разгрузки', 'Время погрузки'],
			['11', '№', 'Время разгрузки', 'Время погрузки'],
			['12', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['1', '№', 'Время разгрузки', 'Время погрузки'],
			['19', '№', 'Время разгрузки', 'Время погрузки'],
		],
	});

	const [catalogData, setCatalogData] = React.useState({
		content: [],
	});

	const handleOpenCreateModals = () => {};

	const handleCloseAllModals = () => {};

	const handleOpenTableModals = (data) => {};

	const getPageData = ({ path, body }, func) => {
		api.getPageData(path, body)
			.then((res) => {
				func(res);
			})
			.catch((err) => {
				console.log(err);
			})
			.finally(() => setIsLoading(false));
	};

	const attributesCategoryTableDataParsing = (res) => {
		setAttributesCategoryTableData({
			...attributesCategoryTableData,
			content: res.table_data.map((element) => {
				const arr = [];
				arr.splice(0, 0, element.id);
				arr.splice(1, 0, element.name);
				arr.splice(2, 0, element.attr_count);
				return arr;
			}),
		});
	};

	const attributesTableDataParsing = (res) => {
		setAttributesTableData({
			...attributesTableData,
			content: res.table_data.map((element) => {
				const arr = [];
				arr.splice(0, 0, element.id);
				arr.splice(1, 0, element.name);
				arr.splice(2, 0, element.category_name);
				arr.splice(3, 0, element.unit);
				arr.splice(4, 0, element.data_type);
				return arr;
			}),
		});
	};

	const productsCategoryTableDataParsing = (res) => {
		setProductsCategoryTableData({
			...productsCategoryTableData,
			content: res.table_data.map((element) => {
				const arr = [];
				arr.splice(0, 0, element.id);
				arr.splice(1, 0, element.category_name);
				arr.splice(2, 0, element.parent_name);
				arr.splice(3, 0, <StatusIcon isStatus={element.image_bool} />);
				return arr;
			}),
		});
	};

	const systemUsersTableDataParsing = (res) => {
		setSystemUsersTableData({
			...systemUsersTableData,
			content: res.table_data.map((element) => {
				const arr = [];
				arr.splice(0, 0, element.id);
				arr.splice(1, 0, element.FIO);
				arr.splice(2, 0, element.email);
				arr.splice(
					3,
					0,
					<TableSelfItem value={element.Physical_LegalFace} />
				);
				arr.splice(4, 0, element.phone_number);
				arr.splice(5, 0, element.telegram);
				arr.splice(6, 0, element.city);
				arr.splice(7, 0, element.company);
				return arr;
			}),
		});
	};

	const inventoryControlTableDataParsing = (res) => {
		setInventoryControlTableData({
			...inventoryControlTableData,
			content: res.table_data.map((element) => {
				const arr = [];
				arr.splice(0, 0, element.id);
				arr.splice(1, 0, element.FIO);
				arr.splice(2, 0, element.email);
				arr.splice(
					3,
					0,
					<TableSelfItem value={element.Physical_LegalFace} />
				);
				arr.splice(4, 0, element.phone_number);
				arr.splice(5, 0, element.telegram);
				arr.splice(6, 0, element.city);
				arr.splice(7, 0, element.company);
				return arr;
			}),
		});
	};

	const balancesTableDataParsing = (res) => {
		setBalancesTableData({
			...balancesTableData,
			content: res.table_data.map((element) => {
				const arr = [];
				arr.splice(0, 0, element.id);
				arr.splice(1, 0, element.FIO);
				arr.splice(2, 0, element.email);
				arr.splice(
					3,
					0,
					<TableSelfItem value={element.Physical_LegalFace} />
				);
				arr.splice(4, 0, element.phone_number);
				arr.splice(5, 0, element.telegram);
				arr.splice(6, 0, element.city);
				arr.splice(7, 0, element.company);
				return arr;
			}),
		});
	};

	const CatalogDataParsing = (res) => {
		setCatalogData({
			...catalogData,
			content: res.view_objects,
		});
	};

	const onGetAttributesCategoryTableData = () => {
		setIsLoading(true);
		getPageData(
			attributesCategoryApiData,
			attributesCategoryTableDataParsing
		);
	};

	const onGetAttributesTableData = () => {
		setIsLoading(true);
		getPageData(attributesApiData, attributesTableDataParsing);
	};

	const onGetProductsCategoryTableData = () => {
		setIsLoading(true);
		getPageData(productsCategoryApiData, productsCategoryTableDataParsing);
	};

	const onGetSystemUsersTableData = () => {
		setIsLoading(true);
		getPageData(systemUsersApiData, systemUsersTableDataParsing);
	};

	const onGetInventoryControlTableData = () => {
		setIsLoading(true);
		getPageData(inventoryControlaApiData, inventoryControlTableDataParsing);
	};

	const onGetBalancesTableData = () => {
		setIsLoading(true);
		getPageData(balancesApiData, balancesTableDataParsing);
	};

	const onGetCatalogData = () => {
		setIsLoading(true);
		getPageData(catalogApiData, CatalogDataParsing);
	};

	React.useEffect(() => {}, []);

	// function fetchAttributeData() {
	//     setIsLoading(true);
	//     axios
	//         .get(`https://${APIurl}/adminpanel/attribute_category/`)
	//         .then(function (response) {
	//             setAttributeCategoryData(response.data);
	//             setIsLoading(false);
	//         })
	//         .catch(function (error) {
	//             setIsLoading(false);
	//             console.log(error);
	//         })
	//         .finally(() => setIsLoading(false));
	// }

	// React.useEffect(() => {
	//     fetchAttributeData();
	// }, []);
	return (
		<Routes>
			<Route path='/login' element={<Login />} />

			<Route path='/' element={<Welcome />} />
			<Route path='/adminpanel/attributes' element={<Attributes />} />
			<Route
				path='/adminpanel/attributes_category'
				element={<AttributeCategories />}
			/>
			<Route
				path='/adminpanel/products_category'
				element={<ProductsCategory />}
			/>
			<Route path='/adminpanel/system_users' element={<Users />} />
			<Route
				path='/storekeeper/inventory_control'
				element={
					<InventoryControl />
					// <Main
					//     isInteractionPanel={true}
					//     element={
					//         <Table
					//             handleCloseAllModals={handleCloseAllModals}
					//             handleOpenTableModals={handleOpenTableModals}
					//             isNothingFound={isNothingFound}
					//             isLoading={isLoading}
					//             getData={onGetInventoryControlTableData}
					//             tableData={inventoryControlTableData}
					//             specialClass="table__row_grid_inventory-control"
					//         />
					//     }
					//     title="Оприходования"
					//     handleOpenCreateModals={handleOpenCreateModals}
					//     isFilterButton={true}
					//     placeholderText="Номер или комментарий"
					// />
				}
			/>
			<Route
				path='/storekeeper/balances'
				element={
					<Balances />
					// <Main
					//     isInteractionPanel={true}
					//     element={
					//         <Table
					//             handleCloseAllModals={handleCloseAllModals}
					//             handleOpenTableModals={handleOpenTableModals}
					//             isNothingFound={isNothingFound}
					//             isLoading={isLoading}
					//             getData={onGetBalancesTableData}
					//             tableData={balancesTableData}
					//             specialClass="table__row_grid_balances"
					//         />
					//     }
					//     select={true}
					//     title="Остатки"
					//     isFilterButton={true}
					//     handleOpenCreateModals={handleOpenCreateModals}
					//     placeholderText="Артикул или наименование"
					// />
				}
			/>
			<Route
				path='/storekeeper/relocation'
				element={
					<Main
						isInteractionPanel={true}
						element={
							<Table
								handleCloseAllModals={handleCloseAllModals}
								handleOpenTableModals={handleOpenTableModals}
								isNothingFound={isNothingFound}
								isLoading={false}
								getData={onGetBalancesTableData}
								tableData={relocationTableData}
								specialClass='table__row_grid_relocation'
							/>
						}
						title='Перемещения'
						isFilterButton={true}
						handleOpenCreateModals={handleOpenCreateModals}
						placeholderText='Номер или комментарий'
					/>
				}
			/>
			<Route
				path='/carts'
				element={
					<Main
						isInteractionPanel={true}
						element={isCartEmpty ? <EmptyCart /> : <Cart />}
						isCartInfo={true}
						title='Корзина'
						handleOpenCreateModals={handleOpenCreateModals}
						isFilterButton={true}
						placeholderText='Наименование или артикул'
					/>
				}
			/>
			<Route
				path='/carts/physicals'
				element={
					<Main
						isInteractionPanel={true}
						element={isCartEmpty ? <EmptyCart /> : <PhysicalCart />}
						isCartInfo={true}
						title='Физические корзины'
						handleOpenCreateModals={handleOpenCreateModals}
						isFilterButton={true}
						placeholderText='Название корзины'
					/>
				}
			/>
			<Route
				path='/catalog'
				element={
					<Catalog />
					// <Main
					//     isInteractionPanel={true}
					//     element={<CardsList getData={onGetCatalogData} data={catalogData} />}
					//     isFilterButton={true}
					//     handleOpenCreateModals={handleOpenCreateModals}
					//     title="Каталог"
					//     placeholderText="Название товара"
					// />
				}
			/>
			<Route
				path='/catalog/product/:id'
				element={
					<Product />
					// <Main isInteractionPanel={true} element={<ProductPage />} title="Каталог" placeholderText="Название товара" />
				}
			/>
			<Route path='/logist' element={<Logist />} />
			<Route path='/adminpanel/brands' element={<Brand />} />
			<Route path='/adminpanel/suppliers/' element={<Suppliers />} />
			<Route path='/adminpanel/logist' element={<APLogist />} />
			<Route path='/adminpanel/storekeeper' element={<Storekeeper />} />
			<Route path='/adminpanel/create_prodact' element={<ProductCreation />} />
			<Route path='/adminpanel/update_prodact' element={<ProductUpdate />} />
		</Routes>
	);
}

export default Page;
