export const convertFiltersForServer = ({
	checked = [],
	checkedColumn = [],
	minMax = [],
	select = [],
	stringeEntire = [],
	trueFalseBoth = [],
}) => {
	const result = {
		...checked.reduce((value, item) => {
			const filter = {
				type: 'm2m_id_in',
				value: item.values,
			};
			return { ...value, [item.target]: filter };
		}, {}),

		...select.reduce((value, item) => {
			const filter = {
				type: 'f_int',
				value: item.values[0]?.id || undefined,
			};
			return { ...value, [item.target]: filter };
		}, {}),

		...checkedColumn.reduce((value, item) => {
			const filter = {
				type: 'f_int',
				value:
					item.values === -0
						? -0
						: item.values.map((item) => item.id)[0],
			};
			return { ...value, [item.target]: filter };
		}, {}),

		// ...stringeEntire.reduce((value, item) => {
		//     if (typeof item.value === 'undefined') return value;
		//     const filter = {
		//         type: 'string_entire_word',
		//         value: item.value
		//     }
		//     return { ...value, [item.target]: filter }
		// }, {}),

		...trueFalseBoth.reduce((value, item) => {
			if (typeof item.value === 'undefined') return value;
			const filter = {
				type: 'f_int',
				value: item.value,
			};
			return { ...value, [item.target]: filter };
		}, {}),

		...minMax.reduce((value, item) => {
			console.log(item);
			if (
				typeof item.value?.max === 'undefined' &&
				typeof item.value?.min === 'undefined'
			)
				return value;
			const filter = {
				type: item.title === 'number' ? 'min_max' : 'datetime_min_max',
				value_max: item.value?.max,
				value_min: item.value?.min,
			};
			return { ...value, [item.target]: filter };
		}, {}),
	};

	return result;
};
