import React from "react";
import { useAttributeStore } from "../store/attribute";

function Pagination({ paginationStore = useAttributeStore, togglePage }) {
    const { targetPage, maxPages } = paginationStore()
    const generatePageArray = () => {
        const result = [];
        for (let i = 0; i < maxPages; i++) {
            result.push(i + 1);
        }
        return result;
    };
    const togglePageOnClick = (value) => {
        return () => {
            togglePage(value);
        };
    };

    return (
        <section className="pagination">



            <button
                className="pagination__move-button zero-button"
                onClick={togglePageOnClick(targetPage - 1)}
                disabled={targetPage <= 1}></button>
            {maxPages <= 8
                ? generatePageArray().map((item) => {
                    return (
                        <button
                            className={` pagination__number zero-button 
                                 ${targetPage === item && ' pagination__number_active cursor-auto'}`}
                            disabled={targetPage === item}
                            onClick={togglePageOnClick(item)}
                            key={item.toString()}>
                            {item}
                        </button>
                    );
                })
                : generatePageArray().map((item) => {
                    if (
                        item < 2 ||
                        item > maxPages - 1 ||
                        item > targetPage - 3 &&
                        item < targetPage + 3
                    ) {
                        return (
                            <button
                                className={` pagination__number  zero-button 
                                 ${targetPage === item && ' pagination__number_active'}`}
                                onClick={togglePageOnClick(item)}
                                key={item.toString()}>
                                {item}
                            </button>
                        );
                    }
                    if (item === 2 || item === maxPages - 2) {
                        return <span key={item} className="pagination__number">...</span>;
                    }
                })}
            <button
                className="pagination__move-button zero-button"
                onClick={togglePageOnClick(targetPage + 1)}
                disabled={targetPage >= maxPages}
            ></button>
        </section>
    )
};

export default Pagination;
