import { createPaginationStore } from "../common/createPaginationStore";
import { getBrandList } from "../service/brand";
import { ReactComponent as MinMax } from "../images/MinMax.svg";

export const useBrandStore = createPaginationStore(
  (options, props, errorProps) => {
    const { token, pageSize, targetPage, search, sorts, filters } = options;
    getBrandList(token, pageSize, targetPage, search, sorts, filters, (brands, totalBrands, maxPages) => {
      if (brands && typeof totalBrands === "number" && maxPages) {
        props(brands, totalBrands, maxPages);
      } else {
        errorProps();
      }
    });
  },
  10,
  [
    { id: "111", title: "№", value: false, target: "id" },
    { id: "222", title: "Бренд", value: false, target: "name" },
    { id: "333", title: "Кол-во товаров", value: false, target: "product" },
  ],
  {
    minMax: [
      {
        id: "1111",
        type: "number",
        title: "Кол-во товаров",
        icon: <MinMax />,
        target: "product",
        value: {},
      },
    ],
  }
);
