import React from 'react';

const LogistFileCard = ({
	attached = false,
	title,
	name,
	url,
	deleteCard,
	addFile,
}) => {
	return (
		<div className='document-item'>
			<label className='document-list-header'>{title}</label>
			<div className='document-status gap-2'>
				{attached ? (
					<>
						<a
							href={url}
							rel='noreferrer'
							target='_blank'
							className='text-[#007AFF] text-[13px] font-bold max-w-32 overflow-hidden text-ellipsis'>
							{name}
						</a>
					</>
				) : (
					<>
						<button type='button' onClick={deleteCard}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<circle
									cx='12'
									cy='12'
									r='8.4'
									stroke='#FF3E3E'
									strokeWidth='1.2'
								/>
								<path
									d='M16 8L8 16'
									stroke='#FF3E3E'
									strokeWidth='1.2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M8 8L16 16'
									stroke='#FF3E3E'
									strokeWidth='1.2'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
							</svg>
						</button>
						<label>
							<p className='text-[#007AFF] text-[13px] max-w-32 overflow-hidden text-ellipsis font-bold cursor-pointer'>
								{name || 'Добавить'}
							</p>
							<input
								className='hidden'
								type='file'
								name=''
								id='add-logist-file'
								onChange={(e) => {
									if (e.target.files[0]) {
										addFile(e.target.files[0]);
									}
								}}
								accept='.pdf, .xls, .xlsx, .doc, .docx, .jpeg, .jpg'
							/>
						</label>
					</>
				)}
			</div>
		</div>
	);
};

export default LogistFileCard;
