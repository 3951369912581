import { useState } from "react";
import Main from "../../components/Main";
import Table from "../../components/Table";
import { useBrandStore } from "../../store/brand";
import CreateBrandModal from "../../components/modals/CreateBrandModal";
import EditBrandModal from "../../components/modals/EditBrandModal";

export default function Brand() {
  const { data, isLoading } = useBrandStore();
  const [isNothingFound, setIsNothingFound] = useState(false);

  const [isOpenCreateBrandModal, setIsOpenCreateBrandModal] = useState(false);
  const [selectedItemFromTable, setSelectedItemFromTable] = useState(false);
  const [isOpenEditBrandModal, setIsOpenEditBrandModal] = useState(false);
  
  const titles = [
    {
      title: "№",
      style: "",
    },
    {
      title: "Бренд",
      style: "",
    },
    {
      title: "Кол-во товаров",
      style: "text-center",
    },
  ];
  const closeModals = () => {
    setIsOpenCreateBrandModal(false);
    setIsOpenEditBrandModal(false);
  };

  return (
    <Main
      isInteractionPanel={true}
      paginationStore={useBrandStore}
      modals={
        <>
          <CreateBrandModal
            title="Создание Бренда"
            handleCloseAllModals={closeModals}
            isOpen={isOpenCreateBrandModal}
            name="create-brand-modal"
            modalWidth={{ width: "500px" }}
          />

          <EditBrandModal
            title="Редактирование Бренда"
            initialData={selectedItemFromTable}
            handleCloseAllModals={closeModals}
            isOpen={isOpenEditBrandModal}
            name="edit-brand-modal"
            modalWidth={{ width: "500px" }}
          />
        </>
      }
      element={
        <Table
          handleCloseAllModals={() => {}}
          handleOpenTableModals={() => {}}
          isNothingFound={isNothingFound}
          isLoading={isLoading}
          tableData={{
            titles,
            content: data,
          }}
          depicter={(item, onClick, specialClass) => {
            const {
              id, name, products_count
            } = item;
            return (
              <tr
                onClick={() => {
                  setIsOpenEditBrandModal(true);
                  setSelectedItemFromTable(item);
                }}
                className={`table__row ${specialClass}`}
              >
                <td className="table__content">{id}</td>
                <td className="table__content block !truncate">
                {name}
                </td>
                <td className="table__content block !truncate text-center">{products_count}</td>
              </tr>
            );
          }}
          specialClass="grid-cols-brandColumns "
        />
      }
      createButton={true}
      isFilterButton={true}
      handleOpenCreateModals={() => {
      setIsOpenCreateBrandModal(true);
      }}
      title="Бренды"
      placeholderText="Наименование бренда"
    />
  );
}
