import React from "react";

function CounterCart(props) {
  const [inputValue, setInputValue] = React.useState(0);

  const handleIncrementValue = () => {
    setInputValue(Number(inputValue) + 1);
  }

  const handleDecrementValue = () => {
    setInputValue(Number(inputValue) - 1);
  }

  const handleChangeValue = (evt) => {
    setInputValue(evt.target.value);
  }

  return (
    <div className="counter">
      <button onClick={handleDecrementValue} className="counter__button zero-button">-</button>
      <input type="number" onChange={handleChangeValue} className="counter__input zero-input" value={inputValue}></input>
      <button onClick={handleIncrementValue} className="counter__button zero-button">+</button>
    </div>
  )
};

export default CounterCart;
